import React from 'react'

import { DateDisplay, TimeDisplay } from 'utils/common'

import styled from 'styled-components'
import { Box, Divider, Stack } from '@mui/material'
import Button from 'components/form/button/Button'
import ButtonOutline from 'components/form/button/ButtonOutline'

import confirmIcon from 'assets/images/confirm-activity.png'

import { LiaTimesSolid } from 'react-icons/lia'

const Div = styled.div`
  padding: 24px;

  .button-width {
    flex-grow: 1;
  }

  .heading {
    color: var(--Gray-900);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
  }
  .sub-heading {
    color: var(--Gray-600);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
  }
  .heading-info {
    width: 155px;
    color: var(--Gray-700);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
  }
  .info {
    color: var(--Gray-700);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
  }
  .close-icon {
    cursor: pointer;
    color: var(--Gray-400);
  }
`

function ConfirmCreateActivityModalContent({ form, branchActivityOptions, onSubmit, onClose }) {
  const getBranchAcitivityName = () => {
    const matchedEventFiled = branchActivityOptions.find((v) => v.value == form.event_field)
    return matchedEventFiled ? matchedEventFiled.text : '-'
  }
  const getScheduleForOneDay = () => {
    const displayDate = DateDisplay(form.date_event_oneday)
    const displayStartDate = TimeDisplay(form.timestart_event_oneday)
    const displayEndDate = TimeDisplay(form.timeend_event_oneday)

    return `${displayDate ?? '-'} (เวลา ${displayStartDate ?? '-'} - ${displayEndDate ?? '-'} น.)`
  }
  const getScheduleForMultipleDay = (data) => {
    const displayDate = DateDisplay(data.date_event)
    const displayStartDate = TimeDisplay(data.timestart_event)
    const displayEndDate = TimeDisplay(data.timeend_event)

    return `${displayDate ?? '-'} (เวลา ${displayStartDate ?? '-'} - ${displayEndDate ?? '-'} น.)`
  }

  return (
    <Div>
      <Stack sx={{ mb: '20px' }} direction="row" justifyContent="space-between ">
        <Stack direction="row" spacing={2}>
          <img src={confirmIcon} />
          <Box>
            <div className="heading">ตรวจสอบและยืนยันการสร้างกิจกรรม</div>
            <div className="sub-heading">กรุณาตรวจสอบและยืนยันการทำรายการ</div>
          </Box>
        </Stack>

        <LiaTimesSolid size={18} className="close-icon" onClick={onClose} />
      </Stack>

      <Divider sx={{ mb: 3 }} />

      <Box sx={{ mb: 7 }}>
        <Stack sx={{ mb: '10px' }} direction="row" spacing={1}>
          <div className="heading-info">ชื่อกิจกรรม</div>
          <div className="info">{form.name}</div>
        </Stack>
        <Stack sx={{ mb: '10px' }} direction="row" spacing={1}>
          <div className="heading-info">สาขากิจกรรม</div>
          <div className="info">{getBranchAcitivityName()}</div>
        </Stack>
        <Stack sx={{ mb: '10px' }} direction="row" spacing={1}>
          <div className="heading-info">กำหนดการ</div>
          {form.type_date_activity === 'จัดงานวันเดียว' ? (
            <Box>
              <div className="info">{getScheduleForOneDay()}</div>
            </Box>
          ) : (
            <Box>
              {form.event_date_list.map((v) => (
                <div className="info">{getScheduleForMultipleDay(v)}</div>
              ))}
            </Box>
          )}
        </Stack>
        <Stack sx={{ mb: '10px' }} direction="row" spacing={1}>
          <div className="heading-info">จำนวนที่เปิดรับสมัคร</div>
          <div className="info">{form.open_attendee_count ?? '-'} คน</div>
        </Stack>
        <Stack sx={{ mb: '10px' }} direction="row" spacing={1}>
          <div className="heading-info">จำนวนชั่วโมง</div>
          <div className="info">{form.duration_in_hours ?? '-'} ชั่วโมง</div>
        </Stack>
        <Stack sx={{ mb: '10px' }} direction="row" spacing={1}>
          <div className="heading-info">สถานที่</div>
          <div className="info">{form.location_type ?? '-'}</div>
        </Stack>
        <Stack sx={{ mb: '10px' }} direction="row" spacing={1}>
          <div className="heading-info">ค่าใช้จ่าย</div>
          <div className="info">{form.fee_is_fee === 'true' ? 'ไม่เสียค่าใช้จ่าย' : 'มีค่าใช้จ่าย'}</div>
        </Stack>
      </Box>

      <Stack direction="row" spacing={2}>
        <ButtonOutline className="button-width" onClick={onClose}>
          ยกเลิก
        </ButtonOutline>
        <Button className="button-width" onClick={onSubmit}>
          ยืนยันการสร้างกิจกรรม
        </Button>
      </Stack>
    </Div>
  )
}

export default ConfirmCreateActivityModalContent
