import React from 'react'

import styled from 'styled-components'

import { Box, Stack } from '@mui/material'
import Button from 'components/form/button/Button'

import { LiaTimesSolid } from 'react-icons/lia'

import warningIcon from 'assets/images/warning-icon.png'

const Div = styled.div`
  padding: 24px;
  .heading {
    color: var(--Gray-900);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
  }

  .button-width {
    flex-grow: 1;
  }

  .close-icon {
    cursor: pointer;
    color: var(--Gray-400);
  }

  .success-icon {
    max-width: 100%;
  }
`

function FailureModal({ onSubmit, onClose, textYes, heading, subHeading }) {
  return (
    <Div>
      <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between">
        <Box sx={{ width: 48, height: 48 }}>
          <img className="success-icon" src={warningIcon} />
        </Box>
        <LiaTimesSolid size={18} className="close-icon" onClick={onClose} />
      </Stack>

      <Box sx={{ mb: 4 }}>
        <div className="heading">{heading}</div>
        <Box>{subHeading}</Box>
      </Box>

      <Stack>
        <Button className="button-width" onClick={onSubmit}>
          {textYes}
        </Button>
      </Stack>
    </Div>
  )
}

export default FailureModal
