import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate,useParams } from 'react-router-dom'

import styled from 'styled-components'

import { Apiverify_3rdToken } from '../redux/actions/admin'
import { BASE_URL_3RD } from '../configs/app'

import Button from '../components/form/button/Button'
import ButtonOutline from '../components/form/button/ButtonOutline'

const Div = styled.div`
  padding: 96px;

  .mr-12-px {
    margin-right: 0.75rem;
  }

  .highlight-error {
    color: var(--Primary-700);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 0.75rem;
  }
  .error-headline {
    color: var(--Gray-900);
    font-size: 3.75rem;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }
  .error-desc {
    color: var(--Gray-600);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 3rem;
  }
  .button-container {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
  }
`

function LoginToken() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { token } = useParams()

  useEffect(() => {
    
    dispatch(Apiverify_3rdToken(token)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            if(data_info.accessToken){
                localStorage.setItem('accessToken', data_info.accessToken);
                localStorage.setItem('user_info', JSON.stringify(data_info.users_admin ));
                localStorage.setItem('menu_list', data_info.menu_list);
                window.location.href = '/CpdManage/OrgList'
            }else{
                window.location.href = data_info.url_redirect ? data_info.url_redirect : BASE_URL_3RD;
            }
        }else{
            window.location.href = BASE_URL_3RD;
        }
    })
    
  }, [dispatch, token])

  const gotoHome = () => {
    navigate('/', { replace: true })
  }

  const gotoPrevious = () => {
    navigate(-1, { replace: true })
  }
  return (
    <Div>
      <div className="highlight-error">ขณะนี้กำลังทำรายการ</div>
      <div className="error-headline">กรุณารอสักครู่</div>
      <div className="error-desc">กรุณาอย่าปิด browser จนกว่าจะทำรายการสำเร็จ</div>
      {
        /*
        <div className="button-container">
            <ButtonOutline className="mr-12-px" onClick={gotoPrevious}>
            Go back
            </ButtonOutline>
            <Button onClick={gotoHome}>Home</Button>
        </div>
        */
      }


    </Div>
  )
}

export default LoginToken
