import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate,useParams } from 'react-router-dom'
import XLSX from "xlsx";

import {  DisplayTableActivityName,DateDisplayDDMMBBB,DisplayTableActivityDescription,GetPermissionOther } from 'utils/common'
import { ApiCMSApplicationsProfile,ApiGetApplicationDocument,ApiUpdateApplications,ApiGetOrgActivity } from '../../redux/actions/organizations'
import { ApiGetProvince,ApiGetDistrict,ApiGetSub_district,ApiGetOrgType } from '../../redux/actions/master'
import {  getActivityCpd } from '../../redux/actions/activityCpd'

import Button from '../../components/form/button/Button'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import TableV2 from '../../components/common/TableV2'
import TextField from '../../components/form/TextField'
import Dropdown from '../../components/form/Dropdown'
import RadioButton from '../../components/form/RadioButton'
import InputTextArea from '../../components/widget/element/InputTextArea'
import Field from '../../components/form/Field'
import Date from '../../components/form/Date'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'
import DialogConfirmV2 from '../../components/dialog/DialogConfirmV2'
import SidePanel from '../../components/common/SidePanel'
import ModalApplicationHistory from './ModalApplicationHistory'

import iconPlus from '../../assets/images/icon-plus.svg'
import { FaBullseye } from 'react-icons/fa'
import { GrStatusGoodSmall } from 'react-icons/gr'
import warningIcon from '../../assets/images/warning-icon.png'
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'

const Div = styled.div`
  .box_header{
    display: flex;
    justify-content: space-between;
  }
  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Base-Font-Title-Color);
  }
  .content-title-text {
    color: var(--Base-Font-Title-SUB-Color);
    font-size: 18px;
  }
  .css_button_add{
    display: inline-block;
    align-self: flex-end;
  }
  .css_magin_top_16 {
    margin-top: 16px;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
  }
  .status-badge {
    width: fit-content;
    padding: 2px 6px;
    text-align: center;
    font-weight: 500;
    border-radius: 16px;
    color: #475467;
    background: #f5f5f4;
    border: 1px #e7e5e4 solid;

    &.expire {
      color:#B42318;
      background: #FEF3F2;
      border: 1px #FECDCA solid;
    }
    &.active {
      color:#067647;
      background: #ECFDF3;
      border: 1px #ABEFC6 solid;
    }

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: #475467;
      

      &.expire {
        color:#B42318;
      }
      &.active {
        color:#067647;
      }

    }
  }

  .table-wrapper {
    margin-top: 24px;
    border: 1px solid #eaecf0;
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    background-color: #ffffff;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Table-Bg-Header);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--BADGE-Table-Font);
        background: var(--BADGE-Table-Bg);
        border-radius: 16px;
        border: 1px var(--BADGE-Table-Border) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }
  .css_magin_top_10 {
    margin-top: 10px;
  }

  .box_radio{
    padding: 0px 0px;
    display: flex;
    gap : 10px;
    .radio_css{
        display: flex;
        align-items: center;
    }
    .text_green{
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color : #085D3A;
    }
    .text_red{
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color : #912018;
    }
    .text_normal{
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color : #101828;
    }
}
`

const DivTab = styled.div`
width: 100%;
margin-bottom: 1rem;
margin-top: 1rem;
display: flex;
/*border-bottom: 1px solid var(--Tab-Border-Default);*/

.btn-menu-style {
    border: none;
    padding: 10px 18px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    background-color: var(--Tab-Bg-Default);
    /*border-bottom: 0px solid var(--Tab-Border-Default);*/
    border-bottom: 2px solid var(--Tab-Border-Default);
    color: var(--Tab-Font-Default);  
    font-size: 14px;
    /*font-family: 'Inter','Noto Sans Thai';*/
    font-family: inherit;
}

.menu-active {
    border: none;
    padding: 10px 18px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 2px solid var(--Tab-Border-Current) !important;
    color: var(--Tab-Font-Current); 
    background-color: var(--Tab-Bg-Current);
    /*font-family: 'Inter','Noto Sans Thai';*/
    font-family: inherit;
}
`
const DivInfo = styled.div`
  color: var(--Gray-600);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  .main_box {
    display: grid;
    grid-template-columns: repeat(2, 25% [col-start] 75% [col-end]);
    /*gap: 20px;*/
    padding-top: 10px;
    .main_box_text_head {
      font-size: 14px;
      font-weight: 600;
      color: var(--Gray-700);
    }
    .main_box_text_sub {
      font-size: 14px;
      color: var(--Gray-600);
    }
    .main_box_edit {
      border: 1px solid rgb(208, 213, 221);
      border-radius: 8px;
    }
    .main_box_fill_data {
      margin: 16px 24px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      gap: 20px;
      .topic_fill {
        font-size: 14px;
        color: #212121;
        margin-bottom: 5px;
      }
      .topic_detail {
        font-size: 14px;
        color: #757575;
      }
    }
    .main_box_button {
      margin: 16px 24px;
      text-align: end;
    }
    .border_buttom {
      border-bottom: 1px solid rgb(234, 236, 240);
    }
  }
  .TextField_employee {
    width: 100%;
  }
  .button_Edit {
    color: #6941c6;
    background-color: #f9f5ff;
    border: 1px solid #eaecf0;
  }
  .button_Cancel {
    color: #000000;
    background-color: #ffffff;
    border: 1px solid #eaecf0;
    margin-right: 1rem;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
  }
  .prefix_icon {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
  .input-new-2 {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr repeat(1, auto);
    grid-template-rows: auto;
    align-content: center;
  }

  .ql-container {
    min-height: 250px;
    font-family: inherit;
  }
  .md-right-1rem{
    margin-right: 1rem;
  }
  .box_org_status {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .group_filter {
      display: flex;
      /*align-items: center;*/
    }
  }
  .css_filter_name {
    width: 150px;
    margin-right: 10px;
  }
  .css_button_filter{
    display: inline-block;
    align-self: flex-end;
  }
  .status-badge {
    /*width: 150px;*/
    /*padding: 0.2rem;*/
    width: fit-content;
    padding: 2px 6px;
    text-align: center;
    font-weight: 500;
    border-radius: 16px;
    color: #475467;
    background: #f5f5f4;
    border: 1px #e7e5e4 solid;

    &.expire {
      color:#B42318;
      background: #FEF3F2;
      border: 1px #FECDCA solid;
    }
    &.active {
      color:#067647;
      background: #ECFDF3;
      border: 1px #ABEFC6 solid;
    }

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color:#475467;
      

      &.expire {
        color:#B42318;
      }
      &.active {
        color:#067647;
      }

    }
  }
`
const DivResultDocument = styled.div`
    border: 1px solid rgb(208, 213, 221);
    border-radius: 8px;
    
    .Box_header{
        display: flex;
        justify-content: space-between;
        padding: 20px 24px;
    }
    .header_topic{
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        color : #101828;
    }
    .css_magin_top_16 {
        margin-top: 16px;
    }
    .border_header {
        border-bottom: 1px solid rgb(234, 236, 240);
    }
    .box_radio{
        padding: 0px 24px;
        display: flex;
        gap : 30px;
        .radio_css{
            display: flex;
            align-items: center;
        }
        .text_green{
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color : #085D3A;
        }
        .text_normal{
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color : #101828;
        }
    }
    .box_date{
        padding: 0px 24px;
        width: 25%;
    }
    .box_comment{
        padding: 0px 24px;
    }
    .InputTextArea_css {
        width: 100%;
        font-size: 14px;
    }
    .box_button{
        padding: 0px 24px;
    }
`
const DivRecordConsideration = styled.div`
border: 1px solid rgb(208, 213, 221);
border-radius: 8px;

.Box_header{
    display: flex;
    justify-content: space-between;
    padding: 20px 24px;
}
.header_topic{
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    color : #101828;
}
.sub_header_topic{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color : #475467;
}
.css_magin_top_16 {
    margin-top: 16px;
}
.border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
}
.box_radio{
    padding: 0px 24px;
    display: flex;
    gap : 30px;
    .radio_css{
        display: flex;
        align-items: center;
    }
    .text_green{
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color : #085D3A;
    }
    .text_red{
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color : #912018;
    }
    .text_normal{
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color : #101828;
    }
}
.box_date{
    padding: 0px 24px;
    display: flex;
    gap : 30px;
}
.box_comment{
    padding: 0px 24px;
}
.InputTextArea_css {
    width: 100%;
    font-size: 14px;
}
.box_button{
    padding: 0px 24px;
}
`
const TableDataStyled = styled.table`
width: 100%;
border-collapse: collapse;
box-shadow: var(--Shadow-xs);

tr {
  border: 1px solid var(--Gray-200);
}

th,
td {
  padding: 16px 14px;
  text-align: left;
  font-size: 14px;
  color: var(--Table-Font-Default);
  white-space: nowrap;
  background: var(--Table-Bg-Default);
}

th {
  padding: 12x 14px;
  font-weight: 500;
  background: var(--Gray-50);
}

tr:hover {
  td {
    background: var(--Table-Bg-Hover);
    color: var(--Table-Font-Hover);
  }
}

.content-wrapper {
  text-align: center;
}

.pagination {
  margin: 30px 24px;
}
`

const OrgApplicationsExtendProfile = () => {
  const dispatch = useDispatch()
  const { ApplicationsId } = useParams()

  const permission_CpdManageOrgApproveList_Extend_Save = GetPermissionOther('CpdManageOrgApproveList_Extend','CpdManageOrgApproveList_Extend_Save');

  const [DataInfo, setDataInfo] = useState(null)
  const [OrganizationId, setOrganizationId] = useState(null)
  const [ApplicationconsiderationHistory, setApplicationconsiderationHistory] = useState([])
  const [TypeUpdate, setTypeUpdate] = useState(null)

  const [ResultDocument, setResultDocument] = useState(0)
  const [DueDateDocument, setDueDateDocument] = useState(null)
  const [CommentDocument, setCommentDocument] = useState(null)

  const [DataAssessment, setDataAssessment] = useState({
    date_assessment : null,
    assessment_timestart_hour : null,
    assessment_timestart_minute : null,
    assessment_timeend_hour : null,
    assessment_timeend_minute : null,
    assessment_link : null,
    zoom_id : null,
    zoom_password : null,
    comment_assessment : null
  })
  const [RecordConsideration, setRecordConsideration] = useState({
    record_consideration : null,
    date_subcommittee : null,
    date_committee_approve : null,
    comment_consideration : null
  })
  
  
  const [isOpenData, setisOpenData] = useState(false)
  const [EditBox1, setEditBox1] = useState(false)
  const [EditBox2, setEditBox2] = useState(false)
  const [EditBox3, setEditBox3] = useState(false)
  const [EditBox4, setEditBox4] = useState(false)
  const [org_type_option, setorg_type_option] = useState([])
  const [province_option, setprovince_option] = useState([])
  const [district_option, setdistrict_option] = useState([])
  const [sub_district_option, setsub_district_option] = useState([])

  const initMessageModal = {
    title: '',
    subTitle: '',
    nameItem: '',
  }
  const [ModalConfirmDialogDel, setModalConfirmDialog] = useState(false)
  const [ConfirmMessageModal, setConfirmMessageModal] = useState(initMessageModal)

  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)

  const handleModalDialog = (type_update) => {
    setTypeUpdate(type_update)
    let message_name_item = '';
    if(type_update == 'consideration'){
      message_name_item = RecordConsideration.record_consideration == 101 ? 'ขอเอกสารเพิ่มเติม' : RecordConsideration.record_consideration == 1 ? 'ผลการตรวจประเมิน : ผ่าน' : 'ผลการตรวจประเมิน : ไม่ผ่าน';
    }
    setConfirmMessageModal({
      title: 'ยืนยันการทำรายการหรือไม่',
      subTitle: DataInfo?.name_th,
      nameItem: message_name_item,
    })
    setModalConfirmDialog(true);
  }

  const onApiUpdate = () => {
    setModalConfirmDialog(false)
    let request = {};
    if(TypeUpdate == 'consideration'){
      request = {
        type_update : TypeUpdate,
        status : RecordConsideration.record_consideration == 101 ? 101 : 200,
        list_file : DataInfo?.list_file,
        //comment_document : RecordConsideration.record_consideration == 101 ? RecordConsideration.comment_consideration : null,
        ...RecordConsideration
      }
    }
    if(request?.type_update){
      dispatch(ApiUpdateApplications(ApplicationsId,request)).then(({ type,data_info }) => {
      
        if(type.endsWith('_SUCCESS')){
          setSuccessMessageModal({
            title: 'ทำรายการสำเร็จ',
            subTitle: '',
          })
          setSuccessModal(true)
        }else{
          setFailMessageModal({
            title: 'internal server error',
            subTitle: '',
          })
          setFailModal(true)
        }
        
      })  
    }

    
  }

  useEffect(() => {
    dispatch(ApiGetOrgType()).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const neworg_type_option= data_info?.data?.map((v) => ({
                text: v?.name_th_th,
                value: v?.id,
            }))
            setorg_type_option([...neworg_type_option])
        }
    })
  }, [dispatch])

  useEffect(() => {
    dispatch(ApiGetProvince()).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const newsetprovince_option= data_info?.data?.map((v) => ({
                text: v?.name_th_th,
                value: v?.id,
            }))
            setprovince_option([...newsetprovince_option])
        }
    })
  }, [dispatch])

  const fetchDataDistrict = useCallback(
    async (filter) => {
      dispatch(ApiGetDistrict(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const newsetdistrict_option= data_info?.data?.map((v) => ({
                text: v?.name_th_th,
                value: v?.id,
            }))
            setdistrict_option([...newsetdistrict_option]);    
        }
      })
    },
    [dispatch]
  )

  const fetchDataSubDistrict = useCallback(
    async (filter) => {
      dispatch(ApiGetSub_district(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const newsetsub_district_option= data_info?.data?.map((v) => ({
                text: v?.name_th_th,
                value: v?.id,
            }))
            setsub_district_option([...newsetsub_district_option]);    
        }
      })
    },
    [dispatch]
  )

  useEffect(() => {
    const sent_data_api = {
      applications_id: ApplicationsId
    }
    dispatch(ApiCMSApplicationsProfile(sent_data_api)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info.data_info){
          const ThisDataInfo = data_info.data_info;
          if(ThisDataInfo?.province_id){
            const filter = {
              skip: 0,
              limit: 100,
              province_id : ThisDataInfo?.province_id
            }
            fetchDataDistrict(filter)
            if(ThisDataInfo?.district_id){
              const filter_district = {
                skip: 0,
                limit: 100,
                district_id : ThisDataInfo?.district_id
              }
              fetchDataSubDistrict(filter_district);
            }
          }

          setDataInfo(ThisDataInfo);
          setOrganizationId(ThisDataInfo.organization_id);
          setApplicationconsiderationHistory(ThisDataInfo.application_consideration_history ? ThisDataInfo.application_consideration_history : []);
          //---- ผลการตรวจเอกสารโดยเจ้าหน้าที่
          setResultDocument(ThisDataInfo.result_document);
          setDueDateDocument(ThisDataInfo.due_date_document);
          setCommentDocument(ThisDataInfo.comment_document);
          //---- นัดตรวจประเมิน
          setDataAssessment(
            {
              date_assessment : ThisDataInfo.date_assessment,
              assessment_timestart_hour : ThisDataInfo.assessment_timestart_hour,
              assessment_timestart_minute : ThisDataInfo.assessment_timestart_minute,
              assessment_timeend_hour : ThisDataInfo.assessment_timeend_hour,
              assessment_timeend_minute : ThisDataInfo.assessment_timeend_minute,
              assessment_link : ThisDataInfo.assessment_link,
              zoom_id : ThisDataInfo.zoom_id,
              zoom_password : ThisDataInfo.zoom_password,
              comment_assessment : ThisDataInfo.comment_assessment
            }
          )
          //---- นัดตรวจประเมิน
          setRecordConsideration({
            record_consideration : ThisDataInfo.record_consideration,
            date_subcommittee : ThisDataInfo.date_subcommittee,
            date_committee_approve : ThisDataInfo.date_committee_approve,
            comment_consideration : ThisDataInfo.comment_consideration
          })
          setisOpenData(true)
        }
        
      }
    })
  }, [dispatch,fetchDataDistrict,fetchDataSubDistrict])

  const [tabactive, setTabactive] = useState('ApplicationsProfile')
  const [menuTabList, setMenuTabList] = useState([
    {
        name: 'รายละเอียดคำขอ',
        value: 'ApplicationsProfile',
        active_status: true,
    },
  ])
  const handleClickTaskMenuTab = (data) => {
    let tmpList = [...menuTabList]
    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i].value == data.value) {
        tmpList[i].active_status = true
        setTabactive(tmpList[i].value)

      } else {
        tmpList[i].active_status = false
      }
    }
    setMenuTabList([...tmpList])
  }

  //--- ST Table ตารางกิจกรรม
  const limit_data_events = 10;
  const [total_events, settotal_events] = useState(0)
  const [page_events, setPage_events] = useState(1)
  const [totalPages_events, setTotalPages_events] = useState(0)
  const [TableCellData_events, setTableCellData_events] = useState([])
  const TableColumnData_events = [
    {
        Header: 'ลำดับ',
        accessor: 'no',
        disableSortBy: false,
    },
    {
        Header: 'วันที่จัดกิจกรรม',
        accessor: 'start_at',
        disableSortBy: false,
    },
    {
        Header: 'ชื่อกิจกรรม',
        accessor: 'name',
        disableSortBy: false,
    },
    {
        Header: 'รายละเอียดการบรรยาย',
        accessor: 'description',
        disableSortBy: false,
    },
    {
        Header: 'จำนวนชั่วโมง',
        accessor: 'duration_in_hours',
        disableSortBy: false,
    },
    {
        Header: 'จำนวนผู้เข้าร่วม',
        accessor: 'participants_count',
        disableSortBy: false,
    },
  ]

  const fetchData_events = useCallback(
    async (filter) => {

      dispatch(ApiGetOrgActivity(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          if (data_info.total > 0) {
            const totalPage = Math.ceil(data_info.total / limit_data_events)
            settotal_events(data_info.total)
            setTotalPages_events(totalPage)
            const ThisSkip = data_info.skip == 0 ? 1 : (data_info.skip / limit_data_events) + 1;

            const newTableCellData = data_info.data.map((v, index) => ({
              no: limit_data_events * ThisSkip - (9 - index),
              start_at: v.activity_date ? `${DateDisplayDDMMBBB(v.activity_date)}` : '-',
              name: v.activity_name ? DisplayTableActivityName(v.activity_name) : '-',
              description: v.activity_detail ? DisplayTableActivityDescription(v.activity_detail) : '-',
              duration_in_hours: v.activity_hours ? v.activity_hours : '-',
              participants_count: v.activity_attendee_count ? v.activity_attendee_count : '-',
            }))
            setTableCellData_events(newTableCellData)
          }else{
            settotal_events(0);
            setPage_events(0);
            setTotalPages_events(0);
            setTableCellData_events([]);
          }
         
        }
      })

    },
    [dispatch]
  )

  useEffect(() => {
    if(ApplicationsId){
        const filter = {
            skip: 0,
            limit: limit_data_events,
            application_id : ApplicationsId,
        }
        fetchData_events(filter)
    }


  }, [fetchData_events, limit_data_events,ApplicationsId])


  const onChangePaginationAttendeeAttend_events = async (value) => {
    const filter = {
      skip: (value.page  - 1) * limit_data_events, 
      limit: limit_data_events,
      application_id : ApplicationsId,
    }
    fetchData_events(filter)
    setPage_events(value.page)
  }

  //--- ED Table ตารางกิจกรรม
  //--- ST Table รายการเอกสารแนบ
  const limit_data_document = 10;
  const [total_document, settotal_document] = useState(0)
  const [page_document, setPage_document] = useState(1)
  const [totalPages_document, setTotalPages_document] = useState(0)
  const [TableCellData_document, setTableCellData_document] = useState([])
  const TableColumnData_document = [
    {
        Header: 'ลำดับ',
        accessor: 'no',
        disableSortBy: false,
    },
    {
        Header: 'วันที่ทำรายการ',
        accessor: 'createdAt',
        disableSortBy: false,
    },
    {
        Header: 'รายการเอกสาร',
        accessor: 'doc_type_name',
        disableSortBy: false,
    },
    {
        Header: 'อัปโหลดโดย',
        accessor: 'upload_by',
        disableSortBy: false,
    },
    {
        Header: 'ดูไฟล์',
        accessor: 'view_file',
        disableSortBy: false,
    },
    {
        Header: 'ลงผลการตรวจเอกสาร',
        accessor: 'document_result',
        disableSortBy: false,
    },
  ]

  const fetchData_document = useCallback(
    async (filter) => {
      if(DataInfo?.organization_id){
        dispatch(ApiGetApplicationDocument(filter)).then(({ type,data_info }) => {
          if(type.endsWith('_SUCCESS')){
            if (data_info.total > 0) {
              const totalPage = Math.ceil(data_info.total / limit_data_document)
              settotal_document(data_info.total)
              setTotalPages_document(totalPage)
              const ThisSkip = data_info.skip == 0 ? 1 : (data_info.skip / limit_data_document) + 1;
  
              const newTableCellData = data_info.data.map((v, index) => ({
                no: limit_data_document * ThisSkip - (9 - index),
                createdAt: `${DateDisplayDDMMBBB(v.createdAt)}`,
                doc_type_name: v.doc_type_name ? v.doc_type_name : '-',
                upload_by: v.create_by_fullname ? v.create_by_fullname : '-',
                view_file : (
                  <ButtonOutline onClick={() => ViewFile(v)}>
                      ดูไฟล์
                  </ButtonOutline>
                ),
                document_result : (
                  <div className="box_radio">
                      <div className="radio_css">
                          <div>
                              <RadioButton 
                                  optionList={[{text : 'ผ่าน',value: true}]} 
                                  value={DataInfo?.list_file.filter((Obj) => Obj.id == v.id)[0]?.is_approved} 
                                  handleChange={(e) => onChangeRadio(v.id,index,e,DataInfo)}
                              /> 
                          </div>
                      </div>
                      <div className="radio_css">
                          <div>
                              <RadioButton 
                                  optionList={[{text : 'ไม่ผ่าน',value: false}]} 
                                  value={DataInfo?.list_file.filter((Obj) => Obj.id == v.id)[0]?.is_approved} 
                                  handleChange={(e) => onChangeRadio(v.id,index,e,DataInfo)}
                              /> 
                          </div>
                      </div>
                  </div>
                )
              }))
              setTableCellData_document(newTableCellData)
            }else{
              settotal_document(0);
              setPage_document(0);
              setTotalPages_document(0);
              setTableCellData_document([]);
            }
           
          }
        })
      }


    },
    [dispatch,DataInfo]
  )

  useEffect(() => {
      const filter = {
          skip: 0,
          limit: limit_data_document,
          application_id : ApplicationsId,
      }
      fetchData_document(filter)
  }, [fetchData_document, limit_data_document])


  const onChangePaginationAttendeeAttend_document = async (value) => {
    const filter = {
      skip: (value.page  - 1) * limit_data_document, 
      limit: limit_data_document,
      application_id : ApplicationsId,
    }
    fetchData_document(filter)
    setPage_document(value.page)
  }
  //--- ED Table รายการเอกสารแนบ

  const ViewFile = (Obj) => {
    window.open(Obj.file_url, "_blank");
  }
  const onChangeRadio = (FileId,index_file,event,ThisDataInfo) => {
    const { value } = event.target;
    let index = -1;
    index = ThisDataInfo?.list_file.findIndex(obj => obj.id == FileId);
    //alert(FileId + '--> ' + index)
    if(index >= 0){
      const newListFile = ThisDataInfo.list_file;
      newListFile[index].is_approved = value.toLowerCase() == 'true' ? true : false;
      //console.log(newListFile)
      setDataInfo({ ...DataInfo, list_file: newListFile })
    }
  }
  const [isOpenModalHistory, setisOpenModalHistory] = useState(false)

  const OpenModalHistory = () => {
    setisOpenModalHistory(true);
  }

  const loadExcel = () => {
    const filter = {
        skip: 0,
        limit: 10000,
        application_id : ApplicationsId
    }
    dispatch(ApiGetOrgActivity(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            if(data_info.total > 0){
              const resultToxlsx = data_info.data.map((v,index) => {
                  return {
                      "ลำดับ" : index + 1,
                      "วันที่จัดกิจกรรม" : DateDisplayDDMMBBB(v.activity_date),
                      "ชื่อกิจกรรม" : v.activity_name,
                      "รายละเอียดการบรรยาย" : v.activity_detail,
                      "จำนวนชั่วโมง" : v.activity_hours,
                      "จำนวนผู้เข้าร่วม" : v.activity_attendee_count,
                  };
              });
              const dataWS = XLSX.utils.json_to_sheet(resultToxlsx);
              const wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, dataWS);
              XLSX.writeFile(wb, "ตารางกิจกรรม.xlsx");
            }
        }
    })
    
  }
  return (
    <Div>
        <div className="box_header">
            <div>
                <div className="content-title">
                    อนุมัติคำขอเป็นองค์กรแม่ข่าย
                </div>
                <div className="content-title-text">
                    สำหรับเจ้าหน้าที่อนุมัติคำขอเป็นองค์กรแม่ข่าย
                </div>
            </div>            
        </div>
        
        <div className="css_magin_top_16"></div>
        <div className="border_header"></div>
        <div className="css_magin_top_16"></div>
        {/* TAB */}
        <DivTab>
            {menuTabList?.map((item, index) => (
                <button
                id={'this_name-' + item.value}
                key={'btn-' + index}
                className={item.active_status ? 'menu-active' : 'btn-menu-style'}
                onClick={() => handleClickTaskMenuTab(item)}
                >
                    {item.name}                 
                </button>
            ))}
        </DivTab>
        <div className="css_magin_top_16"></div>
        {isOpenData == true && (
            <div>
                <div className="table-wrapper">
                    <div className="table-header">
                        <div className="group">
                            <div className="table-title">รายละเอียดคำขอ</div>
                        </div>
                    </div>
                    <div className="css_magin_top_10">
                        <TableDataStyled>
                            <thead>
                              <tr>
                                <th>ลำดับ</th>
                                <th>วันที่ทำรายการ</th>
                                <th>เลขที่รับเรื่อง</th>
                                <th>รายการ</th>
                                <th>องค์กรแม่ข่าย</th>
                                <th>สถานะรายการ</th>
                              </tr>
                            </thead>
                            <tbody >
                              <tr>
                                <td>1</td>
                                <td>{DateDisplayDDMMBBB(DataInfo.submitted_at)}</td>
                                <td>{DataInfo.request_no}</td>
                                <td>{DataInfo.category_type}</td>
                                <td>{DataInfo.name_th}</td>
                                <td>{DataInfo.status_name}</td>
                              </tr>
                            </tbody>
                        </TableDataStyled>
                    </div>
                </div>
                <div className="css_magin_top_16"></div>
                {tabactive == 'ApplicationsProfile' && (
                    <div>
                        <DivInfo>
                            <div className="main_box">
                                <div>
                                    <div className="main_box_text_head">ข้อมูลเกี่ยวกับองค์กรแม่ข่าย</div>
                                    <div className="main_box_text_sub">ข้อมูลทั่วไปขององค์กรแม่ข่าย</div>
                                </div>
                                <div>
                                    <div className="main_box_edit">
                                        <div className="main_box_fill_data">
                                            <div>
                                            <div className="topic_fill">ชื่อองค์กรแม่ข่าย</div>
                                            <div>
                                                <TextField
                                                name="name_th"
                                                className="TextField_employee"
                                                value={DataInfo.name_th}
                                                onChange={(e) => setDataInfo({ ...DataInfo, name_th: e.target.value })}
                                                disabled={!EditBox1}
                                                />
                                            </div>
                                            </div>
                                        </div>
                                        <div className="main_box_fill_data">
                                            <div>
                                            <div className="topic_fill">ชื่อย่อองค์กรแม่ข่าย</div>
                                            <div>
                                                <TextField
                                                name="name_abbr"
                                                className="TextField_employee"
                                                value={DataInfo.name_abbr}
                                                onChange={(e) => setDataInfo({ ...DataInfo, name_abbr: e.target.value })}
                                                disabled={!EditBox1}
                                                />
                                            </div>
                                            </div>
                                            <div>
                                            <div className="topic_fill">เลขประจำตัวผู้เสียภาษี 13 หลัก</div>
                                            <div>
                                                <TextField
                                                name="tin"
                                                className="TextField_employee"
                                                value={DataInfo.tin}
                                                onChange={(e) => setDataInfo({ ...DataInfo, tin: e.target.value })}
                                                disabled={!EditBox1}
                                                />
                                            </div>
                                            </div>
                                            <div>
                                            <div className="topic_fill">ประเภทองค์กร</div>
                                            <div>
                                                <Dropdown
                                                    id={`dd_org_type`}
                                                    value={DataInfo.org_type}
                                                    optionList={org_type_option}
                                                    onChange={(v) => setDataInfo({ ...DataInfo, org_type: v })}
                                                    placeHolder={'เลือกประเภทองค์กร'}
                                                    disabled={!EditBox1}
                                                />
                                            </div>
                                            </div>
                                        </div>
                                        <div className="main_box_fill_data">
                                            <div>
                                            <div className="topic_fill">เบอร์โทรศัพท์องค์กร</div>
                                            <div>
                                                <TextField
                                                name="org_phone_no"
                                                className="TextField_employee"
                                                value={DataInfo.org_phone_no}
                                                onChange={(e) => setDataInfo({ ...DataInfo, org_phone_no: e.target.value })}
                                                disabled={!EditBox1}
                                                />
                                            </div>
                                            </div>
                                            <div>
                                            <div className="topic_fill">เว็ปไซด์หน่วยงาน</div>
                                            <div>
                                                <TextField
                                                name="website"
                                                className="TextField_employee"
                                                value={DataInfo.website}
                                                onChange={(e) => setDataInfo({ ...DataInfo, website: e.target.value })}
                                                disabled={!EditBox1}
                                                />
                                            </div>
                                            </div>
                                        </div>
                                        <div className="main_box_fill_data">
                                            <div>
                                            <div className="topic_fill">ที่อยู่องค์กร บ้านเลขที่</div>
                                            <div>
                                                <TextField
                                                name="house_no"
                                                className="TextField_employee"
                                                value={DataInfo.house_no}
                                                onChange={(e) => setDataInfo({ ...DataInfo, house_no: e.target.value })}
                                                disabled={!EditBox1}
                                                />
                                            </div>
                                            </div>
                                            <div>
                                            <div className="topic_fill">อาคาร/หมู่บ้าน</div>
                                            <div>
                                                <TextField
                                                name="building"
                                                className="TextField_employee"
                                                value={DataInfo.building}
                                                onChange={(e) => setDataInfo({ ...DataInfo, building: e.target.value })}
                                                disabled={!EditBox1}
                                                />
                                            </div>
                                            </div>
                                            <div>
                                            <div className="topic_fill">หมู่</div>
                                            <div>
                                                <TextField
                                                name="village_no"
                                                className="TextField_employee"
                                                value={DataInfo.village_no}
                                                onChange={(e) => setDataInfo({ ...DataInfo, village_no: e.target.value })}
                                                disabled={!EditBox1}
                                                />
                                            </div>
                                            </div>
                                        </div>
                                        <div className="main_box_fill_data">
                                            <div>
                                            <div className="topic_fill">ซอย</div>
                                            <div>
                                                <TextField
                                                name="alley"
                                                className="TextField_employee"
                                                value={DataInfo.alley}
                                                onChange={(e) => setDataInfo({ ...DataInfo, alley: e.target.value })}
                                                disabled={!EditBox1}
                                                />
                                            </div>
                                            </div>
                                            <div>
                                            <div className="topic_fill">ถนน</div>
                                            <div>
                                                <TextField
                                                name="street"
                                                className="TextField_employee"
                                                value={DataInfo.street}
                                                onChange={(e) => setDataInfo({ ...DataInfo, street: e.target.value })}
                                                disabled={!EditBox1}
                                                />
                                            </div>
                                            </div>
                                            <div>
                                            <div className="topic_fill">จังหวัด</div>
                                            <div>
                                                <Dropdown
                                                    id={`dd_province_id`}
                                                    value={DataInfo.province_id}
                                                    optionList={province_option}
                                                    placeHolder={'เลือกจังหวัด'}
                                                    disabled={!EditBox1}
                                                />
                                            </div>
                                            </div>
                                        </div>
                                        <div className="main_box_fill_data">
                                            <div>
                                            <div className="topic_fill">อำเภอ / เขต</div>
                                            <div>
                                                <Dropdown
                                                    id={`dd_district_id`}
                                                    value={DataInfo.district_id}
                                                    optionList={district_option}
                                                    placeHolder={'เลือกอำเภอ / เขต'}
                                                    disabled={!EditBox1}
                                                />
                                            </div>
                                            </div>
                                            <div>
                                            <div className="topic_fill">ตำบล / แขวง</div>
                                            <div>
                                                <Dropdown
                                                    id={`dd_sub_district_id`}
                                                    value={DataInfo.sub_district_id}
                                                    optionList={sub_district_option}
                                                    placeHolder={'เลือกตำบล / แขวง'}
                                                    disabled={!EditBox1}
                                                />
                                            </div>
                                            </div>
                                            <div>
                                            <div className="topic_fill">รหัสไปรษณีย์</div>
                                            <div>
                                                <TextField
                                                name="postal_code"
                                                className="TextField_employee"
                                                value={DataInfo.postal_code}
                                                onChange={(e) => setDataInfo({ ...DataInfo, postal_code: e.target.value })}
                                                disabled={!EditBox1}
                                                />
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="border_header"></div>
                            <div className="main_box">
                                <div>
                                    <div className="main_box_text_head">ชื่อผู้ทำรายการ</div>
                                    <div className="main_box_text_sub">ผู้ที่ทำรายการยื่นคำขอ</div>
                                </div>
                                <div>
                                    <div className="main_box_edit">
                                        <div className="main_box_fill_data">
                                            <div>
                                                <div className="topic_fill">ชื่อผู้ติดต่อหลัก</div>
                                                <div>
                                                    <TextField
                                                    name="contact_person_first_name"
                                                    className="TextField_employee"
                                                    value={DataInfo.contact_person_first_name + ' ' + DataInfo.contact_person_last_name}
                                                    disabled={!EditBox3}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="topic_fill">ตำแหน่ง</div>
                                                <div>
                                                    <TextField
                                                    name="contact_person_first_name"
                                                    className="TextField_employee"
                                                    value={DataInfo.contact_person_position}
                                                    disabled={!EditBox3}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="main_box_fill_data">
                                            <div>
                                                <div className="topic_fill">เบอร์โทรศัพท์ ติดต่อ</div>
                                                <div>
                                                    <TextField
                                                    name="contact_person_first_name"
                                                    className="TextField_employee"
                                                    value={DataInfo.contact_person_phone_no}
                                                    disabled={!EditBox3}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="topic_fill">Email ติดต่อ</div>
                                                <div>
                                                    <TextField
                                                    name="contact_person_first_name"
                                                    className="TextField_employee"
                                                    value={DataInfo.contact_person_email}
                                                    disabled={!EditBox3}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DivInfo>
                        <div className="css_magin_top_16"></div>
                        <div className="table-wrapper">
                            <div className="table-header">
                                <div className="group">
                                    <div className="table-title">ตารางกิจกรรม</div>
                                    <div className="table-total">
                                        {total_events} รายการ
                                    </div>
                                </div>
                                <div>
                                  <ButtonOutline onClick={() => loadExcel()}>Download</ButtonOutline>
                                </div>
                            </div>
                            <div className="css_magin_top_10">
                                <TableV2
                                columns={TableColumnData_events}
                                data={TableCellData_events}
                                onStateChange={onChangePaginationAttendeeAttend_events}
                                pageCount={totalPages_events}
                                page={page_events}
                                loading={false}
                                />
                            </div>
                        </div>
                        <div className="css_magin_top_16"></div>
                        <div className="table-wrapper">
                            <div className="table-header">
                                <div className="group">
                                    <div className="table-title">รายการเอกสารแนบ</div>
                                    <div className="table-total">
                                        {total_document} รายการ
                                    </div>
                                </div>
                            </div>
                            <div className="css_magin_top_10">
                                <TableV2
                                columns={TableColumnData_document}
                                data={TableCellData_document}
                                onStateChange={onChangePaginationAttendeeAttend_document}
                                pageCount={totalPages_document}
                                page={page_document}
                                loading={false}
                                />
                            </div>
                        </div> 
                        <div className="css_magin_top_16"></div>    
                        <div>
                            <DivRecordConsideration>
                                <div className="Box_header">
                                    <div className="header_topic">
                                        ผลการตรวจเอกสารโดยเจ้าหน้าที่
                                    </div>
                                    <div>
                                        <ButtonOutline onClick={OpenModalHistory}>
                                            ประวัติการตรวจเอกสาร
                                        </ButtonOutline>
                                    </div>
                                </div> 
                                <div className="border_header"></div>
                                <div className="css_magin_top_16"></div>
                                <div className="box_radio">
                                    <div className="radio_css">
                                        <div>
                                            <RadioButton 
                                                optionList={[{value: 1}]} 
                                                value={RecordConsideration.record_consideration} 
                                                handleChange={(e) => setRecordConsideration({ ...RecordConsideration, record_consideration: e.target.value })}
                                            /> 
                                        </div>
                                        <div className="text_green">
                                            ผ่าน (ส่งผลเข้าคณะอนุกรรมการ)
                                        </div>
                                    </div>
                                    <div className="radio_css">
                                        <div>
                                            <RadioButton 
                                                optionList={[{value: 2}]} 
                                                value={RecordConsideration.record_consideration} 
                                                handleChange={(e) => setRecordConsideration({ ...RecordConsideration, record_consideration: e.target.value })}
                                            /> 
                                        </div>
                                        <div className="text_red">
                                            ไม่ผ่าน (ส่งผลเข้าคณะอนุกรรมการ)
                                        </div>
                                    </div>
                                    <div className="radio_css">
                                        <div>
                                            <RadioButton 
                                                optionList={[{value: 101}]} 
                                                value={RecordConsideration.record_consideration} 
                                                handleChange={(e) => setRecordConsideration({ ...RecordConsideration, record_consideration: e.target.value })}
                                            /> 
                                        </div>
                                        <div className="text_normal">
                                            ขอเอกสารเพิ่มเติม
                                        </div>
                                    </div>
                                </div>
                                <div className="css_magin_top_16"></div>
                                <div className="box_date">
                                    <div>
                                        <Field className="field" label="วันที่เข้าประชุมคณะอนุกรรมการสภา">
                                            <Date
                                                className={'input-calendar'}
                                                value={RecordConsideration.date_subcommittee}
                                                onChange={(v) => setRecordConsideration({ ...RecordConsideration, date_subcommittee: v })}
                                            />
                                        </Field>
                                    </div>
                                    <div>
                                        <Field className="field" label="วันที่เข้าประชุมคณะกรรมการสภาวิศวกร">
                                            <Date
                                                className={'input-calendar'}
                                                value={RecordConsideration.date_committee_approve}
                                                onChange={(v) => setRecordConsideration({ ...RecordConsideration, date_committee_approve: v })}
                                            />
                                        </Field>
                                    </div>
                                </div>
                                <div className="css_magin_top_16"></div>
                                <div className="box_comment">
                                    <Field className="field" label="หมายเหตุแจ้งสมาชิก กรณีไม่ผ่าน">
                                        <InputTextArea
                                            className="InputTextArea_css"
                                            initValue={RecordConsideration.comment_consideration}
                                            onChange={(e) => setRecordConsideration({ ...RecordConsideration, comment_consideration: e.target.value })}
                                            placeHolder=""
                                            borderColor={'var(--Gray-300)'}
                                            borderWidth="1px"
                                        />
                                    </Field>
                                </div>
                                <div className="css_magin_top_16"></div>
                                <div className="box_button">
                                    {permission_CpdManageOrgApproveList_Extend_Save?.canRead === true && (
                                      <Button onClick={() => handleModalDialog('consideration')}>
                                          บันทึกผลและแจ้งสมาชิก
                                      </Button>
                                    )}
                                </div>
                                <div className="css_magin_top_16"></div>
                            </DivRecordConsideration> 
                        </div>                 
                    </div>
                )}
            </div>
        )}

      <DialogConfirmV2
        open={Boolean(ModalConfirmDialogDel)}
        onClose={() => setModalConfirmDialog(false)}
        onNo={() => setModalConfirmDialog(false)}
        onSubmit={() => {
          setModalConfirmDialog(false)
          onApiUpdate()
        }}
        icon={successIcon}
        title={ConfirmMessageModal.title}
        subTitle={ConfirmMessageModal.subTitle}
        nameItem={ConfirmMessageModal.nameItem}
        textYes='ยืนยัน'
        textNo='ยกเลิก'
      />

      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={() => {
          setSuccessModal(false)
        }}
        icon={successIcon}
        title={successMessageModal.title}
        description={successMessageModal.subTitle}
        textYes='ตกลง'
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => {
          setFailModal(false)
        }}
        icon={failIcon}
        title={failMessageModal.title}
        description={failMessageModal.subTitle}
        textYes='ตกลง'
      />

      <SidePanel isOpen={isOpenModalHistory} setIsOpen={setisOpenModalHistory} width={1200}>
            <ModalApplicationHistory
              width={1200} 
              onClose={() => setisOpenModalHistory(false)} 
              onSubmit={() => {
                setisOpenModalHistory(false)
              }}
              ApplicationDocHistory={null}
              ApplicationconsiderationHistory={ApplicationconsiderationHistory}
            />
      </SidePanel>
    </Div>
  )
}

export default OrgApplicationsExtendProfile
