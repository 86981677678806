import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

// persist reduxt
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// reducer
import loading from './reducers/loading'
import alert from './reducers/alert'
import master from 'redux/reducers/master'
import activityCpd from 'redux/reducers/activityCpd'

// include reducer here
const rootReducer = combineReducers({
  loading,
  alert,
  master,
  activityCpd,
})

const persistConfig = {
  key: 'root',
  storage,
  // set store reducer that you want to persist here
  // whitelist: ['sharedService', 'documentTemplate'],
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const composeEnhancers =
  (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose
const enhancer = composeEnhancers(applyMiddleware(thunk))

const store = createStore(persistedReducer, enhancer)
const persistor = persistStore(store)

export { store, persistor }
