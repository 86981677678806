import React from 'react'
import QRCode from 'react-qr-code'

import styled from 'styled-components'
import { Box, Stack } from '@mui/material'
import Button from 'components/form/button/Button'

import { LiaTimesSolid } from 'react-icons/lia'

import qrIcon from 'assets/images/qr-icon.png'

const Div = styled.div`
  padding: 24px;

  .button-width {
    flex-grow: 1;
  }

  .heading {
    color: var(--Gray-900);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
  }
  .sub-heading {
    color: var(--Gray-600);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
  }
  .close-icon {
    cursor: pointer;
  }
`

function QrModalContent({ data, type, onClose }) {
  const downloadQRCode = () => {
    // Select the SVG element using its ID
    const svg = document.getElementById('QRCode')
    // Use XMLSerializer to serialize the SVG
    const svgData = new XMLSerializer().serializeToString(svg)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const img = new Image()
    // Define the SVG data as a Blob
    const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' })
    const url = URL.createObjectURL(svgBlob)

    img.onload = () => {
      canvas.width = img.width
      canvas.height = img.height
      ctx.drawImage(img, 0, 0)
      URL.revokeObjectURL(url)

      // Convert the canvas to a data URL
      const imgURL = canvas.toDataURL('image/png')

      // Create a link and trigger the download
      const a = document.createElement('a')
      a.href = imgURL
      a.download = type === 'register' ? `${data.code}-register.png` : `${data.code}-check-in.png`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }

    img.src = url

    onClose()
  }

  return (
    <Div>
      <Stack sx={{ mb: '20px' }} direction="row" justifyContent="space-between ">
        <Stack direction="row" spacing={2}>
          <Box sx={{ width: 48, height: 48 }}>
            <img src={qrIcon} />
          </Box>
        </Stack>
        <LiaTimesSolid size={18} className="close-icon" onClick={onClose} />
      </Stack>

      <Box sx={{ mb: '20px' }}>
        <div className="heading">
          {type === 'register'
            ? 'กรุณารับ QR Code เพื่อใช้ลงทะเบียนเข้าร่วมกิจกรรม'
            : 'กรุณารับ QR Code เพื่อใช้ Check-in เข้าร่วมกิจกรรม'}
        </div>
        <div className="sub-heading">
          <b>ชื่อกิจกรรม :</b> {data.name}
        </div>
        <div className="sub-heading">
          <b>รหัสกิจกรรม :</b> {data.code}
        </div>
      </Box>
      <Box sx={{ mb: 4, padding: 2, backgroundColor: 'var(--Gray-50)' }}>
        <Stack direction="row" justifyContent="center" alignItems="center">
          {type === 'register' ? (
            <QRCode id="QRCode" title="register" value={data?.qr_url_preregister} />
          ) : (
            <QRCode id="QRCode" title="checkin" value={data?.qr_url_checkin} />
          )}
        </Stack>
      </Box>

      <Stack direction="row">
        <Button className="button-width" onClick={downloadQRCode}>
          Download
        </Button>
      </Stack>
    </Div>
  )
}

export default QrModalContent
