import React from 'react'

import styled from 'styled-components'
import { TextField, InputAdornment, Button } from '@mui/material'

// Create a custom styled TextField
const CustomTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    border-radius: 8px;

    & fieldset {
      border: 1px solid var(--Gray-300);
    }

    &:hover fieldset {
      border: 1px solid var(--Gray-300);
    }

    &.Mui-focused fieldset {
      border: 1px solid var(--Gray-300);
    }

    &.Mui-disabled {
      background-color: var(--Gray-50);
      color: var(--Gray-500);
    }
  }
`
// Create a custom styled Button
const RoundedButton = styled(Button)`
  color: var(--Gray-700) !important;
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 600 !important;

  border-radius: 0 !important;
  border-left: 1px solid var(--Gray-300) !important;

  &:hover {
    background-color: inherit !important;
    border-left: 1px solid var(--Gray-300) !important;
  }

  &.Mui-disabled {
    background-color: var(--Gray-50);
    color: var(--Gray-500);
  }
`

function TextFieldWithIcon({ value, onChange, placeholder = 'กรอกข้อความ', iconButton, disabled = false }) {
  return (
    <CustomTextField
      fullWidth
      size="small"
      disabled={disabled}
      value={value}
      onChange={onChange}
      variant="outlined"
      placeholder={placeholder}
      InputProps={{
        endAdornment: <InputAdornment position="end">{<RoundedButton>{iconButton} </RoundedButton>}</InputAdornment>,
      }}
    />
  )
}

export default TextFieldWithIcon
