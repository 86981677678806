import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import {  getAdminUser } from '../../utils/common'
import { Api_GETCpdConsent,Api_UpdateCpdConsent } from '../../redux/actions/master'

import Button from '../../components/form/button/Button'
import TextField from '../../components/form/TextField'
import Field from '../../components/form/Field'
import TextEditorV2 from '../../components/widget/element/TextEditorV2'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'

import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'

const Div = styled.div`
  font-size: 14px;
  .text-editor {
    min-height: 130px;
    background: #FFFFFF;
  }
  .ql-container {
    min-height: 100px; 
    font-family: inherit;
  }
  .css_magin_top_16 {
    margin-top: 16px;
  }
  .fill_box{
    overflow-y: auto;
    /*margin-bottom: 140px;*/
    margin-bottom: 100px;
  }
  .dialog-footer {
    text-align: end;
    right: 0px;
    bottom: 70px;
    background-color : #FFFFFF;
    position: absolute;
    width: 100%;
    padding: 0px 24px;
    .button_margin {
      margin: 10px;
    }
  }
`



const TabCpdConsent = () => {
    const dispatch = useDispatch()
    const user = getAdminUser()
    const fix_id_consent = 1;
    const [DataInfo, setDataInfo] = useState({
        headline : null,
        detail : null
    })
    const initMessageModal = {
        headline: '',
        message: '',
    }
    const [successModal, setSuccessModal] = useState(false)
    const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
    const [failModal, setFailModal] = useState(false)
    const [failMessageModal, setFailMessageModal] = useState(initMessageModal)
    useEffect(() => {
        dispatch(Api_GETCpdConsent(fix_id_consent)).then(({ type,data_info }) => {
            if(type.endsWith('_SUCCESS')){
                if(data_info.id){
                    setDataInfo(data_info);
                }
            }
        })
    }, [dispatch])

    const onProcessApi = () => {
        const sent_data_api = DataInfo;
        dispatch(Api_UpdateCpdConsent(fix_id_consent,sent_data_api)).then(({ type,data_info }) => {
          if(type.endsWith('_SUCCESS')){
            setSuccessMessageModal({
                headline : 'บันทึกสำเร็จ',
                message : '',
            })
            setSuccessModal(true)
          }else{
            setFailMessageModal({
                headline : 'บันทึกไม่สำเร็จ',
                message : '',
            })
            setFailModal(true)
          }     
        })
        
    }

  return (
    <Div>
        <div className="fill_box">
            <div>
                <Field className="field" label="Headline">
                    <TextField
                        name="headline"
                        placeholder={'ระบุ'}
                        value={DataInfo.headline}
                        onChange={(e) => setDataInfo({ ...DataInfo, headline: e.target.value })}
                    />
                </Field>
                <div>{'maximum 500 characters'}</div>
            </div>
            <div className="css_magin_top_24"></div>
            <div>
                <Field className="field" label="Body text">
                    <TextEditorV2
                        className="text-editor"
                        placeHolder="Enter..."
                        initValue={DataInfo.detail}
                        onBlur={(e) => setDataInfo({ ...DataInfo, detail: e.html })}
                    />
                </Field>
            </div>
        </div>
        {/* Button */}
        <div className="dialog-footer">
            <Button className="button_margin" onClick={onProcessApi}>
                {'บันทึก'}
            </Button>
        </div>
        <DialogSuccess
            open={Boolean(successModal)}
            onClose={() => setSuccessModal(false)}
            onSubmit={() => setSuccessModal(false)}
            icon={successIcon}
            title={successMessageModal.headline}
            description={successMessageModal.message}
            textYes='ตกลง'
        />
        <DialogFail
            open={Boolean(failModal)}
            onClose={() => setFailModal(false)}
            onSubmit={() => {
            setFailModal(false)
            }}
            icon={failIcon}
            title={failMessageModal.headline}
            description={failMessageModal.message}
            textYes='ตกลง'
        />
    </Div>
  )
}

export default TabCpdConsent
