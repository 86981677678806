import { combineReducers } from 'redux'
import {
  GET_ACTIVITY_CPD,
  GET_ACTIVITY_CPD_BY_ID,
  CREATE_ACTIVITY_CPD,
  EDIT_ACTIVITY_CPD,
  DELETE_ACTIVITY_CPD,
  CANCEL_ACTIVITY_CPD,
  GET_REGISTERED_NAME_LIST,
  GET_INSTRUCTORS,
} from 'redux/actionTypes'

const initialState = {
  activitiesCPD: [],
  activityInfo: {},
  registeredNameList: [],
  instructorsNameList: [],
  total: 1,
  totalInstructor: 0,
  totalCoEMember: 0,
  totalNormalMember: 0,
  limit: 10,
  skip: 0,
  isLoading: false,
  state: '',
}

const activityCPDReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_ACTIVITY_CPD.REQUEST: {
      return { ...state, isLoading: true, state: 'GET_ACTIVITY_CPD.REQUEST' }
    }
    case GET_ACTIVITY_CPD.SUCCESS: {
      const { data, limit, skip, total } = action
      return { ...state, activitiesCPD: data, limit, skip, total, isLoading: false, state: 'GET_ACTIVITY_CPD.SUCCESS' }
    }
    case GET_ACTIVITY_CPD.FAILURE: {
      return { ...state, isLoading: false, state: 'GET_ACTIVITY_CPD.FAILURE' }
    }
    case GET_ACTIVITY_CPD_BY_ID.REQUEST: {
      return { ...state, isLoading: true, state: 'GET_ACTIVITY_CPD_BY_ID.REQUEST' }
    }
    case GET_ACTIVITY_CPD_BY_ID.SUCCESS: {
      const { data } = action
      return { ...state, isLoading: false, activityInfo: data, state: 'GET_ACTIVITY_CPD_BY_ID.SUCCESS' }
    }
    case GET_ACTIVITY_CPD_BY_ID.FAILURE: {
      return { ...state, isLoading: false, state: 'GET_ACTIVITY_CPD_BY_ID.FAILURE' }
    }
    case CREATE_ACTIVITY_CPD.REQUEST: {
      return { ...state, isLoading: true, state: 'CREATE_ACTIVITY_CPD.REQUEST' }
    }
    case CREATE_ACTIVITY_CPD.SUCCESS: {
      return { ...state, isLoading: false, state: 'CREATE_ACTIVITY_CPD.SUCCESS' }
    }
    case CREATE_ACTIVITY_CPD.FAILURE: {
      return { ...state, isLoading: false, state: 'CREATE_ACTIVITY_CPD.FAILURE' }
    }
    case EDIT_ACTIVITY_CPD.REQUEST: {
      return { ...state, isLoading: true, state: 'EDIT_ACTIVITY_CPD.REQUEST' }
    }
    case EDIT_ACTIVITY_CPD.SUCCESS: {
      return { ...state, isLoading: false, state: 'EDIT_ACTIVITY_CPD.SUCCESS' }
    }
    case EDIT_ACTIVITY_CPD.FAILURE: {
      return { ...state, isLoading: false, state: 'EDIT_ACTIVITY_CPD.FAILURE' }
    }
    case DELETE_ACTIVITY_CPD.REQUEST: {
      return { ...state, isLoading: true, state: 'DELETE_ACTIVITY_CPD.REQUEST' }
    }
    case DELETE_ACTIVITY_CPD.SUCCESS: {
      return { ...state, isLoading: false, state: 'DELETE_ACTIVITY_CPD.SUCCESS' }
    }
    case DELETE_ACTIVITY_CPD.FAILURE: {
      return { ...state, isLoading: false, state: 'DELETE_ACTIVITY_CPD.FAILURE' }
    }
    case CANCEL_ACTIVITY_CPD.REQUEST: {
      return { ...state, isLoading: true, state: 'CANCEL_ACTIVITY_CPD.REQUEST' }
    }
    case CANCEL_ACTIVITY_CPD.SUCCESS: {
      return { ...state, isLoading: false, state: 'CANCEL_ACTIVITY_CPD.SUCCESS' }
    }
    case CANCEL_ACTIVITY_CPD.FAILURE: {
      return { ...state, isLoading: false, state: 'CANCEL_ACTIVITY_CPD.FAILURE' }
    }
    case GET_REGISTERED_NAME_LIST.REQUEST: {
      return { ...state, isLoading: true, state: 'GET_REGISTERED_NAME_LIST.REQUEST' }
    }
    case GET_REGISTERED_NAME_LIST.SUCCESS: {
      const { data, total, skip, limit, is_member_coe } = action

      return is_member_coe
        ? {
            ...state,
            registeredNameList: data,
            total,
            totalCoEMember: total,
            skip,
            limit,
            isLoading: false,
            state: 'GET_REGISTERED_NAME_LIST.SUCCESS',
          }
        : {
            ...state,
            registeredNameList: data,
            total,
            totalNormalMember: total,
            skip,
            limit,
            isLoading: false,
            state: 'GET_REGISTERED_NAME_LIST.SUCCESS',
          }
    }
    case GET_REGISTERED_NAME_LIST.FAILURE: {
      return { ...state, isLoading: false, state: 'GET_REGISTERED_NAME_LIST.FAILURE' }
    }
    case GET_INSTRUCTORS.REQUEST: {
      return { ...state, isLoading: true, state: 'GET_INSTRUCTORS.REQUEST' }
    }
    case GET_INSTRUCTORS.SUCCESS: {
      const { data, total, skip, limit } = action

      return {
        ...state,
        instructorsNameList: data,
        total,
        totalInstructor: total,
        skip,
        limit,
        isLoading: false,
        state: 'GET_INSTRUCTORS.SUCCESS',
      }
    }
    case GET_INSTRUCTORS.FAILURE: {
      return { ...state, isLoading: false, state: 'GET_INSTRUCTORS.FAILURE' }
    }
    default:
      return state
  }
}

export default combineReducers({
  activityCPDReducer,
})
