import styled from 'styled-components'
import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {  DateDisplayDDMMBBB,DateTimeHHmmssDisplay,GetPermissionOther } from '../../utils/common'
import { ApiAppealInfo,ApiProcessAppeal } from '../../redux/actions/appeal'

import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'
import DialogConfirmV2 from '../../components/dialog/DialogConfirmV2'
import TextField from '../../components/form/TextField'
import Dropdown from '../../components/form/Dropdown'
import Field from '../../components/form/Field'
import InputTextArea from '../../components/widget/element/InputTextArea'
import RadioButton from '../../components/form/RadioButton'

import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'
import iconFile from '../../assets/images/pdf-icon.png'
import iconFileimg from '../../assets/images/icon-file-round.svg'

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { GrStatusGoodSmall } from 'react-icons/gr'


const Div = styled.div`
font-size: 14px;
.box_radio{
    padding: 0px 0px;
    display: flex;
    gap : 10px;
    .radio_css{
        display: flex;
        align-items: center;
    }
    .text_green{
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color : #085D3A;
    }
    .text_red{
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color : #912018;
    }
    .text_normal{
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color : #101828;
    }
}
  .css_header_detail {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    .date_css{
      color: #7F56D9;
      font-weight: bold;
    }
  }
  .content_header {
    padding: 14px 14px;
    
    width : ${(props) => props.width}px;
    position: fixed;
    background: #FFFFFF;
    top: 0;
    right: 0;
    z-index: 1;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .dialog-content {
    padding: 0px 14px;
    padding-top: 70px;
    padding-bottom: 110px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .icon {
    cursor: pointer;
  }
  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .dialog-footer {
    /*margin-bottom: 15px;*/
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    width : ${(props) => props.width}px;
    background-color : #FFFFFF;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }

  .content{
    margin:10px 0px;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }
  .div_box_deatail{
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    background-color: #F9FAFB;
    font-size: 14px;
    padding: 10px;
    white-space: pre-wrap;
    line-break: anywhere;
  }
  .div_box_deatail_text_area{
    min-height: 128px;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    background-color: #F9FAFB;
    font-size: 14px;
    padding: 10px;
    white-space: pre-wrap;
    line-break: anywhere;
  }
  .other_box{
    display: grid;
    grid-template-columns: repeat(2, 49% [ col-start ] 49% [ col-end ]);
    gap: 2%;
    align-items: start;
  }
  .content-header{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .status-badge {
    width: fit-content;
    padding: 2px 6px;
    text-align: center;
    font-weight: 500;
    border-radius: 16px;
    color: #475467;
    background: #f5f5f4;
    border: 1px #e7e5e4 solid;

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: #475467;
    }
  }
  .status-badge-code {
    width: fit-content;
    padding: 2px 6px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    border-radius: 16px;
    color: #344054;
    background: #F9FAFB;
    border: 1px #EAECF0 solid;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .status-badge-engineer {
    width: fit-content;
    padding: 2px 6px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    border-radius: 16px;
    color: #A91F23;
    background: #FFF;
    border: 1px #FECDCA solid;
    display: flex;
    gap: 2px;
    align-items: center;
  }
  .content_text_bold{
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color : #344054;
  }
  .content_text_normal{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color : #475467;
  }
  .content_text_cms{
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color : #A91F23;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
  }
  .css_margin_top_24{
    margin-top: 24px;
  }
  .css_margin_top_16{
    margin-top: 16px;
  }
  .file {
    width: 100%;
    display: inline-flex;
    padding: 14px;
    margin-top: 16px;
    background: var(--Base-White);
    border: 1px solid var(--Gray-300);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }

  .icon-wrapper {
    width: 32px;
  }

  .detail-wrapper {
    flex: 1;
    margin: 0 14px;
    font-size: 14px;
    overflow: hidden;

    .file-name {
      margin-bottom: 2px;
      text-overflow: ellipsis;
      overflow: hidden;

      a {
        font-weight: 500;
        color: var(--Gray-700);
      }
    }
}
`

const ModalAppealInfo = ({ onClose, onSubmit,ThisId,...props  }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const permission_AppealAppeal_Approve = GetPermissionOther('AppealAppeal','AppealAppeal_Approve');

  const [isOpenData, setisOpenData] = useState(false)
  const [DataInfo, setDataInfo] = useState(null)


  useEffect(() => {
    const sent_data_to_api = {
        appeal_id : ThisId,
    };
    dispatch(ApiAppealInfo(sent_data_to_api)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info.data_info){
            setDataInfo(data_info.data_info);
            setisOpenData(true)
        }
      }
    })

}, [dispatch])
  //---- Modal Success And Fail
  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)
  const [ConfirmMessageModal, setConfirmMessageModal] = useState(initMessageModal)

  const ConfirmDialog = () => {
    setConfirmMessageModal({
        headline : 'ยืนยันการตรวจสอบคำร้อง',
        message : '',
    })
    setModalConfirmDialog(true)
  }

  const ProcessUpdate = () => {
    setModalConfirmDialog(false);

    
    const sent_data_api = {
        appeal_id : DataInfo.id,
        cms_status_id : DataInfo.cms_status_id,
        cms_point : DataInfo.cms_point,
        cms_remark : DataInfo.cms_remark
    };
    dispatch(ApiProcessAppeal(sent_data_api)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info.code == 200){
            setSuccessMessageModal({
                headline : 'ทำรายการสำเร็จ',
                message : '',
            })
            setSuccessModal(true)
        }else{
            setFailMessageModal({
                headline : 'ทำรายการไม่สำเร็จ',
                message : data_info.message,
            })
            setFailModal(true)
        }
      }else{
        setFailMessageModal({
            headline : 'internal server error',
            message : '',
        })
        setFailModal(true)
      }     
    })
    
    //onSubmit()
  }

  return (
    <Div {...props}>
      <div className="content_header">
        <header>
          <div className="content-title">
          ตรวจสอบคำร้อง
          </div>
          <div className="wrp-tool-right">
            <CloseOutlinedIcon size={30} onClick={onClose} className="icon" />
          </div>
        </header>
        <hr />
      </div>
      
      <div className="dialog-content">
        {isOpenData && (
            <div>
                <div className="content">
                    <div className="content_text_bold">วันที่ส่งคำร้อง {DateTimeHHmmssDisplay(DataInfo.created_at)} น.</div>
                </div>
                {
                    /*
                    <div className="content">
                        <div
                        className="status-badge"
                        style={{
                            borderColor: DataInfo.status_badge_color?.border,
                            backgroundColor: DataInfo.status_badge_color?.background,
                        }}
                        >
                        <GrStatusGoodSmall 
                            className="status"
                            style={{
                            color: DataInfo.status_badge_color?.color,
                            }}
                        />
                        {DataInfo.status_name}
                        </div>
                    </div>
                    */
                }


                <div className="content">
                    <Field className="field" label="รหัสกิจกรรม">
                        <div className="div_box_deatail">
                            {DataInfo.event_code}
                        </div>
                    </Field>
                </div>
                <div className="content">
                    <Field className="field" label="ประเภทคำร้อง">
                        <div className="div_box_deatail">
                            {DataInfo.petition_type_name}
                        </div>
                    </Field>
                </div>
                <div className="content">
                    <Field className="field" label="รายละเอียด">
                        <div className="div_box_deatail_text_area">
                            {DataInfo.description}
                        </div>
                    </Field>
                </div>
                <div className="content">
                    <div className="content_text_bold">แนบเอกสารประกอบ</div>
                </div>
                <div className="content">
                  {DataInfo?.list_file?.map((item, index) => (
                        <div className="file">
                            <div className="icon-wrapper">
                                <img src={iconFileimg} />
                            </div>
                            <div className="detail-wrapper">
                                <div className="file-name">
                                    <a href={item.file_url} target="_blank" rel="noreferrer">
                                        {item.file_name}
                                    </a>
                                </div>
                                { (item.file_size) && ( 
                                    <div className="file-size">{item.file_size} KB</div>  
                                )}                                      
                            </div>
                        </div>
                    ))}
                </div>
                <div className="content">
                    <div className="other_box">
                        <Field className="field" label="จำนวนชั่วโมงการทำกิจกรรม">
                            <div className="div_box_deatail">
                                { DataInfo.received_hours}
                            </div>
                        </Field>
                        <Field className="field" label="คะแนน CPD ของสมาชิก">
                            <div className="div_box_deatail">
                                { DataInfo.point }
                            </div>
                        </Field>
                    </div>
                </div>
                <div className="css_margin_top_16"></div>
                <div className="border_header"></div>
                <div className="css_margin_top_16"></div>
                <div className="content">
                    <div className="content_text_cms">เจ้าหน้าที่ลงผลตรวจและปรับปรุงคะแนน</div>
                </div>
                {
                    /*
                    <div className="content-header">
                    <div className="status-badge-code">                   
                        <div
                        className="status-badge"
                        style={{
                            borderColor: DataInfo.cms_status_badge_color?.border,
                            backgroundColor: DataInfo.cms_status_badge_color?.background,
                            color: DataInfo.cms_status_badge_color?.color,
                        }}
                        >
                        {DataInfo.cms_status_name}
                        </div>
                        <div>คะแนน CPD {DataInfo.point} คะแนน</div>
                    </div>
                    </div>
                    */
                }
                

                <div className="content">
                    <div className="box_radio">
                        <div className="radio_css">
                            <div>
                                <RadioButton 
                                    optionList={[{value: 2 , text : 'อนุมัติ'}]} 
                                    value={DataInfo.cms_status_id} 
                                    handleChange={(e) => setDataInfo({ ...DataInfo, cms_status_id: e.target.value })}
                                /> 
                            </div>
                        </div>
                        <div className="radio_css">
                            <div>
                                <RadioButton 
                                    optionList={[{value: 3 , text : 'ไม่อนุมัติ'}]} 
                                    value={DataInfo.cms_status_id} 
                                    handleChange={(e) => setDataInfo({ ...DataInfo, cms_status_id: e.target.value })}
                                /> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <Field className="field" label="ปรับปรุงคะแนน CPD ของสมาชิก">
                        <TextField
                            name="topic_name"
                            className="css_TextField"
                            placeholder={'ระบุ'}
                            value={DataInfo.cms_point}
                            onChange={(e) => setDataInfo({ ...DataInfo, cms_point: e.target.value })}
                        />
                    </Field>
                </div>
                <div className="content">
                    <Field className="field" label="หมายเหตุจากหน้าที่">
                        <InputTextArea
                            className="InputTextArea_css"
                            initValue={DataInfo.cms_remark}
                            onChange={(e) => setDataInfo({ ...DataInfo, cms_remark: e.target.value  })}
                            placeHolder="กรุณาระบุ..."
                            borderColor={'var(--Gray-300)'}
                            borderWidth="1px"
                        />
                    </Field>
                </div>
            </div>

        )}


      </div>

      <div className="dialog-footer">
        <hr />
        <ButtonOutline className="button_margin" onClick={onClose}>
          {'ยกเลิก'}
        </ButtonOutline>
        {(permission_AppealAppeal_Approve?.canRead === true) && (
          <Button className="button_margin" onClick={ConfirmDialog}>
            {'บันทึก'}
          </Button>
        )}
      </div>
      
      {/* Dialog */}
        <DialogConfirmV2
          open={Boolean(ModalConfirmDialog)}
          onClose={() => setModalConfirmDialog(false)}
          onNo={() => setModalConfirmDialog(false)}
          onSubmit={ProcessUpdate}
          icon={successIcon}
          title={ConfirmMessageModal.headline}
          nameItem={ConfirmMessageModal.message}
          textYes={'ยืนยัน'}
          textNo={'ยกเลิก'}
        />
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={() => onSubmit()}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes='ตกลง'
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => {
          setFailModal(false)
        }}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes='ตกลง'
      />
    </Div>
  )
}

export default ModalAppealInfo
