import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { DateDisplay,GetPermissionOther } from 'utils/common'
import { v4 as uuidv4 } from 'uuid'

import { useDispatch, useSelector } from 'react-redux'
import { selectActivityCPD } from 'redux/selectors'
import { getInstructorNameList, getRegisteredNameList,editActivityCpd,getActivityCpdBYId } from 'redux/actions/activityCpd'

import {
  getMemberCOE,
} from 'redux/actions/master'

import styled from 'styled-components'
import { Box, Stack,Divider } from '@mui/material'
import Card from 'components/common/Card'
import Table from 'components/common/Table'
import ButtonOutline from 'components/form/button/ButtonOutline'
import Badge from 'components/containers/Badge'
import TextField from 'components/form/TextField'
import Button from 'components/form/button/Button'
import DialogConfirmV2 from 'components/dialog/DialogConfirmV2'
import DialogSuccess from 'components/dialog/DialogSuccess'
import DialogFail from 'components/dialog/DialogFail'

import successIcon from 'assets/images/success-icon.png'
import failIcon from 'assets/images/fail-icon.png'
import warningIcon from 'assets/images/warning-icon.png'

import { LiaCheckSolid, LiaTimesSolid } from 'react-icons/lia'
import { FiLink, FiPlus, FiTrash2 } from 'react-icons/fi'

const Div = styled.div`
  .input-heading {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .badge-type-join-style {
    &.join {
      border-color: var(--success-200);
      background: var(--success-50);
      color: var(--success-700);
    }
    &.dont-join {
      border-color: var(--Error-200);
      background: var(--Error-50);
      color: var(--Error-700);
    }
    &.dont-scan {
      border-color: var(--Gray-200);
      background: var(--Gray-50);
      color: var(--Gray-700);
    }
  }
  .heading-table {
    color: var(--Gray-900, #101828);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
  }
  .card-styled {
    width: 100%;
  }
  .td-text_center {
    text-align: center;
  }
  .margin_left_8{
    margin-left: 8px;
  }
`
const TableWrapper = styled.div`
  .header-table {
    padding: 26px 24px;
    border-radius: 8px 8px 0 0;
    border: 1px solid var(--Gray-200);
  }
  .heading-table {
    color: var(--Gray-900, #101828);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
  }
  .sub-heading-table {
    color: var(--Gray-600);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
  }
`

const TableDataStyled = styled.table`
  width: 100%;
  border-collapse: collapse;
  thead {
    background-color: var(--Gray-50);

    border-bottom: 1px solid var(--Gray-200);
    th {
      padding: 13px 24px;
    }
  }

  tbody {
    td {
      padding: 16px 24px;
      border-bottom: 1px solid var(--Gray-200);
    }
  }
`

function InstructorNameListPart() {
  // external hook
  const dispatch = useDispatch()
  const { id } = useParams()

  // initiate data
  const [isEdit, setIsEdit] = useState(false)

  const toggleEditMode = () => {
    setIsEdit(!isEdit)
  }
  const openEditMode = () => {
    toggleEditMode()
  }
  const cancelEdit = () => {
    toggleEditMode()
  }

  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false)
  const [successModal, setsuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)

  const openConfirmModal = () => {
    setIsOpenConfirmModal(true)
  }
  const closeConfirmModal = () => {
    setIsOpenConfirmModal(false)
  }

  const [form, setForm] = useState({
    event_field_lv4: '',
    instructors: []
  })
  const fetchInstructorNameList = useCallback(
    (id, filter = { skip: 0, limit: 10 }) => {
      dispatch(getInstructorNameList(id, filter))
    },
    [dispatch]
  )

  useEffect(() => {
    fetchInstructorNameList(id)
  }, [id])

  // page,pageCount
  const { skip, limit, total } = useSelector(selectActivityCPD)
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)

  useEffect(() => {
    setPageCount(Math.ceil(total / limit))
  }, [limit, total, skip])

  const { activityInfo } = useSelector(selectActivityCPD)

  useEffect(() => {
    if (activityInfo.id) {
      console.log(activityInfo)
      const {
        event_field_lv4,
        instructors,
      } = activityInfo
      setForm((prev) => ({
        ...prev,
        event_field_lv4,
        instructors: instructors ? instructors?.map((v) => ({ ...v, key: uuidv4() })) : [],
      }))
    }
  }, [activityInfo])

  const permission_CpdManageOrgActivity_Edit_instructors = GetPermissionOther('CpdManageOrgActivity_COE','CpdManageOrgActivity_COE_Edit_instructors');
  const permission_CpdManageOrgActivity_Dowload_instructors = GetPermissionOther('CpdManageOrgActivity_COE','CpdManageOrgActivity_COE_Dowload_instructors');
  

  // instructor names
  const instructorColumns = [
    {
      Header: 'ลำดับ',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'เลขที่สมาชิก',
      accessor: 'user_no',
      disableSortBy: false,
    },
    {
      Header: 'ชื่อ นามสกุล',
      accessor: 'full_name',
      disableSortBy: false,
    },
    {
      Header: 'จำนวนชั่วโมง',
      accessor: 'event_hours_receiving',
      disableSortBy: false,
    },
    {
      Header: 'คะแนน CPD ที่ได้รับ',
      accessor: 'point',
      disableSortBy: false,
    },
  ]
  const { instructorsNameList } = useSelector(selectActivityCPD)
  const [instructors, setInstructors] = useState([])

  useEffect(() => {
    const newInstructors = instructorsNameList.map((v, i) => ({
      ...v,
      no: skip + 1 + i,
      createdAt: DateDisplay(v.createdAt),
      status_name: (
        <Badge
          className={`badge-type-join-style ${
            v.status_name === 'เข้าร่วม' ? 'join' : v.status_name === 'ไม่เข้าร่วม' ? 'dont-join' : 'dont-scan'
          }`}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            {v.status_name === 'เข้าร่วม' ? (
              <LiaCheckSolid />
            ) : v.status_name === 'ไม่เข้าร่วม' ? (
              <LiaTimesSolid />
            ) : (
              ''
            )}{' '}
            <Box>{v.status_name}</Box>
          </Stack>
        </Badge>
      ),
    }))
    setInstructors(newInstructors)
  }, [instructorsNameList])

  // pagination
  const onChangePagination = (value) => {
    setPage(value.page)

    const filters = {
      skip: (value.page - 1) * limit,
      limit,
    }

    fetchInstructorNameList(id, filters)
  }

  const onChangeInstructor = (key, name, value) => {
    setForm((prev) => ({
      ...prev,
      instructors: prev?.instructors?.map((v) => {
        if (v.key === key) return { ...v, [name]: value }
        else return v
      }),
    }))
  }

  const handleSearchInstructorClick = async (full_name, key) => {
    const value = full_name;
    const request = {
      id_card_or_fullname: value,
    }
    const data = await dispatch(getMemberCOE(request))
    if (data) {
      const { member_no,full_name } = data

      setForm((prev) => ({
        ...prev,
        instructors: prev.instructors.map((v) => {
          if (v.key === key) return { ...v, member_id: member_no , full_name : full_name }
          else return v
        }),
      }))
    } else {
      setForm((prev) => ({
        ...prev,
        instructors: prev.instructors.map((v) => {
          if (v.key === key) return { ...v, member_id: null }
          else return v
        }),
      }))
    }
  }

  const addNewInstructor = () => {
      const newInstructor = {
        key: uuidv4(),
        full_name: '',
        event_hours_receiving: '',
        member_id: null,
      }
      setForm((prev) => ({
        ...prev,
        instructors: [...prev.instructors, newInstructor],
      }))
  }
  const deleteInstructor = (key) => {
      setForm((prev) => ({
        ...prev,
        instructors: prev.instructors.filter((v) => v.key !== key),
      }))
  }

  const onSubmitEdit = () => {
    setIsOpenConfirmModal(false);

    const instructors = form.instructors ? form.instructors.map((v) => ({
      full_name: v.full_name,
      event_hours_receiving: Number(v.event_hours_receiving),
      member_id: v.member_id ? Number(v.member_id) : null,
    })) : [];
    const request = {
      ...form,
      instructors: instructors.length > 0 ? instructors : null,
    }
    dispatch(editActivityCpd(id, request)).then(({ type }) => {
      //alert(JSON.stringify(type))
      if(type.endsWith('_SUCCESS')){
        dispatch(getActivityCpdBYId(id));
        fetchInstructorNameList(id);
        setsuccessModal(true)
      }else{
        setFailModal(true)
      }
    })
    toggleEditMode();

  }

  return (
    <Div>
        <Stack sx={{ mb: '20px' }}  direction="row" justifyContent="space-between" alignItems="center">
          <Box>
            <Stack direction="row" spacing={1}>
              <div className="heading-table">ข้อมูลวิทยากร</div>
              <Badge>{form?.instructors ? form?.instructors.length : 0} คน</Badge>
            </Stack>
          </Box>
          <Box>
            {isEdit ? (
              <Stack direction="row" spacing={3}>
                <ButtonOutline onClick={cancelEdit}>ยกเลิก</ButtonOutline>
                <Button className="margin_left_8" onClick={openConfirmModal}>บันทึก</Button>
                {(permission_CpdManageOrgActivity_Dowload_instructors?.canRead === true) && (
                  <ButtonOutline className="margin_left_8">Download</ButtonOutline>
                )}
              </Stack>
            ) : (
              <Stack direction="row" spacing={2}>
                {(permission_CpdManageOrgActivity_Edit_instructors?.canRead === true) && (
                  <Button onClick={openEditMode}>แก้ไขรายชื่อวิทยากร</Button>
                )}
                {(permission_CpdManageOrgActivity_Dowload_instructors?.canRead === true) && (
                  <ButtonOutline className="margin_left_8">Download</ButtonOutline>
                )}
              </Stack>
              
            )}
            
            
          </Box>

        </Stack>
        <Divider sx={{ mb: '40px' }} />
        <Stack sx={{ mb: '20px' }} direction="row">
          <Box className="left-content">
            <div className="heading-info">ข้อมูลเกี่ยวกับวิทยากร</div>
            <div className="sub-heading-info">
              กรุณาระบุข้อมูลวิทยากร วิทยากรที่เป็นสมาชิกสภาวิศวกรจะได้รับคะแนน CPD เพิ่มเติม
            </div>
          </Box>
          <Box className="right-content">
          <Card className="card-styled">
              <TableDataStyled>
                <thead>
                  <tr>
                    <th>ลำดับ</th>
                    <th>ชื่อ - นามสกุล</th>
                    <th></th>
                    <th>เลขที่สมาชิกสภาวิศวกร</th>
                    <th>จำนวนชั่วโมงที่อบรม</th>
                    <th>คะแนน CPD</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {form?.instructors?.map((v, i) => (
                    <tr key={v.key}>
                      <td>{i + 1}</td>
                      <td>
                        <TextField
                          disabled={!isEdit}
                          className="w-100"
                          value={v.full_name}
                          onChange={(event) => onChangeInstructor(v.key, 'full_name', event.target.value)}
                        />
                      </td>
                      <td>
                        {isEdit && (
                          <Button onClick={() => handleSearchInstructorClick(v.full_name, v.key)}>ตรวจสอบ</Button>
                        )}
                          
                      </td>
                      <td className="td-text_center">{v?.member_id ?? '-'}</td>
                      <td>
                        <TextField
                          disabled={!isEdit}
                          className="w-100"
                          value={v.event_hours_receiving}
                          onChange={(event) => onChangeInstructor(v.key, 'event_hours_receiving', event.target.value)}
                        />
                      </td>
                      <td className="td-text_center">{v?.cpd_point ?? '-'}</td>
                      <td>
                        {isEdit && (
                          <FiTrash2 className="icon" onClick={() => deleteInstructor(v.key)} />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TableDataStyled>
              {isEdit && (   
                <Box sx={{ mt: 3 }}>
                  <ButtonOutline className="add-btn" append={<FiPlus size={20} />} onClick={addNewInstructor}>
                    เพิ่มวิทยากร
                  </ButtonOutline>
                </Box>
              )}
            </Card>
          </Box>

        </Stack>
        {
          /*
          <TableWrapper>
            <Stack className="header-table" direction="row" justifyContent="space-between" alignItems="center">
              <Box>
                <Stack direction="row" spacing={1}>
                  <div className="heading-table">ข้อมูลวิทยากร</div>
                  <Badge>{total} คน</Badge>
                </Stack>
              </Box>

              <ButtonOutline>Download</ButtonOutline>
            </Stack>
              <Table
                data={instructors}
                columns={instructorColumns}
                page={page}
                pageCount={pageCount}
                onStateChange={onChangePagination}
              />
          </TableWrapper>
          */
        }

        <DialogConfirmV2
          open={Boolean(isOpenConfirmModal)}
          onClose={() => setIsOpenConfirmModal(false)}
          onNo={() => setIsOpenConfirmModal(false)}
          onSubmit={onSubmitEdit}
          icon={warningIcon}
          title={'ยืนยันการแก้ไขวิทยากร?'}
          nameItem={''}
          textYes={'ยืนยัน'}
          textNo={'ยกเลิก'}
        />

      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setsuccessModal(false)}
        onSubmit={() => setsuccessModal(false)}
        icon={successIcon}
        title={'บันทึกสำเร็จ'}
        subtitle={''}
        description={''}
        textYes={'ตกลง'}
      />

      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => setFailModal(false)}
        icon={failIcon}
        title={'บันทึกไม่สำเร็จ'}
        nameItem={''}
        description={''}
        textYes={'ตกลง'}
      />
    </Div>
  )
}

export default InstructorNameListPart
