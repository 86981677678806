import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {  getAdminUser,DateTimeHHmmDisplay,DateDisplay,GetPermissionOther } from '../../utils/common'
import { getOrgUserAccount } from '../../redux/actions/organizations'
import { ApiGetStatus } from '../../redux/actions/master'

import { Api_org_num_status_appeal,ApiGetVWAppeal } from '../../redux/actions/appeal'


import Button from '../../components/form/button/Button'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import TableV2 from '../../components/common/TableV2'
import SearchText from '../../components/form/SearchText'
import Dropdown from '../../components/form/Dropdown'
import Date from '../../components/form/Date'
import Field from '../../components/form/Field'
import SidePanel from '../../components/common/SidePanel'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'
import DialogConfirmV2 from '../../components/dialog/DialogConfirmV2'
import ModalAppealInfo from '../../pages/Appeal/ModalAppealInfo'
import TextField from '../../components/form/TextField'
import Checkbox from '../../components/form/Checkbox'
import PaginationV2 from '../../components/common/PaginationV2'

import iconPlus from '../../assets/images/icon-plus.svg'
import iconFileDowload from '../../assets/images/icon-file-download.svg'
import iconEdit from '../../assets/images/edit-icon.png'
import iconDelete from '../../assets/images/icon-bin-red.svg'
import iconDuplicate from '../../assets/images/Duplicate.png'
import warningIcon from '../../assets/images/warning-icon.png'
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'

import { FaBullseye } from 'react-icons/fa'
import { GrStatusGoodSmall } from 'react-icons/gr'
import { IoMdCheckboxOutline } from "react-icons/io";

const Div = styled.div`
  .box_header{
    display: flex;
    justify-content: space-between;
  }
  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Base-Font-Title-Color);
  }
  .content-title-text {
    color: var(--Base-Font-Title-SUB-Color);
    font-size: 18px;
  }
  .css_button_add{
    display: flex;
    gap: 10px;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
  }
  .status-badge {
    /*width: 150px;*/
    /*padding: 0.2rem;*/
    width: fit-content;
    padding: 2px 6px;
    text-align: center;
    font-weight: 500;
    border-radius: 16px;
    color: #475467;
    background: #f5f5f4;
    border: 1px #e7e5e4 solid;

    &.expire {
      color:#B42318;
      background: #FEF3F2;
      border: 1px #FECDCA solid;
    }
    &.active {
      color:#067647;
      background: #ECFDF3;
      border: 1px #ABEFC6 solid;
    }

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: #475467;
      

      &.expire {
        color:#B42318;
      }
      &.active {
        color:#067647;
      }

    }
  }

  .table-wrapper {
    border: 1px solid #eaecf0;
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    background-color: #ffffff;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Table-Bg-Header);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--BADGE-Table-Font);
        background: var(--BADGE-Table-Bg);
        border-radius: 16px;
        border: 1px var(--BADGE-Table-Border) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }
  .css_magin_top_10 {
    margin-top: 10px;
  }
  .css_magin_top_24 {
    margin-top: 24px;
  }
  .title_dowload{
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
  .cover_img{
    img {
      width: 74px;
      height: 40px;
    }
  }
  .cursor_pointer {
    cursor: pointer;
  }
  .text-bold {
    font-weight: bold;
  }
  
  .link {
    color: var(--Td-Font-Color-Link);
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .css_button_filter{
    display: inline-block;
    align-self: flex-end;
  }
  .group_button {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .button_approve {
    color: #ffffff;
    background-color: #17b26a;
    border: 1px solid #17b26a;
  }
`

const DivFilter = styled.div`
min-height: 68px;
background-color: var(--Gray-50);
border-radius: 8px;
margin-bottom: 21px;
padding: 10px;
.content-fillter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .search-wrapper {
        margin-right: 12px;
    }

    .filter-list {
        flex-basis: 100%;
        margin-top: 24px;
    }

    .group_filter {
        display: flex;
        align-items: center;
    }
}
.css_filter_name {
    min-width: 380px;
    margin-right: 10px;
}
.mr-1-rem {
    margin-right: 1rem;
}
.css_button_filter{
    display: inline-block;
    align-self: flex-end;
}
.dd_filter{
    min-width: 110px;
}
.dd_filterStatusExam{
  min-width: 115px;
}
`

const DivTab = styled.div`
width: 100%;
margin-bottom: 1rem;
margin-top: 1rem;
display: flex;
/*border-bottom: 1px solid var(--Tab-Border-Default);*/

.btn-menu-style {
    border: none;
    padding: 10px 18px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    background-color: var(--Tab-Bg-Default);
    /*border-bottom: 0px solid var(--Tab-Border-Default);*/
    border-bottom: 2px solid var(--Tab-Border-Default);
    color: var(--Tab-Font-Default);  
    font-size: 14px;
    /*font-family: 'Inter','Noto Sans Thai';*/
    font-family: inherit;
}

.menu-active {
    border: none;
    padding: 10px 18px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 2px solid var(--Tab-Border-Current) !important;
    color: var(--Tab-Font-Current); 
    background-color: #FFFFFF;
    /*font-family: 'Inter','Noto Sans Thai';*/
    font-family: inherit;
}
.box_sub_menu{
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .box_num_notification {
      display: inline-block;
      align-self: center;
      height: 22px;
      font-size: 12px;
      font-weight: 500;
      padding: 2px 8px;
      
      border-radius: 16px;
      color: #475467;
      background: #f5f5f4;
      border: 1px #e7e5e4 solid;

      &.active {
        color:#A81E23; 
        background: #FEF3F2;
        border: 1px #FECDCA solid;
      }

    }

  }
`

const DivTable = styled.div`
  width: 100%;
  overflow-x: auto;
  .text_css{
    min-width: 200px;
  }
  .text-bold {
    font-weight: bold;
  }
  
  .link {
    color: var(--Td-Font-Color-Link);
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: var(--Shadow-xs);

    tr {
      border: 1px solid var(--Gray-200);
    }

    th,
    td {
      padding: 16px 14px;
      text-align: left;
      font-size: 14px;
      color: var(--Gray-600);
      white-space: nowrap;
      background: var(--Base-White);
    }

    th {
      padding: 12x 14px;
      font-weight: 500;
      background: var(--Gray-50);
    }

    tr:hover {
      td {
        background: var(--Table-Bg-Hover);
        color: var(--Table-Font-Hover);
      }
    }
  }

  .content-wrapper {
    text-align: center;
  }

  .pagination {
    margin: 30px 24px;
  }

  .cursor_pointer {
    cursor: pointer;
  }
  .cancel-activity {
    color: var(--COE-Main-CI);
    font-weight: 700;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`


const Appeal = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = getAdminUser()

  const permission_AppealAppeal_View = GetPermissionOther('AppealAppeal','AppealAppeal_View');

  const [ElearningId, setElearningId] = useState(null)
  const [ElearningStatusId, setElearningStatusId] = useState(null)
  const [isOpenData, setisOpenData] = useState(false)
  const [DataInfo, setDataInfo] = useState(null)
  const [filterName, setfilterName] = useState('')
  const [filterUser, setfilterUser] = useState('')
  const [filterDate, setfilterDate] = useState(null)
  const [filterStatus, setfilterStatus] = useState('1')
  const [filterStatusExam, setfilterStatusExam] = useState('')
  const [filterOrgName, setfilterOrgName] = useState('')

  const [user_option, setuser_option] = useState([])
  /*
  useEffect(() => {
    const filter = {
      skip: 0,
      limit: 1000,
      status_id : 1,
    }
    dispatch(getOrgUserAccount(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const neworg_type_option= data_info?.data?.map((v) => ({
                text: v?.full_name,
                value: v?.user_id,
            }))
            setuser_option([...neworg_type_option])
        }
    })
  }, [dispatch])
  */

  const [ExamOption, setExamOption] = useState([
    {
      text : 'มี',
      value: 1
    },
    {
      text : 'ไม่มี',
      value: 2
    },
  ])

  const [StatusOption, setStatusOption] = useState([])
  useEffect(() => {
    const filter = {
      skip: 0,
      limit: 1000,
      category_name : 'organizations.appeal',
      is_active : true,
    }
    dispatch(ApiGetStatus(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const newStatusOption=  data_info?.data?.map((v) => ({
              text: v?.name_th_th,
              value: v?.id
            }))
            setStatusOption([...newStatusOption])
        }
    })
  }, [dispatch])


  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
  const [ModalConfirmDialogDel, setModalConfirmDialogDel] = useState(false)
  const [ConfirmMessageModal, setConfirmMessageModal] = useState(initMessageModal)

  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)

  const [tabactive, setTabactive] = useState('Wait')

  const limit_data = 10;
  const [total, settotal] = useState(0)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [TableCellData, setTableCellData] = useState([])

  const [isSelectAll, setIsSelectAll] = useState(false)
  const [ListCheckApprove, setListCheckApprove] = useState([])

  const onChangeSelectAllCheckBox = () => {
    if(isSelectAll){
        setIsSelectAll(false)
        setListCheckApprove([])
    }else{
        setIsSelectAll(true)
        const filter = {
            skip: 0,
            limit: 10000,
            status_id:  tabactive == 'All' ? '' : filterStatus,
        }
        dispatch(ApiGetVWAppeal(filter)).then(({ type,data_info }) => {
            if(type.endsWith('_SUCCESS')){
                if(data_info.total > 0){
                    const data_check_box = data_info.data.map((v) => (v.id))
                    setListCheckApprove(data_check_box)
                }
            }
        })
    }
  }

  const [TableColumnData, setTableColumnData] = useState([
    {
      Header: 'ลำดับ',
      accessor: 'no',
      disableSortBy: false,
    },
    {
        Header: 'เลขที่สมาชิก',
        accessor: 'user_no',
        disableSortBy: false,
    },
    {
        Header: 'ชื่อ นามสกุล',
        accessor: 'appeal_full_name',
        disableSortBy: false,
    },
    {
        Header: 'รหัสกิจกรรม',
        accessor: 'event_code',
        disableSortBy: false,
    },
    {
        Header: 'ชื่อกิจกรรม',
        accessor: 'activity_name',
        disableSortBy: false,
    },
    {
        Header: 'ประเภทคำร้อง',
        accessor: 'petition_type_name',
        disableSortBy: false,
    },
    {
        Header: 'วันที่ยื่นคำร้อง',
        accessor: 'createdAt',
        disableSortBy: false,
    },
    {
        Header: 'คะแนน CPD',
        accessor: 'point',
        disableSortBy: false,
    },
    {
        Header: 'สถานะ',
        accessor: 'status_name',
        disableSortBy: false,
    },
  ])

  useEffect(() => {
    dispatch(Api_org_num_status_appeal()).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info.data_info){
          setDataInfo(data_info.data_info);
          setisOpenData(true)
        }
        
      }
    })
  }, [dispatch])

  
  //const [AttendanceCellData ,setAttendanceCellData] = useState([])

  const fetchData = useCallback(
    async (filter) => {

      dispatch(ApiGetVWAppeal(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          if (data_info.total > 0) {
            const totalPage = Math.ceil(data_info.total / limit_data)
            settotal(data_info.total)
            setTotalPages(totalPage)
            setTableCellData(data_info.data)
          }else{
            settotal(0);
            setPage(0);
            setTotalPages(0);
            setTableCellData([]);
          }
         
        }
      })

    },
    [dispatch]
  )

  const handleChangeCheckBoxApprove = (e,v_inp,index) => {
    //alert(v_inp.id)
    if(ListCheckApprove.filter((v) => v == v_inp.id).length > 0){
      setListCheckApprove(ListCheckApprove.filter((v) => v !== v_inp.id))
    }else{
      setListCheckApprove(current => [...current, v_inp.id]); 
    }        
  }

  useEffect(() => {
    const filter = {
      skip: 0,
      limit: limit_data,
      status_id : filterStatus,
    }
    fetchData(filter) //-- ปิด รอ API
  }, [fetchData, limit_data])


  const onChangePaginationAttendeeAttend = async (value) => {
    const filter = {
      skip: (value.page  - 1) * limit_data, 
      limit: limit_data,
      status_id : filterStatus,
      name : filterName,
      user : filterUser,
      date : filterDate,
      status_exam : filterStatusExam,
      organizations_name : filterOrgName,
    }
    fetchData(filter)
    setPage(value.page)
  }

  const onSearch = () => {
    const filter = {
      skip: 0,
      limit: limit_data,
      status_id : filterStatus,
      name : filterName,
      user : filterUser,
      date : filterDate,
      status_exam : filterStatusExam,
      organizations_name : filterOrgName,
    }
    fetchData(filter)
    setPage(1)
  }
  const onClearSearch = () => {
    setModalPreview(false);
    setfilterName('')
    setfilterOrgName('')
    setfilterUser('')
    setfilterDate(null)
    setfilterStatusExam('')
    setIsSelectAll(false)
    setListCheckApprove([])
    if(tabactive == 'All'){
      setfilterStatus('')
    }
    const filter = {
      skip: 0,
      limit: limit_data,
      status_id:  tabactive == 'All' ? '' : filterStatus,
    }
    fetchData(filter)
    
    setPage(1)
  }

  const onClearfilterName = () => {
    setfilterName('');
  }
  const onClearfilterOrgName = () => {
    setfilterOrgName('');
  }
  

  const OpenCreateModal = () => {
    //navigate('/Activity/NewElearning')
    alert('OpenCreateModal');
  }
  const DowloadReport = () => {
    alert('DowloadReport')
  }

  const DelData = (data) => {
    setElearningId(data.id)
    setElearningStatusId(0);
    setConfirmMessageModal({
        headline: 'กรุณายืนยันการลบบทความนี้',
        message: '',
    })
    setModalConfirmDialogDel(true);
  }

  const CancleData = (data) => {
    setElearningId(data.id)
    setElearningStatusId(6);
    setConfirmMessageModal({
        headline: 'กรุณายืนยันการยกเลิกบทความนี้',
        message: '',
    })
    setModalConfirmDialogDel(true);
  }

  const ReopenData = (data) => {
    setElearningId(data.id)
    setElearningStatusId(3);
    setConfirmMessageModal({
        headline: 'กรุณายืนยันการเปิดอีกครั้ง',
        message: '',
    })
    setModalConfirmDialogDel(true);
  }

  const [WidthSidePanel, setWidthSidePanel] = useState(400)
  const [ModalPreview, setModalPreview] = useState(false)
  const [Id, setId] = useState(null)

  const PreviewData = (data) => {
    setId(data.id)
    setModalPreview(true);
  }
  const EditData = (data) => {
    navigate('/Activity/ElearningInfo/' + data.id)
  }

  const [num_success, setnum_success] = useState(0)
  const [num_error, setnum_error] = useState(0)
  const handleProcess = (type_status_id) => {
    
    alert('handleProcess ->' + type_status_id)
  }

  
  const [menuTabList, setMenuTabList] = useState([
    {
        name: 'รอตรวจสอบ',
        status_id : '1',
        value: 'Wait',
        active_status: true,
    },
    {
        name: 'อนุมัติ',
        status_id : '2',
        value: 'Approve',
        active_status: false,
    },
    {
        name: 'ไม่อนุมัติ',
        status_id : '3',
        value: 'NotApprove',
        active_status: false,
    },
    {
        name: 'ทั้งหมด',
        status_id : '',
        value: 'All',
        active_status: false,
    },
  ])
  const handleClickTaskMenuTab = (data) => {
    let tmpList = [...menuTabList]
    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i].value == data.value) {
        tmpList[i].active_status = true
        setTabactive(tmpList[i].value)
        //---- QUERY
        setfilterStatus(tmpList[i].status_id)
        setfilterName('')
        setfilterOrgName('')
        setfilterUser('')
        setfilterDate(null)
        const filter = {
          skip: 0,
          limit: limit_data,
          status_id: tmpList[i].status_id,
        }
        fetchData(filter)
        setPage(1)

      } else {
        tmpList[i].active_status = false
      }
    }
    setMenuTabList([...tmpList])
  }

  const [ModalConfirmDialogApprove, setModalConfirmDialogApprove] = useState(false)
  const ConfirmApprove = () => {
    if(ListCheckApprove.length > 0){
      setConfirmMessageModal({
          headline: 'กรุณายืนยันการอนุมัติ',
          message: 'จำนวน ' + ListCheckApprove.length + ' รายการ',
      })
      setModalConfirmDialogApprove(true);
    }else{
      setFailMessageModal({
        headline: 'กรุณาเลือกบทความที่ต้องการอนุมัติ',
        message: '',
      })
      setFailModal(true)
    }



  }
  

  return (
    <Div>
      <header>
        <div className="box_header">
            <div>
                <div className="content-title">
                    คำร้องอุทธรณ์
                </div>
                <div className="content-title-text">
                    สำหรับเจ้าหน้าทตรวจสอบคำร้องอุทธรณ์ของสมาชิก
                </div>
            </div>
        </div>
        
        {/* Tab */}
        <div className="css_magin_top_24"></div>
        {isOpenData == true && (
          <DivTab>
              {menuTabList?.map((item, index) => (
                  <button
                  id={'this_name-' + item.value}
                  key={'btn-' + index}
                  className={item.active_status ? 'menu-active' : 'btn-menu-style'}
                  onClick={() => handleClickTaskMenuTab(item)}
                  >
                      <div className="box_sub_menu">
                          <div>
                              {item.name}
                          </div>
                          <div className={`box_num_notification ${item.active_status ? 'active' : ''}`}>
                              {DataInfo[item.value]}
                          </div>
                      </div>
                  
                  </button>
              ))}
          </DivTab>
        )}
        {/* ค้นหา */}
        <div className="css_magin_top_24"></div>
        <DivFilter>
          <div className="content-fillter">
            <div className="group_filter">
              <div className="css_filter_name">
                <Field className="field" label="ค้นหาบทความ">
                  <SearchText
                    placeholder="Search รหัสกิจกรรม ชื่อกิจกรรม หรือเลขที่สมาชิก"
                    value={filterName}
                    onChange={(e) => setfilterName(e.target.value)}
                    onClear={onClearfilterName}
                  />
                </Field>
              </div>
              {tabactive == 'All' && (
                <div className="mr-1-rem">
                  <Field className="field" label="สถานะ">
                    <Dropdown
                        id={`dd_filter`}
                        className="dd_filter"
                        value={filterStatus}
                        optionList={StatusOption}
                        onChange={(v) => setfilterStatus(v)}
                        placeHolder={'เลือก'}
                    />
                  </Field>
                </div>
              )}
              <div className="mr-1-rem">
                <Field className="field" label="วันที่สร้าง">
                    <Date
                        className={'input-calendar'}
                        value={filterDate}
                        onChange={(v) => setfilterDate(v)}
                    />
                </Field>
              </div>
              <div className="css_button_filter mr-1-rem">
                  <Button onClick={onSearch} >
                    ค้นหา
                  </Button>
              </div>
              <div className="css_button_filter mr-1-rem">
                <ButtonSecondary onClick={onClearSearch} >
                  ล้างการค้นหา
                </ButtonSecondary>
              </div>
            </div>
          </div>
        </DivFilter>

        {/* Table */}
        <div className="css_magin_top_24"></div>
        <div className="table-wrapper">
            <div className="table-header">
                <div className="group">
                  <div className="table-title">รายการคำร้อง</div>
                  <div className="table-total">
                      {total} รายการ
                  </div>
                </div>              
            </div>


          <div className="css_magin_top_10">
            <DivTable>
              <table >
                  <thead>
                      <tr >
                          {TableColumnData.map((column) => (
                              <th
                                  key={column.accessor}
                                  className={`col-${column.accessor}`}
                              >
                                  {column.Header == 'checkbox' && (
                                      <Checkbox checked={isSelectAll} onChange={onChangeSelectAllCheckBox}  />
                                  )}
                                  {column.Header != 'checkbox' && (
                                      <div>{column.Header ? column.Header : ''}</div>                                           
                                  )}                                        
                              </th>
                          ))}
                      </tr>
                      {TableCellData?.map((v, index) => (
                          <tr key={index}>
                              <td >
                                    {limit_data * page - (9 - index)}
                              </td>
                              <td >
                                {(permission_AppealAppeal_View?.canRead == true) ? (
                                  <div className="text-bold link" onClick={() => PreviewData(v)}>
                                    {v.user_no}
                                  </div>
                                ) : (
                                  <div className="text-bold">
                                    {v.user_no}
                                  </div>
                                )
                                }  
                              </td>
                              <td >
                                  {v.appeal_full_name ? v.appeal_full_name : '-'}
                              </td>
                              <td >
                                  {v.event_code ? v.event_code : '-'}
                              </td>
                              <td >
                                  {v.activity_name ? v.activity_name : '-'}
                              </td> 
                              <td >
                                  {v.petition_type_name ? v.petition_type_name : '-'}
                              </td>
                              <td >
                                  {v.createdAt ? DateDisplay(v.createdAt) : '-'}
                              </td>
                              <td >
                                  {v.point}
                              </td> 
                              <td >
                                <div
                                  className="status-badge"
                                  style={{
                                    
                                    borderColor: v.status_badge_color?.border,
                                    backgroundColor: v.status_badge_color?.background,
                                  }}
                                >
                                  <GrStatusGoodSmall 
                                    className="status"
                                    style={{
                                      color: v.status_badge_color?.color,
                                    }}
                                  />
                                  {v.status_name}
                                </div>
                              </td>
                          </tr>
                      ))}
                  </thead>
              </table>
              <PaginationV2
                  className="pagination"
                  pageCount={totalPages}
                  page={page}
                  onPageChange={(page) => onChangePaginationAttendeeAttend({ page })}
              />
            </DivTable>
            
          </div>
        </div>


      </header>

      <DialogConfirmV2
        open={Boolean(ModalConfirmDialogDel)}
        onClose={() => setModalConfirmDialogDel(false)}
        onNo={() => setModalConfirmDialogDel(false)}
        onSubmit={() => {
          setModalConfirmDialogDel(false)
          handleProcess(0)
        }}
        icon={warningIcon}
        title={ConfirmMessageModal.headline}
        nameItem={ConfirmMessageModal.message}
        textYes='ยืนยัน'
        textNo='ยกเลิก'
      />

      <DialogConfirmV2
        open={Boolean(ModalConfirmDialogApprove)}
        onClose={() => setModalConfirmDialogApprove(false)}
        onNo={() => setModalConfirmDialogApprove(false)}
        onSubmit={() => {
          setModalConfirmDialogApprove(false)
          handleProcess(3)
        }}
        icon={warningIcon}
        title={ConfirmMessageModal.headline}
        nameItem={ConfirmMessageModal.message}
        textYes='ยืนยัน'
        textNo='ยกเลิก'
      />

      {/* Dialog */}
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={() => {
          setSuccessModal(false)
          onClearSearch()
        }}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes='ตกลง'
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => {
          setFailModal(false)
          onClearSearch()
        }}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes='ตกลง'
      />

      <SidePanel isOpen={ModalPreview} setIsOpen={setModalPreview} width={WidthSidePanel}>
          <ModalAppealInfo 
            width={WidthSidePanel} 
            onClose={() => setModalPreview(false)} 
            onSubmit={() => onClearSearch()}
            ThisId={Id}
          />
      </SidePanel>
    </Div>
  )
}

export default Appeal
