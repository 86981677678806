import React from 'react'

import { styled } from '@mui/system'
import { Box, Stepper, Step, StepLabel, StepConnector } from '@mui/material'
import { stepConnectorClasses } from '@mui/material/StepConnector'

import { FaDotCircle, FaRegDotCircle } from 'react-icons/fa'

// style connector line
const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-51% + 16px)',
    right: 'calc(49% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'var(--Gray-200)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'var(--COE-Main-CI)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: 'var(--Gray-200)',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))

// style step
const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: 'var(--Gray-200)',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: 'var(--COE-Main-CI)',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: 'var(--COE-Main-CI)',
    zIndex: 1,
  },
  '& .QontoStepIcon-circle': {
    color: 'var(--Gray-200)',
  },
}))

function QontoStepIcon(props) {
  const { active, completed, className } = props

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <FaDotCircle size={25} className="QontoStepIcon-completedIcon" />
      ) : (
        <FaRegDotCircle size={25} className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  )
}

const CustomStepLabel = styled(StepLabel)(({ theme, ownerState }) => ({
  '& .MuiStepLabel-label': {
    color: 'var(--Gray-700)',
    fontWeight: '600',
    // Active state
    ...(ownerState.active && {
      color: 'var(--Gray-700)',
    }),
    // Completed state
    ...(ownerState.completed && {
      color: 'var(--COE-Main-CI)',
    }),
  },
}))

function Steppers({ steps, activeStep }) {
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
        {steps.map((step, index) => (
          <Step key={step.step}>
            <CustomStepLabel
              StepIconComponent={QontoStepIcon}
              ownerState={{ active: index === activeStep, completed: index < activeStep }}
            >
              {step.text}
            </CustomStepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}

export default Steppers
