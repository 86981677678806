import React from 'react'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components'

import logoIcon from '../assets/images/logo-icon.png'

const Div = styled.div`
  margin-bottom: 50px;
  margin-top: 60px;
  padding: 10px;
  display: flex;
  justify-content: center;

  /*width: 512px;*/

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  color : #000000;
  text-align: center;

  .img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    
    img {
      width: 220px;
      /*height: 48px;*/
    }
    
  }
  .topic{
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    color : #A91F23;
    text-align: center; 
  }
  .css_margin_top_24{
    margin-top: 24px;
  }
`

function Maintenance() {
  const navigate = useNavigate()

  return (
    <Div>
        <div>
            <div className="img-container">
            <img src={logoIcon} alt="loco icon" />
            </div>
            <div className="topic"> 
                แจ้งปิดปรับปรุงระบบ
            </div>
            <div className="css_margin_top_24"></div>
            <div>ระบบพัฒนาวิชาชีพวิศวกรต่อเนื่อง CPD</div>
            <div>ระหว่างวันที่ 4 สิงหาคม 2567 เวลา 00.01 น.</div>
            <div>เปิดให้บริการอีกครั้งวันที่ 5 สิงหาคม 2567 เวลา 08.00 น.</div>
            <div>ทั้งนี้ระบบอื่นๆ ยังเปิดให้บริการตามปกติ</div>
            <div>ขออภัยในความไม่สะดวก มา ณ ที่นี้</div>
        </div>

    </Div>
  )
}

export default Maintenance
