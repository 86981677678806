import React from 'react'

import Switch from '@mui/joy/Switch'

function Switchs({ checked, textIsChecked = 'Yes', textIsNotChecked = 'No', onChangeSwitch }) {
  const handleChange = (event) => {
    onChangeSwitch(event)
  }
  return (
    <Switch
      checked={checked}
      endDecorator={checked ? textIsChecked : textIsNotChecked}
      onChange={handleChange}
      sx={{
        '& .MuiSwitch-thumb': {
          bgcolor: 'var(--Base-White)', // Default thumb color
        },
        '& .MuiSwitch-track': {
          bgcolor: 'var(--Gray-200)', // Track color when switch is off
        },
        '&.Mui-checked .MuiSwitch-track': {
          bgcolor: 'var(--COE-Main-CI)', // Track color when switch is on
        },
        '&.Mui-checked': {
          '& .MuiSwitch-thumb': {
            bgcolor: 'var(--Base-White)', // Color thumb when is on
          },
        },
      }}
    />
  )
}

export default Switchs
