import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationERP from './assets/erp_lang.json'

//import { csvToJson } from './utils/common'
//import langConfig from './assets/erp_lang.csv'
//import { getAPI } from './utils/api'
//import { translationEN } from '../public/locales/translation.json';

async function initI18n() {
  try {
    //---- VERSION Getlang จาก JSON File เปลี่ยนมาใช้จาก File JSON By Jame 05/10/2023
    const resources = translationERP;
    i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng: "th",

      keySeparator: false, // we do not use keys in form messages.welcome

      interpolation: {
        escapeValue: false // react already safes from xss
      }
    });
    //----- VERSION เก่าที่ดึงจาก CSV
    /*
    const response = await fetch(langConfig)
  
    if (!response.ok) {
      throw new Error('Failed to fetch the CSV file')
    }

    const csvContent = await response.text()
    const resources = csvToJson(csvContent)

    i18n.use(initReactI18next).init({
      resources,
      lng: 'en', // default language
      interpolation: {
        escapeValue: false,
      },
    })
    */
    //---- VERSION Getlang จาก API ByJame ไม่ใช่ 05/10/2023 เพราะหากมีคนเข้าใช้งาน เยอะๆ backend จะไม่ไหว
    /*
    try {
      
      const result = await getAPI({
        url: '/api/erp-langs',
        headers: {
          'Content-Type': 'application/json',
          Authorization: null,
        }
      })
      
      if(result.meta.pagination.total > 0){
        const resources_backend = result.data[0];
        //
        DatainitI18n(langConfigJson);
      }else{ //---- หากไม่มีข้อมูลจะอ่าน จาก CSV ที่อยู่ใน frontend
        const response = await fetch(langConfig)
  
        if (!response.ok) {
          throw new Error('Failed to fetch the CSV file')
        }
    
        const csvContent = await response.text()
        const resources = csvToJson(csvContent)
        DatainitI18n(resources);
      } 
      
      
    } catch (error) {
      const response = await fetch(langConfig)
  
      if (!response.ok) {
        throw new Error('Failed to fetch the CSV file')
      }
    
      const csvContent = await response.text()
      const resources = csvToJson(csvContent)
      DatainitI18n(resources);
    }
    */

    
  } catch (error) {
    console.error('Initialization failed:', error.message)
  }
}

async function DatainitI18n(resources) {
  try {
    
    i18n.use(initReactI18next).init({
      resources,
      lng: 'en', // default language
      interpolation: {
        escapeValue: false,
      },
    })
  } catch (error) {
    
    console.error('Data Initialization failed:', error.message);
  }
}

initI18n()

export default i18n
