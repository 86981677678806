import { getAPI, postAPI,patchAPI } from '../../utils/api'
import { 
    organization_profile,
    GET_organization,
    UPDATE_organization,
    GET_ApiCPDNumNotification
} from '../actionTypes'
import { showLoading, hideLoading } from './loading'

export const ApiGetOrgList = (filter = { limit: 10, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_organization.REQUEST })

    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
    }

    if(filter.name){
      params = {...params,'$or[0][name_th][$like]': `%${filter.name}%`,'$or[1][tin][$like]': `%${filter.name}%`}
    }
    if(filter.org_type){
      params = {...params,'org_type': filter.org_type}
    }
    if(filter.type_org){
      params = {...params,'type_org': filter.type_org}
    }
    if(filter.tab_active){
      if(filter.tab_active != 'all'){
        params = {...params,'applications_status': filter.tab_active}
      }

    }

    const data_result = await getAPI({
      url: '/api/cms/vw_cms_organization?$sort[id]=-1',
      headers: headers,
      params,
    })

    return { type: 'ApiGetOrgList_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetOrgList_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGetOrgProfileCMS = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: organization_profile.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/cms/organization_profile_cms`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiGetOrgProfileCMS_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetOrgProfileCMS_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiUpdateOrg = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_organization.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    await patchAPI({
      url: `/api/cms/organization_profile_cms/${id}`,
      data,
      headers: headers,
    })

    return { type: 'ApiUpdateOrg_SUCCESS', data_info: { code : 200 } }

  } catch (err) {

    return { type: 'GApiUpdateOrg_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const Apigenerate_cpd_pdf = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: organization_profile.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/cms/generate_cpd_pdf`,
      data,
      headers: headers,
    })
    
    return { type: 'Apigenerate_cpd_pdf_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'Apigenerate_cpd_pdf_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const getOrgUserAccount = (filter = { limit: 10, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_organization.REQUEST })

    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
      'organizations_id': filter.organizations_id,
    }

    if(filter.name){
      params = {...params,'full_name': filter.name}
    }
    if(filter.status_id){
      params = {...params,'status_id': filter.status_id}
    }

    const data_result = await getAPI({
      url: '/api/cms/OrgUserAccount?$sort[id]=1',
      headers: headers,
      params,
    })

    return { type: 'getOrgUserAccount_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'getOrgUserAccount_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiApproveRequest = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: organization_profile.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/cms/CPDApproveRequest`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiApproveRequest_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiApproveRequest_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiEditUserAccount = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: organization_profile.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/cms/CMSEditUser`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiEditUserAccount_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiEditUserAccount_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiNewUserAccount = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: organization_profile.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/cms/CMSNewUser`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiNewUserAccount_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiNewUserAccount_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiCPDNumNotification = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_ApiCPDNumNotification.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/cms/CPDNumNotification`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiCPDNumNotification_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiCPDNumNotification_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGetcms_vw_user_authen = (filter = { limit: 10, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_organization.REQUEST })

    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
    }

    if(filter.name){
      params = {...params,'$or[0][full_name][$like]': `%${filter.name}%`,'$or[1][email][$like]': `%${filter.name}%`,'$or[2][user_name][$like]': `%${filter.name}%`,'$or[3][phone_no][$like]': `%${filter.name}%`}
    }
    if(filter.status_id){
      params = {...params,'status_id': filter.status_id}
    }

    const data_result = await getAPI({
      url: '/api/cms/cms_vw_user_authen?$sort[id]=-1&organizations_name[$ne]=-',
      headers: headers,
      params,
    })

    return { type: 'ApiGetcms_vw_user_authen_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetcms_vw_user_authen_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGetRequestNewOrg = (filter = { limit: 10, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_organization.REQUEST })

    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
    }
    if(filter.applications_status){
      params = {...params,'applications_status': filter.applications_status}
    }

    if(filter.name){
      params = {...params,'$or[0][name_th][$like]': `%${filter.name}%`,'$or[1][tin][$like]': `%${filter.name}%`}
    }
    
    if(filter.org_type){
      params = {...params,'org_type': filter.org_type}
    }

    if(filter.status){
      params = {...params,'applications_status': filter.status}
    }

    if(filter.submitted_at){
      params = {...params,'submitted_at': filter.submitted_at}
    }

    const data_result = await getAPI({
      url: '/api/org/CMSRequestNewOrg?$sort[id]=-1',
      headers: headers,
      params,
    })

    return { type: 'ApiGetRequestNewOrg_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetRequestNewOrg_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiCMSApplicationsProfile = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: organization_profile.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/org/CMSApplicationsProfile`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiCMSApplicationsProfile_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiCMSApplicationsProfile_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGetApplicationDocument = (filter = { limit: 10, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_organization.REQUEST })

    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
    }
    if(filter.application_id){
      params = {...params,'application_id': filter.application_id}
    }

    const data_result = await getAPI({
      url: '/api/org/CMSApplicationDocument?$sort[id]=-1',
      headers: headers,
      params,
    })

    return { type: 'ApiGetApplicationDocument_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetApplicationDocument_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiUpdateApplications = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_organization.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    await patchAPI({
      url: `/api/org/CMSApplications/${id}`,
      data,
      headers: headers,
    })

    return { type: 'ApiUpdateApplications_SUCCESS', data_info: { code : 200 } }

  } catch (err) {

    return { type: 'ApiUpdateApplications_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGetRequestExtendOrg = (filter = { limit: 10, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_organization.REQUEST })

    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
    }

    if(filter.name){
      params = {...params,'$or[0][name_th][$like]': `%${filter.name}%`,'$or[1][tin][$like]': `%${filter.name}%`}
    }
    
    if(filter.org_type){
      params = {...params,'org_type': filter.org_type}
    }

    if(filter.status){
      params = {...params,'applications_status': filter.status}
    }

    if(filter.submitted_at){
      params = {...params,'submitted_at': filter.submitted_at}
    }

    if(filter.not_approve){
      params = {...params,'applications_status[$ne]': 300}
    }

    const data_result = await getAPI({
      url: '/api/org/CMSRequestExtendOrg?$sort[id]=-1',
      headers: headers,
      params,
    })

    return { type: 'ApiGetRequestExtendOrg_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetRequestExtendOrg_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGetReportCommittee = (filter = { limit: 10, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_organization.REQUEST })

    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
    }

    if(filter.name){
      params = {...params,'$or[0][name_th][$like]': `%${filter.name}%`,'$or[1][tin][$like]': `%${filter.name}%`}
    }
    
    if(filter.category_type){
      params = {...params,'type': filter.category_type}
    }

    if(filter.is_type_extend){
      params = {...params,'is_type_extend': filter.is_type_extend}
    }

    if(filter.status_assessment_results){
      params = {...params,'record_consideration_name': filter.status_assessment_results}
    }

    if(filter.type_date && filter.start_date && filter.end_date){
      if(filter.type_date == 'date_subcommittee'){
        params = {...params,'date_subcommittee[$gte]': `${filter.start_date}` ,  'date_subcommittee[$lte]' : `${filter.end_date}`}
      }else if(filter.type_date == 'date_committee_approve'){
        params = {...params,'date_committee_approve[$gte]': `${filter.start_date}` ,  'date_committee_approve[$lte]' : `${filter.end_date}`}
      }
      
    }

    const data_result = await getAPI({
      url: '/api/org/CMSReportCommittee?$sort[id]=-1',
      headers: headers,
      params,
    })

    return { type: 'ApiGetReportCommittee_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetReportCommittee_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGetOrgActivity = (filter = { limit: 10, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_organization.REQUEST })

    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
    }
    if(filter.application_id){
      params = {...params,'application_id': filter.application_id}
    }

    const data_result = await getAPI({
      url: '/api/org/CMSGetOrgActivity?$sort[id]=1',
      headers: headers,
      params,
    })

    return { type: 'ApiGetOrgActivity_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetOrgActivity_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const Apigenerate_report_pdf_org = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: organization_profile.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/cms/generate_report_pdf_org`,
      data,
      headers: headers,
    })
    
    return { type: 'Apigenerate_report_pdf_org_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'Apigenerate_report_pdf_org_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}









  