import styled from 'styled-components'
import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { ApiGET_SERVICE_TYPE,Api_Post_service_condition_cpd } from '../../redux/actions/master'

import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'
import DialogConfirmV2 from '../../components/dialog/DialogConfirmV2'
import TextField from '../../components/form/TextField'
import Dropdown from '../../components/form/Dropdown'
import Field from '../../components/form/Field'
import InputTextArea from '../../components/widget/element/InputTextArea'

import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


const Div = styled.div`
font-size: 14px;
  .css_header_detail {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    .date_css{
      color: #7F56D9;
      font-weight: bold;
    }
  }
  .content_header {
    padding: 14px 14px;
    
    width : ${(props) => props.width}px;
    position: fixed;
    background: #FFFFFF;
    top: 0;
    right: 0;
    z-index: 1;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .dialog-content {
    padding: 0px 14px;
    padding-top: 90px;
    padding-bottom: 106px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .icon {
    cursor: pointer;
  }
  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .dialog-footer {
    /*margin-bottom: 15px;*/
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    width : ${(props) => props.width}px;
    background-color : #FFFFFF;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }

  .content{
    margin:10px 0px;    
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }
`

const NewConditionCPD = ({ onClose, onSubmit,...props  }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [DataInfo, setDataInfo] = useState({
    service_type_id: null,
    year: null,
    score_criteria: null,
    num_year: null,
  })

  const [SERVICE_TYPEOption, setSERVICE_TYPE] = useState([])
  const [year_option, setyear_option] = useState([])
  useEffect(() => {
    const filter = {
      skip: 0,
      limit: 1000,
    }
    dispatch(ApiGET_SERVICE_TYPE(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const newStatusOption=  data_info?.data?.map((v) => ({
              text: v?.name_th_th,
              value: v?.id
            }))
            setSERVICE_TYPE([...newStatusOption])
             //---- year
            let STYears = 0;
            let years = [];
            let currentYear = new Date().getFullYear();

            for (STYears; STYears < 3; STYears++) {
                let year = currentYear++;
                years.push({ value: year , text :  year + 543 });
            }
            setyear_option(years);
        }
    })
  }, [dispatch])

  //---- Modal Success And Fail
  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)
  const [ConfirmMessageModal, setConfirmMessageModal] = useState(initMessageModal)

  const ConfirmDialog = () => {
    setConfirmMessageModal({
        headline : 'ยืนยันการทำรายการหรือไม่',
        message : '',
    })
    setModalConfirmDialog(true)
  }
  const ProcessApi = () => {
    setModalConfirmDialog(false);

    
    const sent_data_api = DataInfo;
    dispatch(Api_Post_service_condition_cpd(sent_data_api)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        setSuccessMessageModal({
            headline : 'ทำรายการสำเร็จ',
            message : '',
        })
        setSuccessModal(true)
      }else{
        setFailMessageModal({
            headline : 'ทำรายการไม่สำเร็จ',
            message : '',
        })
        setFailModal(true)
      }     
    })
    
    //onSubmit()
  }
  return (
    <Div {...props}>
      <div className="content_header">
        <header>
          <div className="content-title">
            สร้างหลักเกณฑ์กิจกรรม CPD
          </div>
          <div className="wrp-tool-right">
            <CloseOutlinedIcon size={30} onClick={onClose} className="icon" />
          </div>
        </header>
        <div className="css_header_detail">สร้างเงื่อนไขกิจกรรม CPD ใหม่</div>
        <hr />
      </div>
      
      <div className="dialog-content">
            <div>
                <div className="content">
                    <Field className="field" label="ประเภทบริการ">
                        <Dropdown
                            id={`dd_dropdrow`}
                            value={DataInfo.service_type_id}
                            optionList={SERVICE_TYPEOption}
                            onChange={(v) => setDataInfo({ ...DataInfo, service_type_id: v })}
                            placeHolder={'เลือกประเภทบริการ'}
                            ValueplaceHolderIsNull={true}
                        />
                    </Field>
                </div>
                <div className="content">
                    <Field className="field" label="ประจำปี">
                        <Dropdown
                            id={`dd_dropdrow`}
                            value={DataInfo.year}
                            optionList={year_option}
                            onChange={(v) => setDataInfo({ ...DataInfo, year: v })}
                            placeHolder={'เลือกประจำปี'}
                            ValueplaceHolderIsNull={true}
                        />
                    </Field>
                </div>
                <div className="content">
                    <Field className="field" label="เงื่อนไขคะแนน">
                        <TextField
                            name="score_criteria"
                            placeholder={'ระบุ'}
                            value={DataInfo.score_criteria}
                            onChange={(e) => setDataInfo({ ...DataInfo, score_criteria: e.target.value })}
                        />
                    </Field>
                    <div>{'สมาชิกต้องได้คะแนน >= เพื่อทำรายการต่อได้ '}</div>
                </div>
                <div className="content">
                    <Field className="field" label="จำนวนอายุ (ปี) ของคะแนน">
                        <TextField
                            name="num_year"
                            placeholder={'ระบุ'}
                            value={DataInfo.num_year}
                            onChange={(e) => setDataInfo({ ...DataInfo, num_year: e.target.value })}
                        />
                    </Field>
                    <div>{'นับจากวันที่ได้รับคะแนน'}</div>
                </div>
            </div>
      </div>
      
      <div className="dialog-footer">
        <hr />
        <ButtonSecondary className="button_margin" onClick={onClose}>
          {'ยกเลิก'}
        </ButtonSecondary>
        <Button className="button_margin" onClick={ConfirmDialog}>
          {'สร้างรายการ'}
        </Button>
      </div>

      {/* Dialog */}
        <DialogConfirmV2
          open={Boolean(ModalConfirmDialog)}
          onClose={() => setModalConfirmDialog(false)}
          onNo={() => setModalConfirmDialog(false)}
          onSubmit={ProcessApi}
          icon={successIcon}
          title={ConfirmMessageModal.headline}
          nameItem={ConfirmMessageModal.message}
          textYes={'ยืนยัน'}
          textNo={'ยกเลิก'}
        />
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={() => onSubmit()}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes='ตกลง'
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => {
          setFailModal(false)
        }}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes='ตกลง'
      />
    </Div>
  )
}

export default NewConditionCPD
