import styled from 'styled-components'
import FormControlLabel from '@mui/material/FormControlLabel'
import MatCheckbox from '@mui/material/Checkbox'

const Container = styled.div`
  width: 100%;

  .MuiFormControlLabel-root {
    align-items: flex-start;
    margin-left: 0;

    .MuiCheckbox-colorPrimary {
      padding: 0;
      margin-right: 8px;

      &.Mui-checked {
        color: var(--Checkbox-Checked);
      }

      svg {
        font-size: 26px;
      }
    }

    .MuiTypography-root {
      color: var(--Gray-600);
      /*font-family: 'Inter';*/
      font-family: inherit;
      font-size: 14px;
    }
  }
`

const Checkbox = ({ checked, onChange, label, disabled, name, ...props }) => {
  const handleChange = () => {
    onChange(!checked)
  }

  return (
    <Container {...props}>
      <FormControlLabel
        name={name}
        control={
          <MatCheckbox color="primary" disableRipple checked={checked} disabled={disabled} onChange={handleChange} />
        }
        label={label}
      />
    </Container>
  )
}

export default Checkbox
