import styled from 'styled-components'
import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Api_Post_ActivityCriteria } from '../../redux/actions/master'

import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'
import DialogConfirmV2 from '../../components/dialog/DialogConfirmV2'
import TextField from '../../components/form/TextField'
import Dropdown from '../../components/form/Dropdown'
import Field from '../../components/form/Field'
import InputTextArea from '../../components/widget/element/InputTextArea'

import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


const Div = styled.div`
  .css_header_detail {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    .date_css{
      color: #7F56D9;
      font-weight: bold;
    }
  }
  .content_header {
    padding: 14px 14px;
    
    width : ${(props) => props.width}px;
    position: fixed;
    background: #FFFFFF;
    top: 0;
    right: 0;
    z-index: 1;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .dialog-content {
    padding: 0px 14px;
    padding-top: 90px;
    padding-bottom: 106px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .icon {
    cursor: pointer;
  }
  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .dialog-footer {
    /*margin-bottom: 15px;*/
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    width : ${(props) => props.width}px;
    background-color : #FFFFFF;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }

  .content{
    margin:10px 0px;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }
`

const NewActivityCPD = ({ onClose, onSubmit,...props  }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [DataInfo, setDataInfo] = useState({
    activity_type_no: null,
    activity_type: null,
    weight: null,
    weight_instructors: null,
    cpd_multiplier: null,
    max_point_auto: null,
    activity_number: null,
    activity_details: null,
    activity_criteria : null,
    activity_count_by : null,
    documents_submission : null,
    max_point : null,
  })
  const [activity_type_noOption, setactivity_type_noOption] = useState([
    {
        text: "1",
        value: "1"
    },
    {
        text: "2",
        value: "2"
    },
    {
        text: "3",
        value: "3"
    },
    {
        text: "4",
        value: "4"
    },
    {
        text: "5",
        value: "5"
    },
    {
        text: "6",
        value: "6"
    },
    {
        text: "7",
        value: "7"
    },
    {
        text: "8",
        value: "8"
    },
    {
        text: "9",
        value: "9"
    }
])
  //---- Modal Success And Fail
  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)
  const [ConfirmMessageModal, setConfirmMessageModal] = useState(initMessageModal)

  const ConfirmDialog = () => {
    setConfirmMessageModal({
        headline : 'ยืนยันการทำรายการหรือไม่',
        message : '',
    })
    setModalConfirmDialog(true)
  }
  const ProcessApi = () => {
    setModalConfirmDialog(false);

    
    const sent_data_api = DataInfo;
    dispatch(Api_Post_ActivityCriteria(sent_data_api)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        setSuccessMessageModal({
            headline : 'ทำรายการสำเร็จ',
            message : '',
        })
        setSuccessModal(true)
      }else{
        setFailMessageModal({
            headline : 'ทำรายการไม่สำเร็จ',
            message : '',
        })
        setFailModal(true)
      }     
    })
    
    //onSubmit()
  }
  return (
    <Div {...props}>
      <div className="content_header">
        <header>
          <div className="content-title">
            สร้างหลักเกณฑ์กิจกรรม CPD
          </div>
          <div className="wrp-tool-right">
            <CloseOutlinedIcon size={30} onClick={onClose} className="icon" />
          </div>
        </header>
        <div className="css_header_detail">สร้างเงื่อนไขกิจกรรม CPD ใหม่</div>
        <hr />
      </div>
      
      <div className="dialog-content">
            <div>
                <div className="content">
                    <Field className="field" label="กิจกรรมที่">
                        <Dropdown
                            id={`dd_dropdrow`}
                            value={DataInfo.activity_type_no}
                            optionList={activity_type_noOption}
                            onChange={(v) => setDataInfo({ ...DataInfo, activity_type_no: v })}
                            placeHolder={'เลือกกิจกรรมที่'}
                            ValueplaceHolderIsNull={true}
                        />
                    </Field>
                </div>
                <div className="content">
                    <Field className="field" label="ประเภทกิจกรรม">
                        <TextField
                            name="activity_type"
                            placeholder={'ระบุ'}
                            value={DataInfo.activity_type}
                            onChange={(e) => setDataInfo({ ...DataInfo, activity_type: e.target.value })}
                        />
                    </Field>
                </div>
                <div className="content">
                    <Field className="field" label="เลขที่กิจกรรม">
                        <TextField
                            name="activity_number"
                            placeholder={'ระบุ'}
                            value={DataInfo.activity_number}
                            onChange={(e) => setDataInfo({ ...DataInfo, activity_number: e.target.value })}
                        />
                    </Field>
                </div>
                <div className="content">
                    <Field className="field" label="รายละเอียดกิจกรรม">
                        <InputTextArea
                            className="InputTextArea_css"
                            initValue={DataInfo.activity_details}
                            onChange={(e) => setDataInfo({ ...DataInfo, activity_details: e.target.value  })}
                            placeHolder="กรุณาระบุ..."
                            borderColor={'var(--Gray-300)'}
                            borderWidth="1px"
                        />
                    </Field>
                </div>
                <div className="content">
                    <Field className="field" label="หลักเกณฑ์การนับคะแนน">
                        <InputTextArea
                            className="InputTextArea_css"
                            initValue={DataInfo.activity_criteria}
                            onChange={(e) => setDataInfo({ ...DataInfo, activity_criteria: e.target.value  })}
                            placeHolder="กรุณาระบุ..."
                            borderColor={'var(--Gray-300)'}
                            borderWidth="1px"
                        />
                    </Field>
                </div>
                <div className="content">
                    <Field className="field" label="นับตาม">
                        <TextField
                            name="activity_count_by"
                            placeholder={'ระบุ'}
                            value={DataInfo.activity_count_by}
                            onChange={(e) => setDataInfo({ ...DataInfo, activity_count_by: e.target.value })}
                        />
                    </Field>
                </div>
                <div className="content">
                    <Field className="field" label="ตัวคูณ CPD">
                        <TextField
                            name="cpd_multiplier"
                            placeholder={'ระบุ'}
                            value={DataInfo.cpd_multiplier}
                            onChange={(e) => setDataInfo({ ...DataInfo, cpd_multiplier: e.target.value })}
                        />
                    </Field>
                </div>
                <div className="content">
                    <Field className="field" label="ตัวคูณน้ำหนัก (การให้น้ำหนัก)">
                        <TextField
                            name="weight"
                            placeholder={'ระบุ'}
                            value={DataInfo.weight}
                            onChange={(e) => setDataInfo({ ...DataInfo, weight: e.target.value })}
                        />
                    </Field>
                </div>
                <div className="content">
                    <Field className="field" label="ตัวคูณน้ำหนัก (วิทยากร)">
                        <TextField
                            name="weight_instructors"
                            placeholder={'ระบุ'}
                            value={DataInfo.weight_instructors}
                            onChange={(e) => setDataInfo({ ...DataInfo, weight_instructors: e.target.value })}
                        />
                    </Field>
                </div>
                <div className="content">
                    <Field className="field" label="คะแนนสูงสุด (ห้ามสมาชิกกรอกเข้ามาเกินกำหนด)">
                        <TextField
                            name="max_point"
                            placeholder={'ระบุ'}
                            value={DataInfo.max_point}
                            onChange={(e) => setDataInfo({ ...DataInfo, max_point: e.target.value })}
                        />
                    </Field>
                </div>
                <div className="content">
                    <Field className="field" label="คะแนนสูงสุด (เพื่ออนุมัติอัตโนมัติ)">
                        <TextField
                            name="max_point_auto"
                            placeholder={'ระบุ'}
                            value={DataInfo.max_point_auto}
                            onChange={(e) => setDataInfo({ ...DataInfo, max_point_auto: e.target.value })}
                        />
                    </Field>
                </div>
                <div className="content">
                    <Field className="field" label="เอกสารประกอบการยื่น">
                        <InputTextArea
                            className="InputTextArea_css"
                            initValue={DataInfo.documents_submission}
                            onChange={(e) => setDataInfo({ ...DataInfo, documents_submission: e.target.value  })}
                            placeHolder="กรุณาระบุ..."
                            borderColor={'var(--Gray-300)'}
                            borderWidth="1px"
                        />
                    </Field>
                </div>
            </div>
      </div>
      
      <div className="dialog-footer">
        <hr />
        <ButtonSecondary className="button_margin" onClick={onClose}>
          {'ยกเลิก'}
        </ButtonSecondary>
        <Button className="button_margin" onClick={ConfirmDialog}>
          {'สร้างรายการ'}
        </Button>
      </div>

      {/* Dialog */}
        <DialogConfirmV2
          open={Boolean(ModalConfirmDialog)}
          onClose={() => setModalConfirmDialog(false)}
          onNo={() => setModalConfirmDialog(false)}
          onSubmit={ProcessApi}
          icon={successIcon}
          title={ConfirmMessageModal.headline}
          nameItem={ConfirmMessageModal.message}
          textYes={'ยืนยัน'}
          textNo={'ยกเลิก'}
        />
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={() => onSubmit()}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes='ตกลง'
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => {
          setFailModal(false)
        }}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes='ตกลง'
      />
    </Div>
  )
}

export default NewActivityCPD
