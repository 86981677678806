import React, { useState } from 'react'
import styled from 'styled-components'

import { FiEye, FiEyeOff, FiRefreshCw } from 'react-icons/fi'

// Styled components
const Container = styled.div`
  position: relative;
`

const PasswordInput = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  color: var(--Gray-500);

  padding-right: 40px;

  &:focus {
    outline: none;
    /* border-color: #4d90fe; */
  }
`

const ToggleButton = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  background: transparent;
  cursor: pointer;

  &.optional {
    right: 30px;
  }
`

const PasswordField = ({ value, optionalFunc, onChange, placeholder, disabled, className }) => {
  const [passwordShown, setPasswordShown] = useState(false)
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown)
  }

  return (
    <Container>
      <PasswordInput
        placeholder={placeholder}
        type={passwordShown ? 'text' : 'password'}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={className}
      />

      {optionalFunc && (
        <ToggleButton className="optional" onClick={optionalFunc}>
          <FiRefreshCw />
        </ToggleButton>
      )}

      <ToggleButton type="button" onClick={togglePasswordVisibility}>
        {passwordShown ? <FiEyeOff /> : <FiEye />}
      </ToggleButton>
    </Container>
  )
}

export default PasswordField
