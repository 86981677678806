import React from 'react'

import styled from 'styled-components'
import { Stack } from '@mui/material'

import { TbPointFilled } from 'react-icons/tb'

const BadgeContainer = styled.div`
  color: var(--Gray-700);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;

  border-radius: 6px;
  border: 1px solid var(--Gray-200);
  background: var(--Base-White);

  padding: 4px 8px;

  display: inline-block;

  .icon-color {
    &.color-1 {
      color: var(--success-500);
    }
    &.color-2 {
      color: var(--Purple-500);
    }
    &.color-3 {
      color: var(--Error-500);
    }
  }
`

function BadgeStatus({ status, className, props }) {
  return (
    <BadgeContainer className={className} {...props}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <TbPointFilled
          size={16}
          className={`icon-color ${status === 'ปกติ' ? 'color-1' : status === 'ร่าง' ? 'color-2' : 'color-3'}`}
        />{' '}
        <div>{status}</div>
      </Stack>
    </BadgeContainer>
  )
}

export default BadgeStatus
