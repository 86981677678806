import InputUnstyled from '@mui/base/Input'
import styled from 'styled-components'

const StyledTextField = styled(InputUnstyled)`
  display: inline-flex;
  background: var(--Base-White);
  border: 1px solid var(--Gray-300);
  box-shadow: var(--Shadow-xs);
  border-radius: 8px;
  height: 40px;

  input {
    border-radius: 8px;
    padding: 10px;
    width: 100%;
    min-width: 0;
    border: none;
    /*font-family: 'Inter';*/
    font-family: inherit;
    font-size: 14px;
    line-height: 1.5;
    color: var(--fill-color);
  }

  .prefix {
    margin-right: 10px;
  }

  .postfix {
    /*margin-left: 10px;*/
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  .postfix_border_left {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border-left: 1px solid rgb(234, 236, 240);
    width: 100px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }


  &.Mui-error {
    border-color: red;
  }
`

const TextField = ({ startAdornment, endAdornment,endAdornment_is_border_left = false, disabled = false, startAdornmentClassName, ...props }) => {
  return (
    <StyledTextField
      disabled={disabled}
      startAdornment={startAdornment && <div className={startAdornmentClassName ? startAdornmentClassName : 'prefix'} >{startAdornment}</div>}
      endAdornment={endAdornment && <div className={endAdornment_is_border_left ? 'postfix_border_left' : 'postfix'}>{endAdornment}</div>}
      {...props}
    />
  )
}

export default TextField
