import styled from 'styled-components'
import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Api_Post_ActivityCriteria } from '../../redux/actions/master'
import {  DateTimeHHmmssDisplay } from 'utils/common'


import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'
import DialogConfirmV2 from '../../components/dialog/DialogConfirmV2'

import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


const Div = styled.div`
  .css_header_detail {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    .date_css{
      color: #7F56D9;
      font-weight: bold;
    }
  }
  .content_header {
    padding: 14px 14px;
    
    width : ${(props) => props.width}px;
    position: fixed;
    background: #FFFFFF;
    top: 0;
    right: 0;
    z-index: 1;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .dialog-content {
    padding: 0px 14px;
    padding-top: 90px;
    padding-bottom: 15px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .icon {
    cursor: pointer;
  }
  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .dialog-footer {
    /*margin-bottom: 15px;*/
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    width : ${(props) => props.width}px;
    background-color : #FFFFFF;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }

  .content{
    margin:10px 0px;
  }
`

const TableDataStyled = styled.table`
width: 100%;
border-collapse: collapse;
box-shadow: var(--Shadow-xs);

tr {
  border: 1px solid var(--Gray-200);
}

th,
td {
  padding: 16px 14px;
  text-align: left;
  font-size: 14px;
  color: var(--Table-Font-Default);
  white-space: nowrap;
  background: var(--Table-Bg-Default);
}

th {
  padding: 12x 14px;
  font-weight: 500;
  background: var(--Gray-50);
}

tr:hover {
  td {
    background: var(--Table-Bg-Hover);
    color: var(--Table-Font-Hover);
  }
}

.content-wrapper {
  text-align: center;
}

.pagination {
  margin: 30px 24px;
}
`

const ModalApplicationHistory = ({ onClose, onSubmit,ApplicationDocHistory,ApplicationconsiderationHistory,...props  }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  return (
    <Div {...props}>
      <div className="content_header">
        <header>
          <div className="content-title">
          ประวัติการตรวจเอกสาร
          </div>
          <div className="wrp-tool-right">
            <CloseOutlinedIcon size={30} onClick={onClose} className="icon" />
          </div>
        </header>
        <div className="css_header_detail">ตรวจสอบข้อมูล</div>
        <hr />
      </div>
      
      <div className="dialog-content">
        <div className="content">
        <TableDataStyled>
            <thead>
                <tr>
                    <th>ลำดับ</th>
                    <th>วันที่ตรวจเอกสาร</th>
                    <th>ผลการตรวจเอกสาร</th>
                    <th>หมายเหตุ</th>
                    <th>โดยเจ้าหน้าที่</th>
                </tr>
            </thead>
            <tbody >
                {ApplicationDocHistory?.map((item, index) => (
                        <tr>
                            <td>{index + 1}</td>
                            <td>{DateTimeHHmmssDisplay(item.created_at)}</td>
                            <td>{item.data_info?.status ? item.data_info?.status == 101 ? 'ขอเอกสารเพิ่มเติม' :  'รอนัดตรวจประเมิน' : '-'}</td>
                            <td>{item.data_info?.comment_document ? item.data_info?.comment_document : '-'}</td>
                            <td>{item.full_name ? item.full_name : '-'}</td>
                        </tr>
                ))} 

                {ApplicationconsiderationHistory?.map((item, index) => (
                        <tr>
                            <td>{index + 1}</td>
                            <td>{DateTimeHHmmssDisplay(item.created_at)}</td>
                            <td>{item.data_info?.record_consideration ? item.data_info?.record_consideration == 1 ? 'ผ่าน (ส่งผลเข้าคณะอนุกรรมการ)' : item.data_info?.record_consideration == 2 ? 'ไม่ผ่าน (ส่งผลเข้าคณะอนุกรรมการ)' : 'ขอเอกสารเพิ่มเติม' : '-'}</td>
                            <td>{item.data_info?.comment_consideration ? item.data_info?.comment_consideration : '-'}</td>
                            <td>{item.full_name ? item.full_name : '-'}</td>
                        </tr>
                ))} 
            </tbody>
        </TableDataStyled>
        </div>

      </div>
      
    </Div>
  )
}

export default ModalApplicationHistory
