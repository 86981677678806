import React from 'react'

import styled from 'styled-components'
import { Box, Divider, Stack } from '@mui/material'
import TextArea from 'components/form/TextArea'

import { LiaTimesSolid } from 'react-icons/lia'
import { GrHistory } from 'react-icons/gr'

const Div = styled.div`
  padding: 24px;

  .close-icon {
    &:hover {
      cursor: pointer;
    }
  }

  .heading {
    color: var(--Gray-900);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
  }
  .sub-heading {
    color: var(--Gray-600);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
  }

  .history-item {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;

    margin-bottom: 24px;

    .reopen-text-color {
      color: var(--success-600);
    }

    .cancel-text-color {
      color: var(--Error-600);
    }

    .date-text {
      color: var(--Gray-700);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
    }
  }
`

function HistorySidePanelContent({ onClose }) {
  return (
    <Div>
      <Stack sx={{ mb: 3 }} direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={1}>
          <Box
            sx={{
              border: 1,
              borderColor: 'var(--Gray-200)',
              borderRadius: '8px',
              padding: '10px',
              width: 40,
              height: 40,
            }}
          >
            <Stack alignItems="center">
              <GrHistory />
            </Stack>
          </Box>
          <Box>
            <div className="heading">ประวัติการแก้ไข</div>
            <div className="sub-heading">ตรวจสอบข้อมูลการแก้ไขสถานะกิจกรรม</div>
          </Box>
        </Stack>

        <LiaTimesSolid size={18} className="close-icon" onClick={onClose} />
      </Stack>

      <Box className="history-item">
        <div className="date-text">วันที่ 14 มีนาคม 2567 09:13:22 น.</div>

        <Box sx={{ mb: 2 }}>
          <div className="reopen-text-color">เปิดกิจกรรมอีกครั้ง</div>
        </Box>

        <Box sx={{ mb: 1 }}>
          <div>หมายเหตุ</div>
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextArea disabled={true} />
        </Box>

        <Box sx={{ mb: 3 }}>
          <div>โดย นางสาว ขวัญฤทัย ปินใจ</div>
        </Box>

        <Divider />
      </Box>
      <Box className="history-item">
        <div className="date-text">วันที่ 14 มีนาคม 2567 09:13:22 น.</div>

        <Box sx={{ mb: 2 }}>
          <div className="cancel-text-color">ยกเลิกกิจกรรม</div>
        </Box>

        <Box sx={{ mb: 1 }}>
          <div>หมายเหตุ</div>
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextArea disabled={true} />
        </Box>

        <Box sx={{ mb: 3 }}>
          <div>โดย นางสาว ขวัญฤทัย ปินใจ</div>
        </Box>

        <Divider />
      </Box>
    </Div>
  )
}

export default HistorySidePanelContent
