import styled from 'styled-components'
import { useState, useEffect, useCallback,useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import {  getAdminUser } from '../../utils/common'
import { postAPI } from '../../utils/apiUpload'
import { Api_Post_OrgElearningExam,Api_Update_OrgElearningExam } from '../../redux/actions/elearning'

import TextField from '../../components/form/TextField'
import Dropdown from '../../components/form/Dropdown'
import InputTextArea from '../../components/widget/element/InputTextArea'
import Button from '../../components/form/button/Button'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import question from '../../assets/images/question.png'
import DialogConfirmV2 from '../../components/dialog/DialogConfirmV2'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'
import TextEditorV2 from '../../components/widget/element/TextEditorV2'
import Field from '../../components/form/Field'
import SwitchButton from '../../components/form/SwitchButton'
import RadioButton from '../../components/form/RadioButton'


import { GiCancel } from 'react-icons/gi'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'
import { useTranslation } from 'react-i18next'
import { RiDeleteBinLine } from "react-icons/ri";
import { BsRepeat } from "react-icons/bs";
import noData from '../../assets/images/no-data.png'
import { GoPlus } from "react-icons/go";

const Div = styled.div`
  .css_header_detail {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    .date_css{
      color: #7F56D9;
      font-weight: bold;
    }
  }
  .content_header {
    padding: 14px 14px;
    
    width : ${(props) => props.width}px;
    position: fixed;
    background: #FFFFFF;
    top: 0;
    right: 0;
    z-index: 1;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .dialog-content {
    padding: 0px 14px;
    padding-top: 90px;
    padding-bottom: 106px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .icon {
    cursor: pointer;
  }
  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
    
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .topic_main {
    font-size: 14px;
    font-weight: 600;
    padding: 5px 0px;
    color: #344054;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }
  .dialog-footer {
    /*margin-bottom: 15px;*/
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    width : ${(props) => props.width}px;
    background-color : #FFFFFF;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }

  .wrp-tool {
    display: flex;
    justify-content: space-between;
  }
  .wrp-tool-left {
    display: flex;
    align-items: center;
    /* background-color: red; */
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }
  .css_upload {
    margin-top: 10px;
  }
  .topic_fill{
    font-size: 14px;
    color: #344054;
    margin: 5px 0px;
  }
  .TextField_employee {
    width: 100%;
  }
  .content{
    margin:10px 0px;
    background-color: #F9FAFB;
    border-radius: 8px;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }
  .prefix_icon{
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
  .Input_content{
    padding: 24px 24px;
  }
  .question_content{
    display: grid;
    grid-template-columns: repeat(2, 70% [ col-start ] 20% [ col-end ]);
    gap: 10px;
    .input_css{
        width: 100%;
    }
  }
  .text-editor {
    min-height: 130px;
    background: #FFFFFF;
    &.error{
      border: 1px solid red;
    }
  }
  .ql-container {
    min-height: 170px;
    font-family: inherit;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
  }
  .css_magin_top_24 {
    margin-top: 24px;
  }
  .css_magin_top_16 {
    margin-top: 16px;
  }
  .input_img{
    width: 287px;
  }
  .question_img{
    width: 287px;
    height: 184px;
    img {
      width: 287px;
      height: 184px;
    }
  }
  .question_button {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
  }
  .answer_box{
    border-radius: 12px;
    border: 1px solid #EAECF0;
    background: #FFFFFF;

    &.correct_answer {
        border: 1px solid #A91F23;
        background: #FEF3F2;
    }
  }
  .answer_content{
    padding : 23px 23px;
    display: grid;
    grid-template-columns: repeat(4, 5% [col-start] 60% [col-middle] 30% [col-middle] 5% [col-end]);
    gap: 10px;
    .input_css{
        width: 100%;
    }
  }
  .css_random_answer{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .css_radio_correct_answer{
    text-align: center;
  }
`

const DivErrorMessage = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`

const HiddenInput = styled.input.attrs({ type: 'file' })`
  display: none;
`

const acceptList = {
  images: {
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
  },
  files: {
    'text/csv': [],
    'application/pdf': [],
    'application/msword': [],
    'application/vnd.ms-excel': [],
    'application/zip': [],
  },
  videos: {
    'video/x-msvideo': [],
    'video/mp4': [],
    'video/mpeg': [],
    'video/webm': [],
  },
  audios: {
    'audio/mpeg': [],
    'audio/wav': [],
    'audio/webm': [],
  },
}

//---- FIX คะแนน 
const valid_answer_point = 1;   //-- ตอบถูก
const invalid_answer_point = 0; //-- ตอบผิด
const no_answer_point = 0;      //-- ไม่ตอย
const EditQuestion = ({ onClose, onSubmit,DataEdit,...props  }) => {
  const { t } = useTranslation()
  const user = getAdminUser()
  const dispatch = useDispatch()


  const [QuestionData, setQuestionData] = useState({
    elearning_id : DataEdit.elearning_id,
    question : DataEdit.question,
    question_img : DataEdit.question_img,
    is_random_answer : DataEdit.is_random_answer,
    status_id : DataEdit.status_id,
    valid_answer_point : DataEdit.valid_answer_point,
    invalid_answer_point : DataEdit.invalid_answer_point,
    no_answer_point : DataEdit.no_answer_point,
    show_errorMessage : false,
    answer_list : JSON.parse(DataEdit.answer_list),
  })
  const initRadioList = [
    {
      text: '',
      value: true,
    },
  ]


  const onChangeAnswer = (index_item, e, type_data) => {
    const newState = QuestionData?.answer_list?.map((obj, index) => {
      if (index === index_item) {
        if(type_data == 'texteditor_choice'){
          return { ...obj, choice: e.html , show_errorMessage : e.html ? false : true  }
        }else if(type_data == 'del_choice_image'){
          return { ...obj, choice_image: null  }
        }else if(type_data == 'add_choice_image'){
          return { ...obj, choice_image: e  }
        }
      }
      return obj
    })
    setQuestionData({ ...QuestionData, answer_list: newState })
  }

  const onChangeRadio = (index_item,event) => {
    const { value } = event.target;
    const newState = QuestionData?.answer_list?.map((obj, index) => {
        if (index === index_item) {
            return { ...obj, is_answer: true  }
        }else{
            return { ...obj, is_answer: false  }
        }
    })
    setQuestionData({ ...QuestionData, answer_list: newState })
  }
  const onAddAnswer = () => {
    let newDataElement = {
        choice : null,
        choice_image : null,
        is_answer : false,
        is_user_answer : false,
        show_errorMessage : false
    }
    const newStateData = [...QuestionData.answer_list, newDataElement]
    setQuestionData({ ...QuestionData, answer_list: newStateData })
  }
  const RemoveAnswer = (index_item) => {
    const currentListCopy = [...QuestionData.answer_list]
    currentListCopy.splice(index_item, 1)
    setQuestionData({ ...QuestionData, answer_list: currentListCopy })
  }
  const onChangeRadioMain = (event) => {
    setQuestionData({ ...QuestionData, is_random_answer: event.target.checked })
  }

  const inputFileQuestionRef = useRef(null)
  const OpenInputQuestionFile = () => {
    inputFileQuestionRef.current.click()
  }

  const onChangeQuestionFile = async (event) => {
    const file = event.target.files[0]
    const fetchList = []
    const formData = new FormData()
    formData.append('file', file)
    formData.append(
      'fileInfo',
      JSON.stringify({
        name: file.name,
        caption: file.name,
        alternativeText: file.name,
        folder: null,
      })
    )


    
    fetchList.push(
      postAPI({
        url: '/file/uploadformdata',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: null,
        },
        data: formData,
      })
    )

    const res = await Promise.all(fetchList)
    console.log(JSON.stringify(res))
    if(res.length > 0){
      if(res[0].url_file){
        console.log(JSON.stringify(res[0].url_file))
        setQuestionData({ ...QuestionData, question_img: res[0].url_file })
      }
    }
  }
  const [AnswerIndex, setAnswerIndex] = useState(-1)
  const inputFileAnswerRef = useRef(null)
  const OpenInputAnswerFile = (index_item) => {
    setAnswerIndex(index_item)
    inputFileAnswerRef.current.click()
  }

  const onChangeAnswerFile = async (event) => { 
    if(AnswerIndex >= 0){
      const file = event.target.files[0]
      const fetchList = []
      const formData = new FormData()
      formData.append('file', file)
      formData.append(
        'fileInfo',
        JSON.stringify({
          name: file.name,
          caption: file.name,
          alternativeText: file.name,
          folder: null,
        })
      )
  
  
      
      fetchList.push(
        postAPI({
          url: '/file/uploadformdata',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: null,
          },
          data: formData,
        })
      )
  
      const res = await Promise.all(fetchList)
      console.log(JSON.stringify(res))
      if(res.length > 0){
        if(res[0].url_file){
          console.log(JSON.stringify(res[0].url_file))
          //setQuestionData({ ...QuestionData, question_img: res[0].url_file })
          onChangeAnswer(AnswerIndex,res[0].url_file, 'add_choice_image')
          setAnswerIndex(-1)
        }
      }
    }
  }

  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [ConfirmMessageModal, setConfirmMessageModal] = useState(initMessageModal)
  const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)

  const [Titlesuccess, setTitlesuccess] = useState('')
  const [SubTitlesuccess, setSubTitlesuccess] = useState('')
  const [Descriptionsuccess, setDescriptionsuccess] = useState('')
  const [successModal, setsuccessModal] = useState(false)

  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)

  const isValid = () => {
    let errors = [];
    const regex = /(<([^>]+)>)/gi;
    const data_question = QuestionData.question ? QuestionData.question.replace(regex, "") : null;
    if(!data_question){
      errors.push('ระบุคำถาม')
      setQuestionData({ ...QuestionData, show_errorMessage: true })
    }
    if(errors.length == 0){
      if(QuestionData.answer_list){
        
        if(QuestionData.answer_list.length >= 2){
          const newState = QuestionData?.answer_list?.map((obj, index) => {
            const data_choice = obj.choice ? obj.choice.replace(regex, "") : null;
            if(data_choice){
              return { ...obj, show_errorMessage: false  }
            }else{
              errors.push('ระบุคำตอบ')
              return { ...obj, show_errorMessage: true  }
            }
          })
          setQuestionData({ ...QuestionData, answer_list: newState })
        }else{
          errors.push('ต้องมีคำตอบอย่างน้อย 2 ข้อขึ้นไป')
          setTitleFail('ต้องมีคำตอบอย่างน้อย 2 ข้อขึ้นไป');
          setnameItemFail('');
          setDescriptionFail(''); 
          setFailModal(true)
        }
      }else{
        errors.push('ต้องมีคำตอบอย่างน้อย 2 ข้อขึ้นไป')
        setTitleFail('ต้องมีคำตอบอย่างน้อย 2 ข้อขึ้นไป');
        setnameItemFail('');
        setDescriptionFail(''); 
        setFailModal(true)
      }
    }
    
    if(errors.length == 0){
      const CheckIsAnsWER = QuestionData?.answer_list?.filter((v) => v.is_answer == true);
      if(CheckIsAnsWER.length == 0){
        errors.push('กรุณาระบุคำตอบที่ถูกต้อง')
        setTitleFail('กรุณาระบุคำตอบที่ถูกต้อง');
        setnameItemFail('');
        setDescriptionFail(''); 
        setFailModal(true)
      }
    }
    
    return errors
  }

  const ProcessData = () => {
    if (isValid().length == 0) {
      const sent_data_to_api = {
        elearning_id : QuestionData.elearning_id,
        question : QuestionData.question,
        question_img : QuestionData.question_img,
        is_random_answer : QuestionData.is_random_answer,
        status_id : QuestionData.status_id,
        valid_answer_point : QuestionData.valid_answer_point,
        invalid_answer_point : QuestionData.invalid_answer_point,
        no_answer_point : QuestionData.no_answer_point,
        answer_list : JSON.stringify(QuestionData.answer_list)
      }
      dispatch(Api_Update_OrgElearningExam(DataEdit.id,sent_data_to_api)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          if(data_info.id){
            onSubmit();
          }else{
            setTitleFail('ไม่สามารถแก้ไขข้อสอบได้');
            setnameItemFail('');
            setDescriptionFail(''); 
            setFailModal(true)
          }
        }else{
          setTitleFail('ไม่สามารถแก้ไขข้อสอบได้');
          setnameItemFail('');
          setDescriptionFail(''); 
          setFailModal(true)
        }
      })
    }
  }


  return (
    <Div {...props}>
      <div className="content_header">
        <header>
          <div className="content-title">แก้ไขคำถาม คำตอบ</div>
          <div className="wrp-tool-right">
            <CloseOutlinedIcon size={30} onClick={onClose} className="icon" />
          </div>
        </header>
        <div className="css_header_detail">แก้ไขรายละเอียดคำถาม</div>
        <hr />
      </div>
      
      <div className="dialog-content">
        <div className="content">
            <div className="Input_content">
                <div className="question_content">
                    <div className="input_css">
                        <Field className="field" label="คำถาม">
                            <TextEditorV2
                                className={`text-editor ${QuestionData.show_errorMessage ? 'error' : ''}`}
                                placeHolder="Enter..."
                                initValue={QuestionData.question}
                                onBlur={(e) => setQuestionData({ ...QuestionData, question: e.html })}
                            />
                            {QuestionData.show_errorMessage && (
                              <DivErrorMessage>กรุณาระบุคำถาม</DivErrorMessage>
                            )}
                        </Field>
                    </div>
                    <div className="input_img">
                        <div className="question_img">
                            <img src={QuestionData.question_img ? QuestionData.question_img : noData } />
                        </div>
                        <div className="css_magin_top_16"></div>
                        <div className="question_button">
                            {QuestionData.question_img && (
                                <ButtonSecondary append={<RiDeleteBinLine size={16} />} onClick={() => setQuestionData({ ...QuestionData, question_img: null })} >
                                    ลบ
                                </ButtonSecondary>
                            )}
                            <ButtonSecondary append={<BsRepeat size={16} />} onClick={OpenInputQuestionFile} >
                                เปลี่ยนรูป
                            </ButtonSecondary>
                        </div>
                        <HiddenInput ref={inputFileQuestionRef} id="custom-file-input" onChange={onChangeQuestionFile} />
                    </div>
                </div>
                <div className="css_magin_top_24"></div>
                <div className="border_header"></div>
                <div className="css_magin_top_24"></div>
                <div >เลือกคำตอบที่ถูกต้อง</div>
                <div className="css_magin_top_24"></div>
                <HiddenInput ref={inputFileAnswerRef} id="custom-file-input" onChange={onChangeAnswerFile} />
                {QuestionData?.answer_list?.map((item, index) => (
                    <div>
                        <div className={`answer_box ${item.is_answer ? 'correct_answer' : ''}`}>
                            <div className="answer_content">
                                <div>
                                    <Field className="field" label={'ตัวเลือกที่ ' + (index + 1)}>
                                        <div className="css_radio_correct_answer">
                                            <RadioButton optionList={initRadioList} value={item.is_answer} handleChange={(e) => onChangeRadio(index,e)} />
                                        </div>                                       
                                    </Field>
                                </div>
                                <div>
                                    <Field className="field" label={'รายละเอียด'}>
                                        <TextEditorV2
                                            className={`text-editor ${item.show_errorMessage ? 'error' : ''}`}
                                            placeHolder="Enter..."
                                            initValue={item.choice}
                                            onBlur={(e) => onChangeAnswer(index,e, 'texteditor_choice')}
                                        />
                                        {item.show_errorMessage && (
                                          <DivErrorMessage>กรุณาระบุรายละเอียด</DivErrorMessage>
                                        )}
                                    </Field>
                                </div>
                                <div className="input_img">
                                    <div className="question_img">
                                        <img src={item.choice_image ? item.choice_image : noData } />
                                    </div>
                                    <div className="css_magin_top_16"></div>
                                    <div className="question_button">
                                        {item.choice_image && (
                                          <ButtonSecondary append={<RiDeleteBinLine size={16} />} onClick={() => onChangeAnswer(index,'', 'del_choice_image')}>
                                            ลบ
                                          </ButtonSecondary>
                                        )}
                                        
                                        <ButtonSecondary append={<BsRepeat size={16} />} onClick={() => OpenInputAnswerFile(index)}>
                                            เปลี่ยนรูป
                                        </ButtonSecondary>
                                    </div>
                                    
                                </div>
                                <div>
                                    <RiDeleteBinLine size={18} className="icon" onClick={() => RemoveAnswer(index)}/>
                                </div>
                            </div>
                        </div>
                        <div className="css_magin_top_24"></div>
                    </div>

                ))} 
                <div>
                    <ButtonSecondary append={<GoPlus  size={18} />} onClick={onAddAnswer} >
                        {'เพิ่มตัวเลือก'}
                    </ButtonSecondary>
                </div>
                <div className="css_magin_top_24"></div>
                <div className="css_random_answer">
                    <div>
                        <SwitchButton
                            value={true}
                            defaultChecked={QuestionData.is_random_answer}
                            handleChange={(event) => onChangeRadioMain(event)}
                            disabled={false}
                        />
                    </div>
                    <div>
                        สลับลำดับของตัวเลือก
                    </div>

                    
                </div>
                
            </div>
        </div>
      </div>
      
      <div className="dialog-footer">
        <hr />
        <ButtonSecondary className="button_margin" onClick={onClose}>
          {'ยกเลิก'}
        </ButtonSecondary>
        <Button className="button_margin" onClick={ProcessData}>
          {'บันทึก'}
        </Button>
      </div>

        <DialogConfirmV2
          open={Boolean(ModalConfirmDialog)}
          onClose={() => setModalConfirmDialog(false)}
          onNo={() => setModalConfirmDialog(false)}
          onSubmit={ProcessData}
          icon={successIcon}
          title={ConfirmMessageModal.headline}
          nameItem={ConfirmMessageModal.message}
          textYes={'ยืนยัน'}
          textNo={'ยกเลิก'}
        />

      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setsuccessModal(false)}
        onSubmit={() => onSubmit()}
        icon={successIcon}
        title={Titlesuccess}
        subtitle={SubTitlesuccess}
        description={Descriptionsuccess}
        textYes={'ตกลง'}
      />

        <DialogFail
          open={Boolean(failModal)}
          onClose={() => setFailModal(false)}
          onSubmit={() => setFailModal(false)}
          icon={failIcon}
          title={TitleFail}
          nameItem={nameItemFail}
          description={DescriptionFail}
          textYes={'ตกลง'}
        />
    </Div>
  )
}

export default EditQuestion
