import React, { useState,useEffect } from 'react'

import styled from 'styled-components'
import { Box } from '@mui/material'
import HeaderPage from 'components/containers/HeaderPage'
import TabGroupMenu from 'components/containers/TabGroupMenu'
import OrgActivityByCoE from 'components/feature/CpdManage/OrgActivityByCoE'
import OrgActivityNotCOE from 'components/feature/CpdManage/OrgActivityNotCOE'
import ReviewOrgActivity from 'components/feature/CpdManage/ReviewOrgActivity'

import {  GetPermissionTabList } from 'utils/common'

const Div = styled.div``

function OrgActivity() {
  /*
  const tabMenuOptions = [
    {
      text: 'กิจกรรมแม่ข่ายโดยสภาวิศวกร',
      value: 'org-activity',
    },
    {
      text: 'ตรวจสอบกิจกรรมแม่ข่ายอื่นๆ',
      value: 'review-org-activity',
    },
  ]
  const [tabMenu, setTabMenu] = useState('org-activity')
  */

  const [tabMenu, setTabMenu] = useState('')
  const [tabMenuOptions, settabMenuOptions] = useState([])

  useEffect(() => {
    const this_tab_list = GetPermissionTabList('CpdManageOrgActivity');
    if(this_tab_list.length > 0){
      const tab_permission = this_tab_list
      let temp_tab = [];
      let index_tab = 0;
      for (const  objtab_permission of tab_permission) {
        //console.log(objtab_permission.key);
        temp_tab.push({
          text : objtab_permission.name,
          value : objtab_permission.key,
          active_status : index_tab == 0 ? true : false
        })
        
        if(index_tab == 0){
          setTabMenu(objtab_permission.key)
        }
        index_tab = index_tab + 1;
      }
      settabMenuOptions(temp_tab)
    }
  }, [])

  return (
    <Div>
      <Box sx={{ mb: 2 }}>
        <HeaderPage heading="กิจกรรมองค์กรแม่ข่าย" subHeading="สำหรับเจ้าหน้าที่ตรวจสอบกิจกรรมองค์กรแม่ข่าย" />
      </Box>

      <Box sx={{ mb: 2 }}>
        <TabGroupMenu tabMenuOptions={tabMenuOptions} tabMenu={tabMenu} setTabMenu={setTabMenu} />
      </Box>

      {tabMenu === 'CpdManageOrgActivity_COE' && <OrgActivityByCoE />}
      {tabMenu === 'CpdManageOrgActivity_Other' && <OrgActivityNotCOE />}
    </Div>
  )
}

export default OrgActivity
