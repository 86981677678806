import React from 'react'

import Textarea from '@mui/joy/Textarea'

function TextArea({ value, onChange, rows = 6, placeholder = 'Enter your text here', ...props }) {
  return (
    <Textarea
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      minRows={rows}
      size="md"
      variant="outlined"
      sx={{
        '--Textarea-background': 'white',
        '--Textarea-minHeight': `${rows * 24}px`,
        backgroundColor: 'white',
        ...props.sx,
      }}
      {...props}
    />
  )
}

export default TextArea
