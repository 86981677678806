import React from 'react'
import styled from 'styled-components'

import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'

const StyledRadio = styled(Radio)`
  &.MuiRadio-colorPrimary.Mui-checked {
    color: var(--Radio-Checked);
  }
`
const DivLabel = styled.div`
  font-family: 'Inter', 'Noto Sans Thai';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color : #101828;
`

function RadioButton({ label, optionList, value, name, handleChange, disabled }) {
  return (
    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">{label}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-radio-buttons-group-label"
        value={value}
        onChange={handleChange}
        name={name}
      >
        {optionList.map((v, i) => (
          <FormControlLabel key={i} value={v.value} control={<StyledRadio />} label={<DivLabel >{v.text}</DivLabel>} disabled={disabled} />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default RadioButton
