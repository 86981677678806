import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import {  getAdminUser,GetPermissionOther } from '../../utils/common'
import { ApiGet_vw_service_condition_cpd,ApiGET_SERVICE_TYPE,Api_Update_service_condition_cpd } from '../../redux/actions/master'

import Button from '../../components/form/button/Button'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import TableV2 from '../../components/common/TableV2'
import Field from '../../components/form/Field'
import SearchText from '../../components/form/SearchText'
import Dropdown from '../../components/form/Dropdown'
import SidePanel from '../../components/common/SidePanel'
import NewConditionCPD from '../../pages/CpdManage/NewConditionCPD'
import EditConditionCPD from '../../pages/CpdManage/EditConditionCPD'
import ViewConditionCPD from '../../pages/CpdManage/ViewConditionCPD'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'
import DialogConfirmV2 from '../../components/dialog/DialogConfirmV2'

import iconPlus from '../../assets/images/icon-plus.svg'
import iconEdit from '../../assets/images/edit-icon.png'
import iconDelete from '../../assets/images/icon-bin-red.svg'
import { FaBullseye } from 'react-icons/fa'
import { GrStatusGoodSmall } from 'react-icons/gr'
import warningIcon from '../../assets/images/warning-icon.png'
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'

const Div = styled.div`
.cursor_pointer {
    cursor: pointer;
  }
  .box_header{
    display: flex;
    justify-content: space-between;
  }
  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Base-Font-Title-Color);
  }
  .content-title-text {
    color: var(--Base-Font-Title-SUB-Color);
    font-size: 18px;
  }
  .css_button_add{
    display: inline-block;
    align-self: flex-end;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
  }
  .status-badge {
    width: fit-content;
    padding: 2px 6px;
    text-align: center;
    font-weight: 500;
    border-radius: 16px;
    color: #475467;
    background: #f5f5f4;
    border: 1px #e7e5e4 solid;

    &.expire {
      color:#B42318;
      background: #FEF3F2;
      border: 1px #FECDCA solid;
    }
    &.active {
      color:#067647;
      background: #ECFDF3;
      border: 1px #ABEFC6 solid;
    }

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: #475467;
      

      &.expire {
        color:#B42318;
      }
      &.active {
        color:#067647;
      }

    }
  }

  .table-wrapper {
    margin-top: 24px;
    border: 1px solid #eaecf0;
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    background-color: #ffffff;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Table-Bg-Header);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--BADGE-Table-Font);
        background: var(--BADGE-Table-Bg);
        border-radius: 16px;
        border: 1px var(--BADGE-Table-Border) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }
  .css_magin_top_10 {
    margin-top: 10px;
  }
  .css_magin_top_24 {
    margin-top: 24px;
  }
  .button_CreateModal {
    color: #ffffff;
    background-color: #17b26a;
    border: 1px solid #17b26a;
  }
  .text-bold {
    font-weight: bold;
  }
  
  .link {
    color: var(--Td-Font-Color-Link);
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`


const DivFilter = styled.div`
min-height: 68px;
background-color: var(--Gray-50);
border-radius: 8px;
margin-bottom: 21px;
padding: 10px;
.content-fillter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .search-wrapper {
        margin-right: 12px;
    }

    .filter-list {
        flex-basis: 100%;
        margin-top: 24px;
    }

    .group_filter {
        display: flex;
        align-items: center;
    }
}
.css_filter_name {
    min-width: 380px;
    margin-right: 10px;
}
.mr-1-rem {
    margin-right: 1rem;
}
.css_button_filter{
    display: inline-block;
    align-self: flex-end;
}
.dd_filter{
    min-width: 380px;
}
.dd_filterStatusExam{
  min-width: 115px;
}
`



const TabConditionCPD = () => {
  const dispatch = useDispatch()
  const user = getAdminUser()

  const permission_CpdConditionPoint_Create = GetPermissionOther('CpdConditionPoint','CpdConditionPoint_Create');
  const permission_CpdConditionPoint_Edit = GetPermissionOther('CpdConditionPoint','CpdConditionPoint_Edit');
  const permission_CpdConditionPoint_Del = GetPermissionOther('CpdConditionPoint','CpdConditionPoint_Del');

  const [service_type_id, setservice_type_id] = useState('')
  const [filterStatus, setfilterStatus] = useState('')
  const [SERVICE_TYPEOption, setSERVICE_TYPE] = useState([])
  useEffect(() => {
    const filter = {
      skip: 0,
      limit: 1000,
    }
    dispatch(ApiGET_SERVICE_TYPE(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const newStatusOption=  data_info?.data?.map((v) => ({
              text: v?.name_th_th,
              value: v?.id
            }))
            setSERVICE_TYPE([...newStatusOption])
        }
    })
  }, [dispatch])
  const [StatusOption, setStatusOption] = useState([
    {
      text : 'Active',
      value: 1
    },
    {
      text : 'Cancle',
      value: 2
    },
  ])

  const limit_data = 10;
  const [total, settotal] = useState(0)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [TableCellData, setTableCellData] = useState([])
  const TableColumnData = [
    {
      Header: 'ลำดับ',
      accessor: 'no',
      disableSortBy: false,
    },
    {
        Header: 'ประจำปี',
        accessor: 'year',
        disableSortBy: false,
    },
    {
        Header: 'ประเภทบริการ',
        accessor: 'service_type_name',
        disableSortBy: false,
    },
    {
        Header: 'เงื่อนไขคะแนน',
        accessor: 'score_criteria',
        disableSortBy: false,
    },
    {
        Header: 'อายุคะแนน',
        accessor: 'num_year',
        disableSortBy: false,
    },
    {
        Header: 'สถานะ',
        accessor: 'status',
        disableSortBy: false,
    },
    {
        accessor: 'buttonDelete',
        disableSortBy: false,
    },
    {
        accessor: 'linkEdit',
        disableSortBy: false,
    },
  ]

  const fetchData = useCallback(
    async (filter) => {

      dispatch(ApiGet_vw_service_condition_cpd(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          if (data_info.total > 0) {
            const totalPage = Math.ceil(data_info.total / limit_data)
            settotal(data_info.total)
            setTotalPages(totalPage)

            const newTableCellData = data_info.data.map((v, index) => ({
              no: limit_data * page - (9 - index),
              year: v.year ? parseInt(v.year) + 543 : '-',
              service_type_name: v.service_type_name ? v.service_type_name : '-',
              score_criteria: v.score_criteria ? (
                <div className="text-bold link" onClick={() => OpenViewModal(v)}>
                  {v.score_criteria}
                </div>
              ) : '-',
              num_year: v.num_year ? v.num_year : '-',
              status: (
                v.status_name ? 
                <div
                  className="status-badge"
                  style={{
                    color: v.status_badge_color?.color,
                    borderColor: v.status_badge_color?.border,
                    backgroundColor: v.status_badge_color?.background,
                  }}
                >
                  <GrStatusGoodSmall 
                    className="status"
                    style={{
                      color: v.status_badge_color?.color,
                    }}
                  />
                  {v.status_name}
                </div>
                : 
                <div >-</div>
              ),
              buttonDelete: permission_CpdConditionPoint_Del?.canRead === true ? <img className="cursor_pointer" src={iconDelete} title="Delete" onClick={() => DelData(v.id)} /> : '',
              linkEdit: permission_CpdConditionPoint_Edit?.canRead === true ? <img className="cursor_pointer" src={iconEdit} title="Edit" onClick={() => OpenEditModal(v)}  /> : '',
            }))
            setTableCellData(newTableCellData)
          }else{
            settotal(0);
            setPage(0);
            setTotalPages(0);
            setTableCellData([]);
          }
         
        }
      })

    },
    [dispatch]
  )

  useEffect(() => {
    const filter = {
      skip: 0,
      limit: limit_data,
    }
    fetchData(filter) //-- ปิด รอ API
  }, [fetchData, limit_data])


  const onChangePaginationAttendeeAttend = async (value) => {
    const filter = {
      skip: (value.page  - 1) * limit_data, 
      limit: limit_data,
    }
    fetchData(filter)
    setPage(value.page)
  }

  const onSearch = () => {
    const filter = {
      skip: 0,
      limit: limit_data,
      activity_type : service_type_id,
      status_id : filterStatus,
    }
    fetchData(filter)
    setPage(1)
  }
  const onClearSearch = () => {
    setservice_type_id('')
    setfilterStatus('')
    const filter = {
      skip: 0,
      limit: limit_data,
    }
    fetchData(filter)
    
    setPage(1)
  }
  const [ThisId, setThisId] = useState(false)
  const [WidthSidePanel, setWidthSidePanel] = useState(400);
  const [ModalNew, setModalNew] = useState(false);
  const [ModalEdit, setModalEdit] = useState(false);
  const [ModalView, setModalView] = useState(false);
  const OpenCreateModal = () => {
    setModalNew(true);
  }
  const OpenViewModal = (Obj) => {
    setThisId(Obj.id);
    setModalView(true);
  }
  const OpenEditModal = (Obj) => {
    setThisId(Obj.id);
    setModalEdit(true);
  }
  const DelData = (data) => {
    setThisId(data)
    setConfirmMessageModal({
        headline: 'ต้องการลบรายการนี้หรือไม่',
        message: 'การลบรายการจะเป็นการปรับสถานะว่ายกเลิก และจะไม่ถูกนำไปใช้คำนวนคะแนนที่ระบบสมาชิก',
    })
    setModalConfirmDialogDel(true);
  }

  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
  const [ModalConfirmDialogDel, setModalConfirmDialogDel] = useState(false)
  const [ConfirmMessageModal, setConfirmMessageModal] = useState(initMessageModal)

  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)

  const handleProcessData = (status_id) => {
    setModalConfirmDialog(false);

    
    const sent_data_api = {
        status_id : status_id
    };
    dispatch(Api_Update_service_condition_cpd(ThisId,sent_data_api)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        setSuccessMessageModal({
            headline: 'ลบรายการสำเร็จ',
            message: '',
          })
          setSuccessModal(true)
      }else{
        setFailMessageModal({
            headline : 'ลบรายการไม่สำเร็จ',
            message : '',
        })
        setFailModal(true)
      }     
    })
  }

  return (
    <Div>
        <DivFilter>
          <div className="content-fillter">
            <div className="group_filter">
              <div className="css_filter_name">
                <Field className="field" label="ประเภทบริการ">
                    <Dropdown
                      id={`dd_filter`}
                      className="dd_filter"
                      value={service_type_id}
                      optionList={SERVICE_TYPEOption}
                      onChange={(v) => setservice_type_id(v)}
                      placeHolder={'เลือก'}
                    />
                </Field>
              </div>
              <div className="mr-1-rem">
                <Field className="field" label="สถานะ">
                  <Dropdown
                      id={`dd_filter`}
                      className="dd_filter"
                      value={filterStatus}
                      optionList={StatusOption}
                      onChange={(v) => setfilterStatus(v)}
                      placeHolder={'เลือก'}
                  />
                </Field>
              </div>
              <div className="css_button_filter mr-1-rem">
                  <Button onClick={onSearch} >
                    ค้นหา
                  </Button>
              </div>
              <div className="css_button_filter mr-1-rem">
                <ButtonSecondary onClick={onClearSearch} >
                  ล้างการค้นหา
                </ButtonSecondary>
              </div>
            </div>
            <div className="css_button_add">
              {permission_CpdConditionPoint_Create?.canRead === true && (
                <Button append={<img src={iconPlus} />} onClick={OpenCreateModal} className="button_CreateModal" >
                  สร้างรายการใหม่
                </Button>
              )}
            </div>
          </div>
        </DivFilter>
        <div className="table-wrapper">
          <div className="table-header">
            <div className="group">
              <div className="table-title">จำนวนรายการทั้งหมด</div>
              <div className="table-total">
                {total} รายการ
              </div>
            </div>
          </div>
          <div className="css_magin_top_10">
            <TableV2
              columns={TableColumnData}
              data={TableCellData}
              onStateChange={onChangePaginationAttendeeAttend}
              pageCount={totalPages}
              page={page}
              loading={false}
            />
          </div>
        </div>
        
        <SidePanel isOpen={ModalNew} setIsOpen={setModalNew} width={WidthSidePanel}>
            <NewConditionCPD
              width={WidthSidePanel} 
              onClose={() => setModalNew(false)} 
              onSubmit={() => {
                setModalNew(false)
                onClearSearch()
              }}
            />
        </SidePanel>
        <SidePanel isOpen={ModalEdit} setIsOpen={setModalEdit} width={WidthSidePanel}>
            <EditConditionCPD
              width={WidthSidePanel} 
              onClose={() => setModalEdit(false)} 
              onSubmit={() => {
                setModalEdit(false)
                onClearSearch()
              }}
              ThisId={ThisId}
            />
        </SidePanel>
        <SidePanel isOpen={ModalView} setIsOpen={setModalView} width={WidthSidePanel}>
            <ViewConditionCPD
              width={WidthSidePanel} 
              onClose={() => setModalView(false)} 
              onSubmit={() => {
                setModalView(false)
                setModalEdit(true)
              }}
              ThisId={ThisId}
            />
        </SidePanel>
        <DialogConfirmV2
        open={Boolean(ModalConfirmDialogDel)}
        onClose={() => setModalConfirmDialogDel(false)}
        onNo={() => setModalConfirmDialogDel(false)}
        onSubmit={() => {
          setModalConfirmDialogDel(false)
          handleProcessData(2)
        }}
        icon={warningIcon}
        title={ConfirmMessageModal.headline}
        nameItem={ConfirmMessageModal.message}
        textYes='ยืนยัน'
        textNo='ยกเลิก'
      />

      {/* Dialog */}
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={() => {
          setSuccessModal(false)
          onClearSearch()
        }}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes='ตกลง'
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => {
          setFailModal(false)
          onClearSearch()
        }}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes='ตกลง'
      />
    </Div>
    
  )
}

export default TabConditionCPD
