import React, { useState } from 'react'

import styled from 'styled-components'
import { Box, Stack } from '@mui/material'
import Button from 'components/form/button/Button'
import ButtonOutline from 'components/form/button/ButtonOutline'
import TextField from 'components/form/TextField'

import warningIcon from 'assets/images/warning-icon.png'

import { LiaTimesSolid } from 'react-icons/lia'

const Div = styled.div`
  padding: 24px;

  .button-width {
    flex-grow: 1;
  }

  .heading {
    color: var(--Gray-900);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
  }
  .sub-heading {
    color: var(--Gray-600);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
  }
  .heading-info {
    width: 155px;
    color: var(--Gray-700);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
  }
  .info {
    color: var(--Gray-700);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
  }
  .close-icon {
    cursor: pointer;
    color: var(--Gray-400);
  }
  .w-100 {
    width: 100%;
  }
`

function ConfirmReopenActivityModalContent({ onClose, onSubmit }) {
  const [reason, setReason] = useState('')

  const onChangeReason = (event) => {
    const { value } = event.target
    setReason(value)
  }
  const confirm = () => {
    onSubmit(reason)
  }

  return (
    <Div>
      <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between">
        <Box sx={{ width: 48, height: 48 }}>
          <img src={warningIcon} />
        </Box>
        <LiaTimesSolid size={18} className="close-icon" onClick={onClose} />
      </Stack>

      <Box sx={{ mb: 2 }}>
        <div className="heading">ยืนยันการเปิดกิจกรรมกลับอีกครั้ง</div>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Box sx={{ mb: '6px' }}>
          <div className="sub-heading">กรุณาระบุเหตุผลที่เปิดกลับ</div>
        </Box>
        <TextField className="w-100" placeholder="โปรดระบุ" value={reason} onChange={onChangeReason} />
      </Box>

      <Stack direction="row" spacing={2}>
        <ButtonOutline className="button-width" onClick={onClose}>
          ยกเลิก
        </ButtonOutline>
        <Button className="button-width" onClick={confirm}>
          ยืนยัน
        </Button>
      </Stack>
    </Div>
  )
}

export default ConfirmReopenActivityModalContent
