import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { TimeDisplay2, getAdminUser } from 'utils/common'

import { useDispatch, useSelector } from 'react-redux'
import { selectActivityCPD, selectMaster } from 'redux/selectors'
import {
  getBranchActivityCpd,
  getBranchEngineer,
  getCriteriaCpd,
  getMemberCOE,
  getTypeActivityCpd,
} from 'redux/actions/master'
import { createActivityCpd } from 'redux/actions/activityCpd'

import styled from 'styled-components'
import { Box, Divider, Stack } from '@mui/material'
import HeaderPage from 'components/containers/HeaderPage'
import TabNavigation from 'components/containers/TabNavigation'
import Steppers from 'components/containers/Steppers'
import Card from 'components/common/Card'
import TextField from 'components/form/TextField'
import Dropdown from 'components/form/Dropdown'
import TextArea from 'components/form/TextArea'
import RadioButton from 'components/form/RadioButton'
import TextFieldWithIcon from 'components/form/TextFieldWithIcon'
import UploadFileArray from 'components/upload/UploadFileArray'
import ButtonOutline from 'components/form/button/ButtonOutline'
import Button from 'components/form/button/Button'
import Date from 'components/form/Date'
import Time from 'components/form/Time'
import Modal from 'components/common/Modal'
import ConfirmCreateActivityModalContent from 'components/feature/CpdManage/ConfirmCreateActivityModalContent'
import SuccessModal from 'components/dialog/SuccessModal'
import SwitchButton from 'components/form/SwitchButton'
import DialogFail from '../../components/dialog/DialogFail'
import failIcon from '../../assets/images/fail-icon.png'

import { AiOutlineApartment } from 'react-icons/ai'
import { FiLink, FiPlus, FiTrash2 } from 'react-icons/fi'
import { HiOutlineDuplicate } from 'react-icons/hi'

const Div = styled.div`
  .left-content {
    width: 350px;

    margin-right: 2rem;

    .heading-info {
      color: var(--Gray-700);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
    }
    .sub-heading-info {
      color: var(--Gray-600);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .right-content {
    flex-grow: 1;
  }

  .input-heading {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;

    margin-bottom: 6px;
  }
  .input-sub-heading {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;

    margin-bottom: 6px;
  }
  .card-styled {
    width: 100%;
  }
  .w-100 {
    width: 100%;
  }

  .add-btn {
    color: var(--COE-Main-CI);
    border-color: var(--COE-Main-CI);
  }
  .td-text_center {
    text-align: center;
  }
`
const IconButtonStyled = styled.div`
  color: var(--Gray-700);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
`
const TableDataStyled = styled.table`
  width: 100%;
  border-collapse: collapse;
  .icon {
    cursor: pointer;
  }
  thead {
    background-color: var(--Gray-50);

    border-bottom: 1px solid var(--Gray-200);
    th {
      padding: 13px 24px;
    }
  }

  tbody {
    td {
      padding: 16px 24px;
      border-bottom: 1px solid var(--Gray-200);
    }
  }
`
const SubheadingContent = styled.div`
  color: var(--Gray-600);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
`

const DivErrorMessage = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`

function CreateOrgActivity() {
  // external hook
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)
  const [failModal, setFailModal] = useState(false)

  const fetchActivityTypeCpd = useCallback(() => {
    dispatch(getTypeActivityCpd())
  }, [dispatch])
  const fetchBranchActivityCpd = useCallback(
    (activity_type_no) => {
      dispatch(getBranchActivityCpd(activity_type_no))
    },
    [dispatch]
  )
  const fetchCriteriaCpd = useCallback(
    (activity_number) => {
      dispatch(getCriteriaCpd(activity_number))
    },
    [dispatch]
  )
  const fetchBranchEngineer = useCallback(() => {
    dispatch(getBranchEngineer())
  }, [dispatch])

  useEffect(() => {
    fetchActivityTypeCpd()
    fetchBranchEngineer()
  }, [])

  // initial type activity options
  const { typeActivityCPDOptions } = useSelector(selectMaster)
  const [typeActivityOptions, setTypeActivityOptions] = useState([])

  useEffect(() => {
    const newTypeActivityOptions = typeActivityCPDOptions.map((v) => ({
      text: v.activity_type,
      value: v.activity_type_no,
    }))
    setTypeActivityOptions(newTypeActivityOptions)
  }, [typeActivityCPDOptions])

  // intiail branch activity options
  const { branchActivityCPDOptions } = useSelector(selectMaster)
  const [branchActivityOptions, setBranchActivityOptions] = useState([])
  useEffect(() => {
    const newBranchActivityOptions = branchActivityCPDOptions.map((v) => ({
      text: v.activity_details,
      value: v.activity_number,
    }))
    setBranchActivityOptions(newBranchActivityOptions)
  }, [branchActivityCPDOptions])

  // initial criteria options
  const { criteriaCPDOptions } = useSelector(selectMaster)
  const [criteriaOptions, setCriteriaOptions] = useState([])
  useEffect(() => {
    const newCriteriaOptions = criteriaCPDOptions.map((v) => ({
      text: v.activity_criteria,
      value: v.id,
    }))
    setCriteriaOptions(newCriteriaOptions)
  }, [criteriaCPDOptions])

  // initial branch engineer options
  const { branchEngineerCPDOptions } = useSelector(selectMaster)
  const [branchEngineerOptions, setBranchEngineerOptions] = useState([])
  useEffect(() => {
    const newBranchEngineerOptions = branchEngineerCPDOptions.map((v) => ({
      text: v.name_th_th,
      value: v.id,
    }))
    setBranchEngineerOptions(newBranchEngineerOptions)
  }, [branchEngineerCPDOptions])

  // tab menu
  const tabNavigationOptions = [
    {
      tab: <AiOutlineApartment size={18} />,
      navigateTo: '',
    },
    {
      tab: 'กิจกรรมองค์กรแม่ข่าย',
      navigateTo: '/CpdManage/OrgActivity',
    },
    {
      tab: 'สร้างกิจกรรมองค์กรแม่ข่าย',
      navigateTo: '',
    },
  ]

  // confirm modal
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false)

  const openConfirmModal = () => {
    setIsOpenConfirmModal(true)
  }
  const closeConfirmModal = () => {
    setIsOpenConfirmModal(false)
  }

  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false)

  const openSuccessModal = () => {
    setIsOpenSuccessModal(true)
  }
  const closeSuccessModal = () => {
    setIsOpenSuccessModal(false)
  }
  const onSubmitSuccessModal = () => {
    navigate('/CpdManage/OrgActivity')
    closeSuccessModal()
  }

  // step options
  const initialSteps = [
    {
      text: 'รายละเอียดกิจกรรม',
      step: 1,
    },
    {
      text: 'กำหนดการ วันที่ และ เวลา',
      step: 2,
    },
    {
      text: 'วิทยากร',
      step: 3,
    },
  ]
  const [currentStep, setCurrentStep] = useState(1)
  const [code, setCode] = useState('')

  const [DateEventError, setDateEventError] = useState({
    DateEvent: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุวันที่จัดกิจกรรม',
    },
  })

  const nextStep = () => {
    //alert(currentStep)
    if(currentStep == 1){
      if (isValidTab1().length == 0) {
        setCurrentStep(2);
      }
    }else if(currentStep == 2){
      if(isValidTab2().length == 0){
        if(form.type_date_activity === 'จัดงานวันเดียว'){
          if(form.date_event_oneday && form.timestart_event_oneday && form.timeend_event_oneday){
            if(form.registration_close_at > form.date_event_oneday){
              setFailMessageModal({
                  headline : 'ไม่สามารถทำรายการได้',
                  message : 'วันที่ปิดรับสมัครจะต้องไม่มากกว่าวันที่จัดกิจกรรม',
              })
              setFailModal(true)
            }else{
              setCurrentStep(3);
            }
            
          }else{
            setDateEventError({
              DateEvent: {
                show_errorMessage: true,
                errorMessage: 'กรุณาระบุวันที่จัดกิจกรรม',
              },
            })
          }
        }else{
          if(form.event_date_list.length > 0){
            let check_item = 0;
            for (const event_date_item of form.event_date_list) {
              if(event_date_item.date_event && event_date_item.timestart_event && event_date_item.timeend_event){
                check_item = check_item + 1;
              }
            }
            if(check_item == form.event_date_list.length){
              if(form.registration_close_at > form.event_date_list[0].date_event){
                setFailMessageModal({
                    headline : 'ไม่สามารถทำรายการได้',
                    message : 'วันที่ปิดรับสมัครจะต้องไม่มากกว่าวันที่จัดกิจกรรม',
                })
                setFailModal(true)
              }else{
                setCurrentStep(3);
              }
              
            }else{
              setDateEventError({
                DateEvent: {
                  show_errorMessage: true,
                  errorMessage: 'กรุณาระบุวันที่จัดกิจกรรม',
                },
              })
            }
            
          }else{
            setDateEventError({
              DateEvent: {
                show_errorMessage: true,
                errorMessage: 'กรุณาระบุวันที่จัดกิจกรรม',
              },
            })
          }
        }
        
      }
    }
    //if (currentStep <= initialSteps.length) setCurrentStep((prev) => prev + 1)
  }
  const backStep = () => {
    if (currentStep > 0 && currentStep <= initialSteps.length) setCurrentStep((prev) => prev - 1)
  }
  const saveDraft = () => {}
  const createActivity = async () => {
    const file_list = file.map((v) => ({
      file_name: v.name,
      file_url: v.url,
      file_size: v.file_size,
    }))
    const instructors = form.instructors.map((v) => ({
      full_name: v.full_name,
      event_hours_receiving: Number(v.event_hours_receiving),
      member_id: v.member_id ? Number(v.member_id) : null,
    }))
    const event_date_list = form.event_date_list.map((v) => ({
      date_event: v.date_event,
      timestart_event: TimeDisplay2(v.timestart_event),
      timeend_event: TimeDisplay2(v.timeend_event),
    }))

    const request = {
      ...form,
      date_event_oneday: form.date_event_oneday === '' ? null : form.date_event_oneday,
      timestart_event_oneday: form.timestart_event_oneday === '' ? null : TimeDisplay2(form.timestart_event_oneday),
      timeend_event_oneday: form.timeend_event_oneday === '' ? null : TimeDisplay2(form.timeend_event_oneday),

      event_date_list: event_date_list.length > 0 ? event_date_list : null,
      list_file: file_list.length > 0 ? file_list : null,
      instructors: instructors.length > 0 ? instructors : null,
      // number
      coe_member_attending_fee: form.coe_member_attending_fee === '' ? null : Number(form.coe_member_attending_fee),
      individual_attending_fee: form.individual_attending_fee === '' ? null : Number(form.individual_attending_fee),
      student_attending_fee: form.student_attending_fee === '' ? null : Number(form.student_attending_fee),
      cpd_event_type: form.cpd_event_type === '' ? null : Number(form.cpd_event_type),
      duration_in_hours: form.duration_in_hours === '' ? null : Number(form.duration_in_hours),
      engineering_field: form.engineering_field === '' ? null : Number(form.engineering_field),
      event_field: form.event_field === '' ? null : Number(form.event_field),
      open_attendee_count: form.open_attendee_count === '' ? null : Number(form.open_attendee_count),
    }

    const codeCreate = await dispatch(createActivityCpd(request))

    if (codeCreate.code) setCode(codeCreate.code)

    closeConfirmModal()
  }

  // isLoading,state
  const { isLoading, state } = useSelector(selectActivityCPD)

  useEffect(() => {
    if (state === 'CREATE_ACTIVITY_CPD.SUCCESS' && isLoading === false) {
      openSuccessModal()
    }
  }, [isLoading, state])

  // radio options
  const initialRadioOptionsForTypeCost = [
    {
      text: 'ไม่เสียค่าใช้จ่าย',
      value: true,
    },
    {
      text: 'มีค่าใช้จ่าย',
      value: false,
    },
  ]
  const initialRadioOptionsForTypeDateActivity = [
    {
      text: 'จัดงานวันเดียว',
      value: 'จัดงานวันเดียว',
    },
    {
      text: 'จัดงานหลายวัน',
      value: 'จัดงานหลายวัน',
    },
  ]
  const initialRadioOptionsForTypeActivity = [
    {
      text: 'Online',
      value: 'ออนไลน์',
    },
    {
      text: 'Offline',
      value: 'ออฟไลน์',
    },
    {
      text: 'Online & Offline',
      value: 'ออนไลน์และออฟไลน์',
    },
  ]
  const initialRadioOptionsForTypeContact = [
    {
      text: 'ผู้ทำรายการเป็นผู้ติดต่อ',
      value: 'ผู้ทำรายการเป็นผู้ติดต่อ',
    },
    {
      text: 'บุคคลอื่น',
      value: 'บุคคลอื่น',
    },
  ]

  const user = getAdminUser()
  const initialForm = {
    name: '',
    engineering_field: '',
    cpd_event_type: '',
    event_field: '',
    event_field_lv4: '',
    weight: '',
    duration_in_hours: '',
    brief_description: '',
    description: '',
    coe_member_attending_fee: '',
    individual_attending_fee: '',
    student_attending_fee: '',
    contact_person_name: user.full_name ?? '',
    contact_person_email: user.email ?? '',
    contact_person_phone_no: user.phone_no ?? '',
    registration_open_at: '',
    registration_close_at: '',
    address_extra_info: '',
    status: '1',
    open_attendee_count: '',
    location_type: 'ออนไลน์',
    conference_link: '',
    conference_id: '',
    conference_password: '',
    organization_link: '',
    is_email_confirm:false,
    fee_is_fee: 'true',
    date_event_oneday: '',
    timestart_event_oneday: '',
    timeend_event_oneday: '',
    list_file: [],
    instructors: [],
    event_date_list: [],

    // optional
    type_date_activity: 'จัดงานวันเดียว',
    type_contact: 'ผู้ทำรายการเป็นผู้ติดต่อ',
  }
  const [form, setForm] = useState(initialForm)
  const [formErrorTab1, setformErrorTab1] = useState({
    name: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุชื่อกิจกรรม',
    },
    cpd_event_type: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุประเภทกิจกรรม CPD',
    },
    event_field: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุสาขากิจกรรม CPD',
    },
    event_field_lv4: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุหลักเกณฑ์การนับคะแนน',
    },
    engineering_field: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุสาขาทางวิศวกรรม',
    },
    description: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุรายละเอียดกิจกรรม',
    },
  })

  const [formErrorTab2, setformErrorTab2] = useState({
    registration_open_at: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุวันที่เปิดรับสมัคร',
    },
    registration_close_at: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุวันที่ปิดรับสมัคร',
    },
    open_attendee_count: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุจำนวนที่นั่งที่เปิดรับสมัคร',
    },
    duration_in_hours: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุจำนวนชั่วโมงรวมที่ทำกิจกรรม',
    },
  })

  const isValidTab1 = () => {
    let data_valid = form;
    let data_check_valid = formErrorTab1;
    let errors = []
    Object.keys(formErrorTab1).forEach((k) => {
      let data = data_valid[k]
      if (data_check_valid[k].errorMessage != '') {
        if (data.toString().length == 0) {
          errors.push(data_check_valid[k].errorMessage)
          data_check_valid[k].show_errorMessage = true
        } else {
          data_check_valid[k].show_errorMessage = false
        }
      }
    })
    setformErrorTab1({ ...formErrorTab1 })
    return errors
  }

  const isValidTab2 = () => {
    let data_valid = form;
    let data_check_valid = formErrorTab2;
    let errors = []
    Object.keys(formErrorTab2).forEach((k) => {
      let data = data_valid[k]
      //alert(k + '---' + data)
      if (data_check_valid[k].errorMessage != '') {
        if(k == 'duration_in_hours'){
          if(!data){
            errors.push(data_check_valid[k].errorMessage)
            data_check_valid[k].errorMessage = 'กรุณาระบุจำนวนชั่วโมงรวมที่ทำกิจกรรม'
            data_check_valid[k].show_errorMessage = true
          }else{
            if(data > 0 ){
              data_check_valid[k].show_errorMessage = false
            }else{
              errors.push(data_check_valid[k].errorMessage)
              data_check_valid[k].errorMessage = 'จำนวนชั่วโมงรวมที่ทำกิจกรรมต้องมากกว่า 0'
              data_check_valid[k].show_errorMessage = true
            }
            
          }
        }else{
          if (!data || data < 0) {
            errors.push(data_check_valid[k].errorMessage)
            data_check_valid[k].show_errorMessage = true
          } else {
            data_check_valid[k].show_errorMessage = false
          }
        }

      }
    })
    setformErrorTab2({ ...formErrorTab2 })
    return errors
  }

  const onChangeForm = (name, value) => {
    if (name === 'fee_is_fee') {
      setForm((prev) => ({
        ...prev,
        [name]: value,
        coe_member_attending_fee: value === 'true' ? '' : prev.coe_member_attending_fee,
        individual_attending_fee: value === 'true' ? '' : prev.individual_attending_fee,
        student_attending_fee: value === 'true' ? '' : prev.student_attending_fee,
      }))
    } else if (name === 'is_email_confirm') {
      setForm((prev) => ({
        ...prev,
        [name]: !value,
      }))
    } else if (name === 'location_type') {
      setForm((prev) => ({
        ...prev,
        [name]: value,
        conference_link: value === 'ออฟไลน์' ? '' : prev.conference_link,
        conference_id: value === 'ออฟไลน์' ? '' : prev.conference_id,
        conference_password: value === 'ออฟไลน์' ? '' : prev.conference_password,
        address_extra_info: value === 'ออนไลน์' ? '' : prev.address_extra_info,
      }))
    } else if (name === 'type_contact') {
      setForm((prev) => ({
        ...prev,
        [name]: value,
        contact_person_name: value === 'บุคคลอื่น' ? '' : user.full_name ?? '',
        contact_person_phone_no: value === 'บุคคลอื่น' ? '' : user.phone_no ?? '',
        contact_person_email: value === 'บุคคลอื่น' ? '' : user.email ?? '',
      }))
    } else if (name === 'type_date_activity') {
      if (value === 'จัดงานวันเดียว') {
        setForm((prev) => ({
          ...prev,
          [name]: value,
          event_date_list: [],
        }))
      } else {
        setForm((prev) => ({
          ...prev,
          [name]: value,
          date_event_oneday: '',
          timestart_event_oneday: '',
          timeend_event_oneday: '',
        }))
      }
    } else {
      setForm((prev) => ({
        ...prev,
        [name]: value,
      }))
    }
  }
  const onChangeDropdown = (name, value) => {
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }))

    if (name === 'cpd_event_type') {
      fetchBranchActivityCpd(value)
      setCriteriaOptions([])
    }
    if (name === 'event_field') {
      fetchCriteriaCpd(value)
    }
  }
  const onChangeEventDateList = (key, name, value) => {
    setForm((prev) => ({
      ...prev,
      event_date_list: prev.event_date_list.map((v) => {
        if (v.key === key) return { ...v, [name]: value }
        else return v
      }),
    }))
  }
  const addNewEventDate = () => {
    const newEventDate = {
      key: uuidv4(),
      date_event: '',
      timestart_event: '',
      timeend_event: '',
    }
    setForm((prev) => ({
      ...prev,
      event_date_list: [...prev.event_date_list, newEventDate],
    }))
  }
  const duplicateEventDate = (key) => {
    const matchedEventDate = form.event_date_list.find((v) => v.key === key)
    const { date_event, timestart_event, timeend_event } = matchedEventDate
    const duplicatedEventDate = {
      key: uuidv4(),
      date_event,
      timestart_event,
      timeend_event,
    }
    setForm((prev) => ({
      ...prev,
      event_date_list: [...prev.event_date_list, duplicatedEventDate],
    }))
  }
  const deleteEventDate = (key) => {
    setForm((prev) => ({
      ...prev,
      event_date_list: prev.event_date_list.filter((v) => v.key !== key),
    }))
  }
  const onChangeInstructor = (key, name, value) => {
    setForm((prev) => ({
      ...prev,
      instructors: prev.instructors.map((v) => {
        if (v.key === key) return { ...v, [name]: value }
        else return v
      }),
    }))
  }
  const handleSearchInstructor = async (event, key, name) => {
    if (event.key === 'Enter') {
      const value = event.target.value
      const request = {
        id_card_or_fullname: value,
      }
      const data = await dispatch(getMemberCOE(request))
      if (data) {
        const { member_no,full_name } = data

        setForm((prev) => ({
          ...prev,
          instructors: prev.instructors.map((v) => {
            if (v.key === key) return { ...v, member_id: member_no, full_name : full_name }
            else return v
          }),
        }))
      } else {
        setForm((prev) => ({
          ...prev,
          instructors: prev.instructors.map((v) => {
            if (v.key === key) return { ...v, member_id: null }
            else return v
          }),
        }))
      }
    }
  }

  const handleSearchInstructorClick = async (full_name, key) => {
      const value = full_name;
      const request = {
        id_card_or_fullname: value,
      }
      const data = await dispatch(getMemberCOE(request))
      if (data) {
        const { member_no,full_name } = data

        setForm((prev) => ({
          ...prev,
          instructors: prev.instructors.map((v) => {
            if (v.key === key) return { ...v, member_id: member_no, full_name : full_name }
            else return v
          }),
        }))
      } else {
        setForm((prev) => ({
          ...prev,
          instructors: prev.instructors.map((v) => {
            if (v.key === key) return { ...v, member_id: null }
            else return v
          }),
        }))
      }
  }
  const addNewInstructor = () => {
    const newInstructor = {
      key: uuidv4(),
      full_name: '',
      event_hours_receiving: '',
      member_id: null,
    }
    setForm((prev) => ({
      ...prev,
      instructors: [...prev.instructors, newInstructor],
    }))
  }
  const deleteInstructor = (key) => {
    setForm((prev) => ({
      ...prev,
      instructors: prev.instructors.filter((v) => v.key !== key),
    }))
  }

  // file
  const acceptList = {
    images: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
    },
    files: {
      'text/csv': [],
      'application/pdf': [],
      'application/msword': [],
      'application/vnd.ms-excel': [],
      'application/zip': [],
    },
    videos: {
      'video/x-msvideo': [],
      'video/mp4': [],
      'video/mpeg': [],
      'video/webm': [],
    },
    audios: {
      'audio/mpeg': [],
      'audio/wav': [],
      'audio/webm': [],
    },
  }
  const multiple = false
  const error = false
  const limitFileSize = 100 //--- 100MB
  const [file, setFile] = useState([])
  const [acceptTypes, setAcceptTypes] = useState([])

  const initFileType = () => {
    const acceptType = [].reduce((pre, cur) => {
      return {
        ...pre,
        ...acceptList[cur],
      }
    }, {})
    setAcceptTypes(acceptType)
  }
  const handleAddFile = async (data, index) => {
    setFile((prev) => [...prev, data].filter((v) => v.progress_display == 'OK'))
  }
  const onDeleteFile = (key_file_id) => {
    setFile((prev) => prev.filter((v) => v.key_file_id !== key_file_id))
  }

  useEffect(() => {
    initFileType()
  }, [])

  return (
    <Div>
      <Box sx={{ marginBottom: '20px' }}>
        <TabNavigation options={tabNavigationOptions} />
      </Box>

      <Box sx={{ mb: 2 }}>
        <HeaderPage heading="สร้างกิจกรรมองค์กรแม่ข่าย" subHeading="สภาวิศวกร" />
      </Box>

      <Box sx={{ mb: 5 }}>
        <Steppers className="" steps={initialSteps} activeStep={currentStep} />
      </Box>

      {currentStep === 1 ? (
        <Stack sx={{ mb: '28px' }} direction="row">
          <Box className="left-content">
            <div className="heading-info">ข้อมูลเกี่ยวกับกิจกรรม</div>
            <div className="sub-heading-info">ข้อมูลทั่วไปขององค์กรแม่ข่าย</div>
          </Box>
          <Box className="right-content">
            <Card className="card-styled">
              <Box sx={{ width: '100%', mb: 3 }}>
                <div className="input-heading">ชื่อกิจกรรม*</div>
                <TextField
                  className="w-100"
                  value={form.name}
                  onChange={(event) => onChangeForm('name', event.target.value)}
                  error={Boolean(formErrorTab1.name.show_errorMessage)}
                />
                {formErrorTab1.name.show_errorMessage == true && (
                    <DivErrorMessage>{formErrorTab1.name.errorMessage}</DivErrorMessage>
                )}
              </Box>
              <Box sx={{ width: '100%', mb: 3 }}>
                <div className="input-heading">ประเภทกิจกรรม CPD*</div>
                <Dropdown
                  className="w-100"
                  value={form.cpd_event_type}
                  onChange={(value) => onChangeDropdown('cpd_event_type', value)}
                  optionList={typeActivityOptions}
                  error={Boolean(formErrorTab1.cpd_event_type.show_errorMessage)}
                />
                {formErrorTab1.cpd_event_type.show_errorMessage == true && (
                    <DivErrorMessage>{formErrorTab1.cpd_event_type.errorMessage}</DivErrorMessage>
                )}
              </Box>
              <Box sx={{ width: '100%', mb: 3 }}>
                <div className="input-heading">สาขากิจกรรม CPD*</div>
                <Dropdown
                  className="w-100"
                  value={form.event_field}
                  onChange={(value) => onChangeDropdown('event_field', value)}
                  optionList={branchActivityOptions}
                  error={Boolean(formErrorTab1.event_field.show_errorMessage)}
                />
                {formErrorTab1.event_field.show_errorMessage == true && (
                    <DivErrorMessage>{formErrorTab1.event_field.errorMessage}</DivErrorMessage>
                )}
              </Box>
              <Box sx={{ width: '100%', mb: 3 }}>
                <div className="input-heading">หลักเกณฑ์การนับคะแนน*</div>
                <Dropdown
                  className="w-100"
                  value={form.event_field_lv4}
                  onChange={(value) => onChangeDropdown('event_field_lv4', value)}
                  optionList={criteriaOptions}
                  error={Boolean(formErrorTab1.event_field_lv4.show_errorMessage)}
                />
                {formErrorTab1.event_field_lv4.show_errorMessage == true && (
                    <DivErrorMessage>{formErrorTab1.event_field_lv4.errorMessage}</DivErrorMessage>
                )}
              </Box>
              <Box sx={{ width: '100%', mb: 3 }}>
                <div className="input-heading">สาขาทางวิศวกรรม*</div>
                <Dropdown
                  className="w-100"
                  value={form.engineering_field}
                  onChange={(value) => onChangeDropdown('engineering_field', value)}
                  optionList={branchEngineerOptions}
                  error={Boolean(formErrorTab1.engineering_field.show_errorMessage)}
                />
                {formErrorTab1.engineering_field.show_errorMessage == true && (
                    <DivErrorMessage>{formErrorTab1.engineering_field.errorMessage}</DivErrorMessage>
                )}
              </Box>
              <Box sx={{ width: '100%', mb: 3 }}>
                <div className="input-heading">รายละเอียดกิจกรรม*</div>
                <TextArea
                  value={form.description}
                  onChange={(event) => onChangeForm('description', event.target.value)}
                  placeholder="โปรดระบุ"
                />
                {formErrorTab1.description.show_errorMessage == true && (
                    <DivErrorMessage>{formErrorTab1.description.errorMessage}</DivErrorMessage>
                )}
              </Box>
              <Box sx={{ width: '100%', mb: 3 }}>
                <RadioButton
                  value={form.fee_is_fee}
                  handleChange={(event) => onChangeForm('fee_is_fee', event.target.value)}
                  optionList={initialRadioOptionsForTypeCost}
                />
              </Box>
              <Box sx={{ width: '100%', mb: 3 }}>
                <Stack direction="row" alignItems="flex-end" spacing={1}>
                  <Box sx={{ width: '32%' }}>
                    <div className="input-heading">ค่าธรรมเนียมสำหรับสมาชิกองค์กรแม่ข่าย</div>
                    <TextFieldWithIcon
                      disabled={form.fee_is_fee == 'true'}
                      value={form.coe_member_attending_fee}
                      onChange={(event) => onChangeForm('coe_member_attending_fee', event.target.value)}
                      placeholder="กรุณาระบุ"
                      iconButton={'บาท'}
                    />
                  </Box>
                  <Box sx={{ width: '32%' }}>
                    <div className="input-heading">ค่าธรรมเนียมสำหรับบุคคลทั่วไป</div>
                    <TextFieldWithIcon
                      disabled={form.fee_is_fee == 'true'}
                      value={form.individual_attending_fee}
                      onChange={(event) => onChangeForm('individual_attending_fee', event.target.value)}
                      placeholder="กรุณาระบุ"
                      iconButton={'บาท'}
                    />
                  </Box>
                  <Box sx={{ width: '32%' }}>
                    <div className="input-heading">ค่าธรรมเนียมสำหรับนักเรียน/นักศึกษา</div>
                    <TextFieldWithIcon
                      disabled={form.fee_is_fee == 'true'}
                      value={form.student_attending_fee}
                      onChange={(event) => onChangeForm('student_attending_fee', event.target.value)}
                      placeholder="กรุณาระบุ"
                      iconButton={'บาท'}
                    />
                  </Box>
                </Stack>
              </Box>
              <Box sx={{ width: '100%', mb: 3 }}>
                <div className="input-heading">แนบเอกสารประกอบกิจกรรม</div>
                <div className="input-sub-heading">
                  เช่น ตารางการจัดกิจกรรม หรือ brochure กิจกรรมกรุณาแนบเอกสารเป็นไฟล์นามสกุล .pdf หรือ .jpg หรือ .jpeg
                  หรือ .png (ไม่เกิน 10MB)
                </div>

                <UploadFileArray
                  multiple={multiple}
                  accept={acceptTypes}
                  file={file.map((v) => ({ ...v, url: `${v.url}` }))}
                  onAddFile={handleAddFile}
                  onDeleteFile={onDeleteFile}
                  error={error}
                  disabled={false}
                  FileListData={file}
                  isOneFile={false}
                  limitFileSize={limitFileSize}
                  // indexdata={index}
                  subtext={'SVG, PNG, JPG or GIF (max. 800x400px)'}
                />
              </Box>
              <Box sx={{ width: '100%', mb: 3 }}>
                <div className="input-heading">URL Link รายละเอียดกิจกรรมของแม่ข่าย</div>
                <TextFieldWithIcon
                  value={form.organization_link}
                  onChange={(event) => onChangeForm('organization_link', event.target.value)}
                  placeholder="www.example.com"
                  iconButton={
                    <IconButtonStyled>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <FiLink /> <span>URL</span>
                      </Stack>
                    </IconButtonStyled>
                  }
                />
              </Box>
              <Box sx={{ width: '100%', mb: 3 }}>
                <Stack direction="row" alignItems="flex-end" spacing={1}>
                  <div className="input-heading">การตั้งค่าพิเศษ (เฉพาะสำหรับสภาวิศวกร) : เปิดให้สมาชิก Confirm Email ผ่านระบบ Email Verification</div>
                  <div>
                    <SwitchButton
                      value={true}
                      defaultChecked={form.is_email_confirm}
                      handleChange={(event) => onChangeForm('is_email_confirm', form.is_email_confirm)}
                    />
                  </div>
                </Stack>
              </Box>
            </Card>
          </Box>
        </Stack>
      ) : currentStep === 2 ? (
        <Box sx={{ mb: '28px' }}>
          <Stack sx={{ mb: '20px' }} direction="row">
            <Box className="left-content">
              <div className="heading-info">กำหนดการ</div>
              <div className="sub-heading-info">รายละเอียดวันที่ เวลา</div>
            </Box>
            <Box className="right-content">
              <Card className="card-styled">
                <Box sx={{ width: '100%', mb: 3 }}>
                  <RadioButton
                    optionList={initialRadioOptionsForTypeDateActivity}
                    value={form.type_date_activity}
                    handleChange={(event) => onChangeForm('type_date_activity', event.target.value)}
                  />
                </Box>

                {form.type_date_activity === 'จัดงานวันเดียว' ? (
                  <Box sx={{ width: '100%', mb: 3 }}>
                    <Stack direction="row" spacing={2}>
                      <Box sx={{ width: '32%' }}>
                        <div className="input-heading">วันที่จัดกิจกรรม*</div>
                        <Date
                          className="w-100"
                          value={form.date_event_oneday}
                          onChange={(value) => onChangeForm('date_event_oneday', value)}
                          error={Boolean(DateEventError.DateEvent.show_errorMessage)}
                        />
                        {DateEventError.DateEvent.show_errorMessage == true && (
                          <DivErrorMessage>{DateEventError.DateEvent.errorMessage}</DivErrorMessage>
                        )}
                      </Box>
                      <Box sx={{ width: '32%' }}>
                        <div className="input-heading">เวลาที่เริ่มกิจกรรม*</div>
                        <Time
                          className="w-100"
                          value={form.timestart_event_oneday}
                          onChange={(value) => onChangeForm('timestart_event_oneday', value)}
                        />
                      </Box>
                      <Box sx={{ width: '32%' }}>
                        <div className="input-heading">เวลาที่สิ้นสุดกิจกรรม*</div>
                        <Time
                          className="w-100"
                          value={form.timeend_event_oneday}
                          onChange={(value) => onChangeForm('timeend_event_oneday', value)}
                        />
                      </Box>
                    </Stack>
                  </Box>
                ) : (
                  <>
                    <TableDataStyled>
                      <thead>
                        <tr>
                          <th></th>
                          <th>วันที่จัดกิจกรรม</th>
                          <th>เวลาที่เริ่มกิจกรรม</th>
                          <th>เวลาที่สิ้นสุดกิจกรรม</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {form.event_date_list.map((v, i) => (
                          <tr key={v.key}>
                            <td>{i + 1}</td>
                            <td>
                              <Date
                                className="w-100"
                                value={v.date_event}
                                onChange={(value) => onChangeEventDateList(v.key, 'date_event', value)}
                              />
                            </td>
                            <td>
                              <Time
                                className="w-100"
                                value={v.timestart_event}
                                onChange={(value) => onChangeEventDateList(v.key, 'timestart_event', value)}
                              />
                            </td>
                            <td>
                              <Time
                                className="w-100"
                                value={v.timeend_event}
                                onChange={(value) => onChangeEventDateList(v.key, 'timeend_event', value)}
                              />
                            </td>
                            <td>
                              <Stack direction="row" alignItems="center" spacing={1}>
                                <HiOutlineDuplicate
                                  className="icon"
                                  size={18}
                                  onClick={() => duplicateEventDate(v.key)}
                                />
                                <FiTrash2 className="icon" onClick={() => deleteEventDate(v.key)} />
                              </Stack>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </TableDataStyled>
                    {DateEventError.DateEvent.show_errorMessage == true && (
                      <DivErrorMessage>{DateEventError.DateEvent.errorMessage}</DivErrorMessage>
                    )}
                    <Box sx={{ mt: 3, mb: 3 }}>
                      <ButtonOutline className="add-btn" append={<FiPlus size={20} />} onClick={addNewEventDate}>
                        เพิ่มวันที่และเวลา
                      </ButtonOutline>
                    </Box>
                  </>
                )}

                <Box sx={{ width: '100%', mb: 3 }}>
                  <Stack direction="row" spacing={2}>
                    <Box sx={{ width: '32%' }}>
                      <div className="input-heading">วันที่เปิดรับสมัคร*</div>
                      <Date
                        className="w-100"
                        value={form.registration_open_at}
                        onChange={(value) => onChangeForm('registration_open_at', value)}
                        error={Boolean(formErrorTab2.registration_open_at.show_errorMessage)}
                      />
                      {formErrorTab2.registration_open_at.show_errorMessage == true && (
                          <DivErrorMessage>{formErrorTab2.registration_open_at.errorMessage}</DivErrorMessage>
                      )}
                    </Box>
                    <Box sx={{ width: '32%' }}>
                      <div className="input-heading">วันที่ปิดรับสมัคร*</div>
                      <Date
                        className="w-100"
                        value={form.registration_close_at}
                        onChange={(value) => onChangeForm('registration_close_at', value)}
                        error={Boolean(formErrorTab2.registration_close_at.show_errorMessage)}
                      />
                      {formErrorTab2.registration_close_at.show_errorMessage == true && (
                          <DivErrorMessage>{formErrorTab2.registration_close_at.errorMessage}</DivErrorMessage>
                      )}
                    </Box>
                  </Stack>
                </Box>
                <Box sx={{ width: '100%', mb: 3 }}>
                  <Stack direction="row" spacing={2}>
                    <Box sx={{ width: '32%' }}>
                      <div className="input-heading">จำนวนที่นั่งที่เปิดรับสมัคร*</div>
                      <TextField
                        className="w-100"
                        value={form.open_attendee_count}
                        onChange={(event) => onChangeForm('open_attendee_count', event.target.value)}
                        error={Boolean(formErrorTab2.open_attendee_count.show_errorMessage)}
                      />
                      {formErrorTab2.open_attendee_count.show_errorMessage == true && (
                          <DivErrorMessage>{formErrorTab2.open_attendee_count.errorMessage}</DivErrorMessage>
                      )}
                    </Box>
                    <Box sx={{ width: '32%' }}>
                      <div className="input-heading">จำนวนชั่วโมงรวมที่ทำกิจกรรม*</div>
                      <TextField
                        className="w-100"
                        value={form.duration_in_hours}
                        onChange={(event) => onChangeForm('duration_in_hours', event.target.value)}
                        error={Boolean(formErrorTab2.duration_in_hours.show_errorMessage)}
                      />
                      {formErrorTab2.duration_in_hours.show_errorMessage == true && (
                          <DivErrorMessage>{formErrorTab2.duration_in_hours.errorMessage}</DivErrorMessage>
                      )}
                    </Box>
                  </Stack>
                </Box>
              </Card>
            </Box>
          </Stack>

          <Divider sx={{ mb: '40px' }} />

          <Stack sx={{ mb: '20px' }} direction="row">
            <Box className="left-content">
              <div className="heading-info">สถานที่จัดกิจกรรม</div>
              <div className="sub-heading-info">กรุณาระบุข้อมูลเพื่อการประสานงาน</div>
            </Box>
            <Box className="right-content">
              <Card className="card-styled">
                <Box sx={{ width: '100%', mb: 3 }}>
                  <RadioButton
                    optionList={initialRadioOptionsForTypeActivity}
                    value={form.location_type}
                    handleChange={(event) => onChangeForm('location_type', event.target.value)}
                  />
                </Box>

                {(form.location_type === 'ออนไลน์' || form.location_type === 'ออนไลน์และออฟไลน์') && (
                  <Box sx={{ width: '100%', mb: 3 }}>
                    <div className="input-heading">Link สำหรับประชุมออนไลน์</div>
                    <TextField
                      className="w-100"
                      value={form.conference_link}
                      onChange={(event) => onChangeForm('conference_link', event.target.value)}
                    />
                  </Box>
                )}

                {(form.location_type === 'ออฟไลน์' || form.location_type === 'ออนไลน์และออฟไลน์') && (
                  <Box sx={{ width: '100%', mb: 3 }}>
                    <div className="input-heading">รายละเอียดสถานที่จัดกิจกรรม</div>
                    <TextArea
                      value={form.address_extra_info}
                      onChange={(event) => onChangeForm('address_extra_info', event.target.value)}
                      placeholder="โปรดระบุ"
                    />
                  </Box>
                )}

                {(form.location_type === 'ออนไลน์' || form.location_type === 'ออนไลน์และออฟไลน์') && (
                  <Box sx={{ width: '100%', mb: 3 }}>
                    <Stack direction="row" spacing={2}>
                      <Box sx={{ width: '49%' }}>
                        <div className="input-heading">Username/ID</div>
                        <TextField
                          className="w-100"
                          value={form.conference_id}
                          onChange={(event) => onChangeForm('conference_id', event.target.value)}
                        />
                      </Box>
                      <Box sx={{ width: '49%' }}>
                        <div className="input-heading">Password</div>
                        <TextField
                          // type="password"
                          className="w-100"
                          value={form.conference_password}
                          onChange={(event) => onChangeForm('conference_password', event.target.value)}
                        />
                      </Box>
                    </Stack>
                  </Box>
                )}
              </Card>
            </Box>
          </Stack>
        </Box>
      ) : (
        <Box sx={{ mb: '28px' }}>
          <Stack sx={{ mb: '20px' }} direction="row">
            <Box className="left-content">
              <div className="heading-info">ข้อมูลเกี่ยวกับวิทยากร</div>
              <div className="sub-heading-info">
                กรุณาระบุข้อมูลวิทยากร วิทยากรที่เป็นสมาชิกสภาวิศวกรจะได้รับคะแนน CPD เพิ่มเติม
              </div>
            </Box>
            <Box className="right-content">
              <Card className="card-styled">
                <TableDataStyled>
                  <thead>
                    <tr>
                      <th>ลำดับ</th>
                      <th>ชื่อ - นามสกุล</th>
                      <th></th>
                      <th>เลขที่สมาชิกสภาวิศวกร</th>
                      <th>จำนวนชั่วโมงที่อบรม</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {form.instructors.map((v, i) => (
                      <tr key={v.key}>
                        <td>{i + 1}</td>
                        <td>
                          <TextField
                            className="w-100"
                            value={v.full_name}
                            onChange={(event) => onChangeInstructor(v.key, 'full_name', event.target.value)}
                            
                          />
                        </td>
                        <td>
                          <Button onClick={() => handleSearchInstructorClick(v.full_name, v.key)}>ตรวจสอบ</Button>
                        </td>
                        <td className="td-text_center">{v?.member_id ?? '-'}</td>
                        <td>
                          <TextField
                            className="w-100"
                            value={v.event_hours_receiving}
                            onChange={(event) => onChangeInstructor(v.key, 'event_hours_receiving', event.target.value)}
                          />
                        </td>
                        <td>
                          <FiTrash2 className="icon" onClick={() => deleteInstructor(v.key)} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </TableDataStyled>

                <Box sx={{ mt: 3 }}>
                  <ButtonOutline className="add-btn" append={<FiPlus size={20} />} onClick={addNewInstructor}>
                    เพิ่มวิทยากร
                  </ButtonOutline>
                </Box>
              </Card>
            </Box>
          </Stack>

          <Divider sx={{ mb: '40px' }} />

          <Stack sx={{ mb: '20px' }} direction="row">
            <Box className="left-content">
              <div className="heading-info">รายละเอียดผู้ติดต่อ</div>
              <div className="sub-heading-info">กรุณาระบุข้อมูลเพื่อการประสานงาน</div>
            </Box>
            <Box className="right-content">
              <Card className="card-styled">
                <Box sx={{ width: '100%', mb: 3 }}>
                  <RadioButton
                    optionList={initialRadioOptionsForTypeContact}
                    value={form.type_contact}
                    handleChange={(event) => onChangeForm('type_contact', event.target.value)}
                  />
                </Box>

                <Box sx={{ width: '100%', mb: 3 }}>
                  <div className="input-heading">ชื่อ - นามสกุล</div>
                  <TextField
                    className="w-100"
                    value={form.contact_person_name}
                    onChange={(event) => onChangeForm('contact_person_name', event.target.value)}
                    disabled={form.type_contact === 'ผู้ทำรายการเป็นผู้ติดต่อ'}
                  />
                </Box>
                <Box sx={{ width: '100%', mb: 3 }}>
                  <Stack direction="row" spacing={2}>
                    <Box sx={{ width: '49%' }}>
                      <div className="input-heading">เบอร์โทรศัพท์ที่ติดต่อได้</div>
                      <TextField
                        className="w-100"
                        value={form.contact_person_phone_no}
                        onChange={(event) => onChangeForm('contact_person_phone_no', event.target.value)}
                      />
                    </Box>
                    <Box sx={{ width: '49%' }}>
                      <div className="input-heading">Email ที่ติดต่อได้</div>
                      <TextField
                        className="w-100"
                        value={form.contact_person_email}
                        onChange={(event) => onChangeForm('contact_person_email', event.target.value)}
                        disabled={form.type_contact === 'ผู้ทำรายการเป็นผู้ติดต่อ'}
                      />
                    </Box>
                  </Stack>
                </Box>
              </Card>
            </Box>
          </Stack>
        </Box>
      )}

      <Divider sx={{ mb: '26px' }} />

      <Stack direction="row" justifyContent="space-between">
        <ButtonOutline onClick={saveDraft}>บันทึกร่าง</ButtonOutline>

        <Stack direction="row" spacing={2}>
          {currentStep !== 1 && <ButtonOutline onClick={backStep}>กลับ</ButtonOutline>}

          {currentStep === initialSteps.length ? (
            <Button onClick={openConfirmModal}>ยืนยันการสร้างกิจกรรม</Button>
          ) : (
            <Button onClick={nextStep}>ถัดไป</Button>
          )}
        </Stack>
      </Stack>

      {/* Modal */}
      <Modal open={isOpenConfirmModal} onClose={closeConfirmModal} disableBackdropClick width={'688px'}>
        <ConfirmCreateActivityModalContent
          form={form}
          branchActivityOptions={branchActivityOptions}
          onSubmit={createActivity}
          onClose={closeConfirmModal}
        />
      </Modal>
      <Modal open={isOpenSuccessModal} onClose={onSubmitSuccessModal} disableBackdropClick width={'400px'}>
        <SuccessModal
          heading="สร้างกิจกรรมสำเร็จ"
          subHeading={
            <SubheadingContent>
              <Box sx={{ mb: 2 }}>รหัสกิจกรรม : {code}</Box>
              <Box>สามารถรับ QR Code เพื่อลงทะเบียนที่หน้าตารางกิจกรรม</Box>
            </SubheadingContent>
          }
          textYes={'ตกลง'}
          onSubmit={onSubmitSuccessModal}
          onClose={onSubmitSuccessModal}
        />
      </Modal>
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => {
          setFailModal(false)
        }}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes='ตกลง'
      />
    </Div>
  )
}

export default CreateOrgActivity
