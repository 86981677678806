import { getAPI, postAPI,patchAPI } from '../../utils/api'
import { 
    GET_APPEAL,
    POST_APPEAL,
    UPDATE_APPEAL
} from '../actionTypes'
import { showLoading, hideLoading } from './loading'

export const Api_org_num_status_appeal = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_APPEAL.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/appeal/cms_num_status_appeal`,
      data,
      headers: headers,
    })
    
    return { type: 'Api_org_num_status_appeal_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'Api_org_num_status_appeal_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGetVWAppeal = (filter = { limit: 10, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_APPEAL.REQUEST })

    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
    }

    if(filter.status_id){
      params = {...params,'status_id': filter.status_id}
    }

    if(filter.name){
      params = {...params,'$or[0][user_no][$like]': `${filter.name}` ,'$or[1][activity_name][$like]': `%${filter.name}%`,'$or[2][event_code]': filter.name }
    }

    if(filter.date){
      params = {...params,'createdAt': filter.date }
    }

    const data_result = await getAPI({
      url: '/api/appeal/CMS_vw_appeal?$sort[id]=-1',
      headers: headers,
      params,
    })

    return { type: 'ApiGetVWAppeal_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetVWAppeal_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiAppealInfo = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_APPEAL.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/appeal/CMSAppealInfo`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiAppealInfo_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiAppealInfo_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiProcessAppeal = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_APPEAL.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/appeal/CMSProcessAppeal`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiProcessAppeal_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiProcessAppeal_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}
