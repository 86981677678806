import React from 'react'

import styled from 'styled-components'
import { Box, Stack } from '@mui/material'
import Badge from './Badge'

const TabMenuContainer = styled.div`
  .menu {
    font-size: 0.875rem;
    color: var(--Gray-500);

    padding: 0 4px 12px;

    &:hover {
      cursor: pointer;
    }

    &.active {
      color: var(--COE-Main-CI);
      font-style: normal;
      font-weight: 600;

      border-bottom: 2px solid var(--COE-Main-CI);
    }
  }

  .badge-style {
    padding: 0 8px;
    border-radius: 16px;
    border-color: var(--Gray-200);
    background-color: var(--Gray-50);
    color: var(--Gray-700);
    &.active {
      border-color: var(--Error-200);
      background-color: var(--Base-White);
      color: var(--COE-Main-CI);
    }
  }
`

function TabGroupMenu({ tabMenuOptions, tabMenu, setTabMenu }) {
  return (
    <TabMenuContainer>
      <Stack direction="row" alignItems="center" spacing={2}>
        {tabMenuOptions.map((v) => (
          <Box>
            <div className={`menu ${tabMenu === v.value ? 'active' : ''}`} onClick={() => setTabMenu(v.value)}>
              {v.text}{' '}
              {v.number && <Badge className={`badge-style ${tabMenu === v.value ? 'active' : ''}`}>{v.number}</Badge>}
            </div>
          </Box>
        ))}
      </Stack>
    </TabMenuContainer>
  )
}

export default TabGroupMenu
