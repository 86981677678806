import 'quill/dist/quill.snow.css'
import { useEffect, useRef, useState } from 'react'
import { useQuill } from 'react-quilljs'
import styled from 'styled-components'
import ReactQuill from 'react-quill'

export default function TextEditor({ onBlur, placeHolder, initValue, className }) {
  const [ isSetInitValue, setIsSetInitValue ] = useState(false)
  const theme = 'snow'
  const modules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote'],
        ['link'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ header: 2 }],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        ['clean'],
    ],
    clipboard : { matchVisual: false }
  }
  const placeholder = placeHolder || 'Type here...'
  const formats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'underline',
    'blockquote',
    'header',
    'list',
    'align',
    'direction',
    'code-block',
    'image',
    'video',
    'svg',
    'mathjax',
    'script',
  ]
  const { quill, quillRef } = useQuill({
    theme,
    formats,
    modules,
    placeholder,
  })

  useEffect(() => {
    if (quill) {
      if(initValue) {
        console.log('HIHIHIHIH');
        console.log(initValue);
        quill.root.innerHTML = initValue;
        setIsSetInitValue(true)
      }
    }
  }, [quill, initValue])

  return (
    <Styled className={className}>
      <div
        className={'main_container'}
        /*
        onBlur={() => {
          if (onBlur && quill) {
            onBlur({
              delta: quill.getContents()?.ops || [],
              html: quill.root.innerHTML,
            })
          }
        }}
        */
      >
        {
            /*
            <div ref={quillRef} />
            */
        }
        

        <ReactQuill
            theme="snow"
            modules={modules}
            formats={formats}
            placeholder={placeHolder}
            value={initValue}
            onChange={(e) => onBlur({ html : e})}
        />
      </div>
    </Styled>
  )
}

const Styled = styled.div`
  min-height: 140px;
  .main_container {
    color: #fff;
    width: 100%;
    /*
    padding-bottom: 20px;
    max-width: 960px;
    */
    margin: 0 auto;
    font-family: 'Inter','Noto Sans Thai';

    small {
      display: block;
      /*font-size: 18px;*/
      padding-top: 20px;
      padding-bottom: 10px;
      i {
        /*font-size: 12px;*/
        font-style: normal;
      }
    }

    .ql-toolbar.ql-snow {
      background-color: #fff;
    }

    .ql-editor {
      background-color: #fff;
      color: #444;
      font-size: 18px;
      h2{
        font-size: 20px;
      }
    }
    .ql-container {
      /*font-size: 16px;*/
    }
  }
`
