import styled from 'styled-components'
import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {  DateDisplayDDMMBBBHHmm } from '../../utils/common'
import { ApiGetVWUserHistoryPointLog } from '../../redux/actions/events'

import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'
import DialogConfirmV2 from '../../components/dialog/DialogConfirmV2'
import TextField from '../../components/form/TextField'
import Dropdown from '../../components/form/Dropdown'
import Field from '../../components/form/Field'
import InputTextArea from '../../components/widget/element/InputTextArea'
import TableV2 from '../../components/common/TableV2'

import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { IoMdClose } from 'react-icons/io'
import { GrStatusGoodSmall } from 'react-icons/gr'

const Div = styled.div`
    padding: 24px 0px;
    min-width: 800px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color : #101828;
    .header_confirm{
        display: flex;
        justify-content: space-between;
        padding: 0px 24px;
    }
    .modal_text_bold{
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        color : #101828;
    }
    .modal_text_normal{
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color : #475467;
    }
    .border_header {
        border-bottom: 1px solid rgb(234, 236, 240);
    }
    .css_magin_top_16{
        margin-top: 16px;
    }
    .icon {
        cursor: pointer;
    }
    .box_content{
        padding: 0px 24px;
    }
    .group_status{
        display: flex;
        gap: 10px;
    }
    .box_engineer{
        .status-badge {
          width: fit-content;
          padding: 2px 6px;
          text-align: center;
          font-weight: 500;
          border-radius: 16px;
          color: #C11574;
          background: #FDF2FA;
          border: 1px #FCCEEE solid;
          .status {
            font-size: 0.6rem;
            margin-right: 0.2rem;
            color: #C11574;    
          }
        }
    }

    .status-badge-2 {
        width: fit-content;
        padding: 2px 6px;
        text-align: center;
        font-weight: 500;
        border-radius: 16px;
        color: #475467;
        background: #f5f5f4;
        border: 1px #e7e5e4 solid;
      
        .status {
          font-size: 0.6rem;
          margin-right: 0.2rem;
          color: #475467;
        }
    }
`

const ViewPointLog = ({ onClose, onSubmit,ObjPoint,...props  }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user_activity_point_id = ObjPoint.id;

    const limit_data = 10;
    const [total, settotal] = useState(0)
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [TableCellData, setTableCellData] = useState([])
    const TableColumnData = [
      {
          Header: 'ลำดับ',
          accessor: 'no',
          disableSortBy: false,
      },
      {
          Header: 'วันทีทำรายการ',
          accessor: 'createdAt',
          disableSortBy: false,
      },
      {
        Header: 'รายการ',
        accessor: 'log_detail',
        disableSortBy: false,
      },
      {
        Header: 'คะแนนที่ได้รับ',
        accessor: 'user_point',
        disableSortBy: false,
      },
      {
        Header: 'สถานะ',
        accessor: 'status_name',
        disableSortBy: false,
      },
    ]

    const fetchData = useCallback(
        async (filter) => {
    
          dispatch(ApiGetVWUserHistoryPointLog(filter)).then(({ type,data_info }) => {
            if(type.endsWith('_SUCCESS')){
              if (data_info.total > 0) {
                const totalPage = Math.ceil(data_info.total / limit_data)
                settotal(data_info.total)
                setTotalPages(totalPage)
    
                const newTableCellData = data_info.data.map((v, index) => ({
                  no: limit_data * page - (9 - index),
                  createdAt: v.createdAt ? DateDisplayDDMMBBBHHmm(v.createdAt) : '-',
                  log_detail: v.log_detail ? v.log_detail : '-',  
                  user_point: v.user_point ? v.user_point : '-',  
                  status_name: (
                    v.status_name ? 
                    <div
                      className="status-badge-2"
                      style={{
                        borderColor: v.status_name_badge_color?.border,
                        backgroundColor: v.status_name_badge_color?.background,
                      }}
                    >
                      <GrStatusGoodSmall 
                        className="status"
                        style={{
                          color: v.status_name_badge_color?.color,
                        }}
                      />
                      {v.status_name}
                    </div>
                    : 
                    <div >-</div>
                  ),
                }))
                setTableCellData(newTableCellData)
              }else{
                settotal(0);
                setPage(0);
                setTotalPages(0);
                setTableCellData([]);
              }
             
            }
          })
    
        },
        [dispatch]
      )

    useEffect(() => {
        const filter = {
          skip: 0,
          limit: limit_data,
          user_activity_point_id : user_activity_point_id
        }
        fetchData(filter) //-- ปิด รอ API
    }, [fetchData, limit_data])

    const onChangePaginationAttendeeAttend = async (value) => {
        const filter = {
          skip: (value.page  - 1) * limit_data, 
          limit: limit_data,
          user_activity_point_id : user_activity_point_id
        }
        fetchData(filter)
        setPage(value.page)
      }

    return (
        <Div {...props}>
            <div className="header_confirm">
                <div>
                    <div className="modal_text_bold">
                        ประวัติการได้รับคะแนน
                    </div>
                    <div className="modal_text_normal">
                        คะแนนของคุณอาจถูกปรับปรุงจากองค์กรแม่ข่าย หรือจากการตรวจสอบของเจ้าหน้าที่
                    </div>
                </div>
                <div>
                    <IoMdClose size={20} className="icon" onClick={onClose} />
                </div>
            </div>
            <div className="css_magin_top_16"></div>
            <div className="border_header"></div>
            <div className="css_magin_top_16"></div>
            <div className="box_content">
                <div className="group_status">
                    <div className="box_engineer">
                        <div className="status-badge">
                            {ObjPoint.ref_activity}
                        </div>
                    </div>
                    <div className="modal_text_bold">
                        {ObjPoint.activity_name}
                    </div>
                </div>
                <div className="css_magin_top_16"></div>
                <div>
                    <TableV2
                        columns={TableColumnData}
                        data={TableCellData}
                        onStateChange={onChangePaginationAttendeeAttend}
                        pageCount={totalPages}
                        page={page}
                        loading={false}
                    />
                </div>
            </div>
        </Div>
    )
}

export default ViewPointLog