import React from 'react'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components'
import { Box, Stack } from '@mui/material'

import { MdKeyboardArrowRight } from 'react-icons/md'

const Div = styled.div`
  .navigation-tab {
    &:hover {
      cursor: pointer;
    }
    &.highlight {
      color: var(--Gray-700);
      font-style: normal;
      font-weight: 600;
    }
  }

  .icon {
    color: var(--Gray-300);
  }
`

function TabNavigation({ options }) {
  // external hook
  const navigate = useNavigate()

  const navigateTo = (url) => {
    if (url !== '') navigate(url)
  }

  return (
    <Div>
      <Stack direction="row" spacing={2} alignItems="center">
        {options.map((v, i) => (
          <React.Fragment key={i}>
            <Box
              className={`navigation-tab ${i == options.length - 1 ? 'highlight' : ''}`}
              onClick={() => navigateTo(v.navigateTo)}
            >
              {v.tab}
            </Box>
            {i !== options.length - 1 && <MdKeyboardArrowRight size={20} className="icon" />}
          </React.Fragment>
        ))}
      </Stack>
    </Div>
  )
}

export default TabNavigation
