import { useCallback, useEffect, useState,useRef  } from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import {  getAdminUser } from '../../utils/common'
import { GET_organization_profile,ApiGetvw_orgnization_request } from '../../redux/actions/organizations'

import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import Field from '../../components/form/Field'
import TextField from '../../components/form/TextField'
import Dropdown from '../../components/form/Dropdown'
import UploadFileArray from '../../components/upload/UploadFileArray'
import TextEditorV2 from '../../components/widget/element/TextEditorV2'

import iconPlus from '../../assets/images/icon-plus.svg'
import { FaBullseye } from 'react-icons/fa'
import { GrStatusGoodSmall } from 'react-icons/gr'
import { GoPlus } from "react-icons/go";
import { IoIosLink } from "react-icons/io";
import iconDelete from '../../assets/images/bin_border.png'
import iconDuplicate from '../../assets/images/duplicate_border.png'
import iconGroupDrag from '../../assets/images/group_border.png'

import { TbRuler } from 'react-icons/tb'
import { TfiText } from "react-icons/tfi";
import { CiImageOn,CiYoutube  } from "react-icons/ci";
import { FaRegFileLines } from "react-icons/fa6";
import { RiLinkM } from "react-icons/ri";

const Div = styled.div`
.border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
}
.css_magin_top_24 {
    margin-top: 24px;
}
.css_magin_top_16 {
    margin-top: 16px;
  }
  .css_type_content{
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }
  .css_content_text{
    display: grid;
    grid-template-columns: repeat(3, 5% [ col-start ] 70% [col-middle] 10% [ col-end ]);
    gap: 10px;
    .input_css{
        width: 100%;
    }
  }
  .css_content_img{
    display: grid;
    grid-template-columns: repeat(3, 5% [ col-start ] 70% [col-middle] 10% [ col-end ]);
    gap: 10px;
    .input_css{
        width: 100%;
    }
    .input_css_upload{
        width: 50%;
    }
  }
  .css_content_pdf{
    display: grid;
    grid-template-columns: repeat(3, 5% [ col-start ] 70% [col-middle] 10% [ col-end ]);
    gap: 10px;
    .input_css{
        width: 100%;
    }
    .input_css_upload{
        width: 50%;
    }
  }
  .css_content_vdo{
    display: grid;
    grid-template-columns: repeat(3, 5% [ col-start ] 70% [col-middle] 10% [ col-end ]);
    gap: 10px;
    .input_css{
        width: 100%;
    }
  }
  .css_content_url{
    display: grid;
    grid-template-columns: repeat(3, 5% [ col-start ] 70% [col-middle] 10% [ col-end ]);
    gap: 10px;
    .input_css{
        width: 100%;
    }
    .css_list_url{
        display: grid;
        grid-template-columns: repeat(2, 49% [ col-start ] 49% [ col-end ]);
        gap: 2%;
        margin-bottom: 16px;
    }
  }

  .icon-header {
    font-size: 24px;
    vertical-align: sub;
    margin-right: 8px;
  }
  .button_add{
    margin-top: 10px;
    color: var(--Base-Font-Focus-Color);
  }
  .main_box_button{
    display: flex;
    gap: 10px;
  }
  .css_cursor_pointer{
    cursor: pointer;
  }
  /*--- ST text-editor --- */
  .text-editor {
    min-height: 130px;
  }
  .ql-container {
    min-height: 100px; 
    font-family: inherit;
  }
  /*--- ED text-editor --- */
  
  /*--- ST เพิ่มเนื้อหา --- */
  .dropdown_none{
    display : none;
  }
  .dropdown {
    /*
    position: absolute;
    top: 100%;
    right: 0px;
    width: 200px;
    z-index: 2;
    */
    width: 200px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    /*box-shadow: rgba(0, 0, 0, 0.14) 0px 16px 24px 2px;*/
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    font-size: 14px;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    padding: 8px 12px;
  }
  
  li:hover {
    cursor: pointer;
    background-color: #F9FAFB;
    border-radius: 8px;
  }
  /*--- ED เพิ่มเนื้อหา --- */
`



const ContentElerning = ({ DataContent,setDataContent,...props }) => {
  const dispatch = useDispatch()

  const [isDivCreate, setisDivCreate] = useState(false)
  /* ST ทำให้ auto ไป div สุกท้าย */
  const [isAutomove, setisAutomove] = useState(false)
  const LastdivRef = useRef(null)
  
  useEffect(() => {
    if(isAutomove){
        LastdivRef.current.scrollIntoView({ behavior: 'smooth' });
        //LastdivRef.current.scrollIntoView();
        setisAutomove(false);
    }

  });
  
  /* ED ทำให้ auto ไป div สุกท้าย */

  const onAddContent = () => {
    setisDivCreate(isDivCreate ? false : true);
    setisAutomove(true);
  }

  const onChangeDataContent = (index_item, e, type_data) => {
    if(type_data == 'texteditor_content'){
        setDataContent((prevList) => {
            const newList = [...prevList];
            newList[index_item].content = e.html;
            return newList;
        });
    }else if(type_data == 'content'){
        setDataContent((prevList) => {
            const newList = [...prevList];
            newList[index_item].content =e.target.value;
            return newList;
        });
    }else if(type_data == 'sub_content'){
        setDataContent((prevList) => {
            const newList = [...prevList];
            newList[index_item].sub_content =e.target.value ;
            return newList;
        });
    }else if(type_data == 'head_content'){
        setDataContent((prevList) => {
            const newList = [...prevList];
            newList[index_item].head_content =e.target.value;
            return newList;
        });
    }
    /*
    const newState = DataContent.map((obj, index) => {
      if (index === index_item) {
        if (type_data == 'content') {
            return { ...obj, content: e.target.value  }
        }else if(type_data == 'sub_content'){
            return { ...obj, sub_content: e.target.value  }
        }else if(type_data == 'texteditor_content'){
            return { ...obj, content: e.html  }
        }else if(type_data == 'head_content'){
            return { ...obj, head_content: e.target.value  }
        }
      }
      return obj
    })
    setDataContent(newState)
    */
  }

  const onChangeDataContentSub = (index_item,index_sub_item, e, type_data) => {
    const newState = DataContent.map((obj, index) => {
        if (index === index_item) {
            const newState_sub = obj.url_list.map((obj_sub, index_sub) => {
                if (index_sub === index_sub_item) {
                    if (type_data == 'link_name') {
                        return { ...obj_sub, link_name: e.target.value  }
                    }else if(type_data == 'link_url'){
                        return { ...obj_sub, link_url: e.target.value  }
                    }
                }
                return obj_sub
            })  
            return { ...obj, url_list: newState_sub  }            
        }
        return obj
      })
      //console.log(JSON.stringify(newState))
      setDataContent(newState)
  }

      //--- ST UPLOAD FILE
      const [FileInfoList, setFileInfoList] = useState([])
      const limitFileSize = 100; //--- 100MB
      const multiple = true;
      const error = false;
      const disabled = false;
      const allowedTypes = [];
      const acceptType = allowedTypes.reduce((pre, cur) => {
        return {
          ...pre,
          ...acceptList[cur],
        }
      }, {})
      const handleAddFile = async (ObjData,index_item) => {
        const newState = DataContent?.map((obj, index) => {
            if (index === index_item) {
                return { ...obj, FileInfoList : [...obj.FileInfoList,ObjData] }
            }
            return obj
        })
        setDataContent(newState)

      }
      const handleDeleteFile = (key_file_id,index_item) => {
        const newState = DataContent?.map((obj, index) => {
            if (index === index_item) {
                return { ...obj, FileInfoList : obj.FileInfoList.filter((v) => v.key_file_id !== key_file_id) }
            }
            return obj
        })
        setDataContent(newState)
      }

      const RemoveData = (index_item) => {
        const currentListCopy = [...DataContent]
        currentListCopy.splice(index_item, 1)
        setDataContent(currentListCopy)
      }
    
      const DuplicateData = (index_item) => {

        const newStateData = [...DataContent, DataContent[index_item]]

        setDataContent(newStateData)

      }

      const AddContent = (type_content) => {
        setisDivCreate(false);
        let newDataElement = {
            type_content : type_content,
            head_content : null,
            content : null,
            sub_content : null,
        }
        if(type_content == 'img' || type_content == 'pdf' ){
            newDataElement = {
                type_content : type_content,
                head_content : null,
                content : null,
                sub_content : null,
                FileInfoList : [],
            }
        }else if(type_content == 'url'){
            newDataElement = {
                type_content : type_content,
                head_content : null,
                content : null,
                sub_content : null,
                url_list : [
                    {
                        link_name : null,
                        link_url : null,
                    },
                ],
            }
        }

    
        const newStateData = [...DataContent, newDataElement]
    
        setDataContent(newStateData)
     }

     const AddContentURL = (index_item) => {
        const newDataElement = {
            link_name : null,
            link_url : null,
        };
        const newState = DataContent.map((obj, index) => {
            if (index === index_item) {
                const newStateData = [...obj.url_list, newDataElement]
                return { ...obj, url_list: newStateData  }
            }
            return obj
        })
        //console.log(JSON.stringify(newState));
        setDataContent(newState)
    }

      //---- Function Move
    const handleOnDragEnd = (result) => {
        if (!result.destination) return
        let _DataContent = [...DataContent]
        const [reorderedItem] = _DataContent.splice(result.source.index, 1)
        _DataContent.splice(result.destination.index, 0, reorderedItem)
        setDataContent(_DataContent)
    }


  return (
    <Div>
        <DragDropContext onDragEnd={handleOnDragEnd}>   
            <Droppable droppableId="droppable">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        {DataContent?.map((item, index) => (
                            <Draggable key={`item-${index}`} draggableId={`item-${index}`} index={index}>      
                                {(provided) => (       
                                    <div {...provided.draggableProps} ref={provided.innerRef}>
                                        <div className="css_magin_top_24"></div>
                                        
                                        {item.type_content == 'text' && (
                                            <div className="css_content_text">
                                                <div className="css_type_content">
                                                    ข้อความ
                                                </div>
                                                <div>
                                                    <div className="input_css">
                                                        <TextEditorV2
                                                            className="text-editor"
                                                            placeHolder="Enter..."
                                                            initValue={item.content}
                                                            onBlur={(e) => onChangeDataContent(index,e, 'texteditor_content')}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="main_box_button">
                                                    <div {...provided.dragHandleProps}>
                                                        <img src={iconGroupDrag} className="css_cursor_pointer" title="Drag" />
                                                    </div>
                                                    <div>
                                                        <img src={iconDuplicate} className="css_cursor_pointer" title="Duplicate" onClick={() => DuplicateData(index)} />
                                                    </div>
                                                    <div>
                                                        <img src={iconDelete} className="css_cursor_pointer" title="Delete" onClick={() => RemoveData(index)} />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {item.type_content == 'img' && (
                                            <div className="css_content_img">
                                                <div className="css_type_content">
                                                    รูปภาพ
                                                </div>
                                                <div>
                                                    <div className="input_css">
                                                        <Field className="field" label="ชื่อภาพ (ถ้ามี)">
                                                            <TextField
                                                                placeholder="ระบุ"
                                                                value={item.head_content}
                                                                onChange={(v) => onChangeDataContent(index, v, 'head_content')}
                                                            />
                                                        </Field>
                                                    </div>
                                                    <div className="css_magin_top_16"></div>
                                                    <div className="input_css_upload">
                                                        <UploadFileArray
                                                            multiple={multiple}
                                                            accept={acceptType}
                                                            file={item.FileInfoList.map((v) => ({ ...v, url: `${v.url}` }))}
                                                            onAddFile={handleAddFile}
                                                            onDeleteFile={handleDeleteFile}
                                                            error={error}
                                                            disabled={disabled}
                                                            FileListData={item.FileInfoList}
                                                            isOneFile={true}
                                                            limitFileSize={limitFileSize}
                                                            indexdata={index}
                                                            subtext={'SVG, PNG, JPG or GIF (max. 1280x720px)'}
                                                        />
                                                    </div>
                                                    <div className="input_css">
                                                        <Field className="field" label="คำบรรยายภาพ (ถ้ามี)">
                                                            <TextField
                                                                placeholder="ระบุ"
                                                                value={item.sub_content}
                                                                onChange={(v) => onChangeDataContent(index, v, 'sub_content')}
                                                            />
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className="main_box_button">
                                                    <div {...provided.dragHandleProps}>
                                                        <img src={iconGroupDrag} className="css_cursor_pointer" title="Drag" />
                                                    </div>
                                                    <div>
                                                        <img src={iconDuplicate} className="css_cursor_pointer" title="Duplicate" onClick={() => DuplicateData(index)} />
                                                    </div>
                                                    <div>
                                                        <img src={iconDelete} className="css_cursor_pointer" title="Delete" onClick={() => RemoveData(index)} />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {item.type_content == 'vdo' && (
                                            <div className="css_content_vdo">
                                                <div className="css_type_content">
                                                    วิดิโอ
                                                </div>
                                                <div>
                                                    {item.content && (
                                                        <div className="input_css">
                                                            <div>
                                                                <ReactPlayer
                                                                    url={item.content}
                                                                    className='react-player'
                                                                    playing={false}
                                                                    width='560px'
                                                                    height='315px'
                                                                />
                                                            </div>
                                                            <div className="css_magin_top_16"></div>

                                                        </div>
                                                    )}
                                                    <div className="input_css">
                                                        <Field className="field" label="Embeded Link">
                                                            <TextField
                                                                placeholder="ระบุ"
                                                                value={item.content}
                                                                onChange={(v) => onChangeDataContent(index, v, 'content')}
                                                                endAdornment_is_border_left={true}
                                                                endAdornment={
                                                                    <div >
                                                                        <span className="icon-header"><IoIosLink size={18}/></span>
                                                                        <span>Link</span>                                                       
                                                                    </div>
                                                                }
                                                            />
                                                        </Field>
                                                    </div>
                                                    <div className="css_magin_top_16"></div>
                                                    <div className="input_css">
                                                        <Field className="field" label="คำบรรยายภาพ (ถ้ามี)">
                                                            <TextField
                                                                placeholder="ระบุ"
                                                                value={item.sub_content}
                                                                onChange={(v) => onChangeDataContent(index, v, 'sub_content')}
                                                            />
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className="main_box_button">
                                                    <div {...provided.dragHandleProps}>
                                                        <img src={iconGroupDrag} className="css_cursor_pointer" title="Drag" />
                                                    </div>
                                                    <div>
                                                        <img src={iconDuplicate} className="css_cursor_pointer" title="Duplicate" onClick={() => DuplicateData(index)} />
                                                    </div>
                                                    <div>
                                                        <img src={iconDelete} className="css_cursor_pointer" title="Delete" onClick={() => RemoveData(index)} />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {item.type_content == 'pdf' && (
                                            <div className="css_content_pdf">
                                                <div className="css_type_content">
                                                    PDF
                                                </div>
                                                <div>
                                                    <div className="input_css_upload">
                                                        <UploadFileArray
                                                            multiple={multiple}
                                                            accept={acceptType}
                                                            file={item.FileInfoList.map((v) => ({ ...v, url: `${v.url}` }))}
                                                            onAddFile={handleAddFile}
                                                            onDeleteFile={handleDeleteFile}
                                                            error={error}
                                                            disabled={disabled}
                                                            FileListData={item.FileInfoList}
                                                            isOneFile={true}
                                                            limitFileSize={limitFileSize}
                                                            indexdata={index}
                                                            subtext={'PDF (maximum. 100MB)'}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="main_box_button">
                                                    <div {...provided.dragHandleProps}>
                                                        <img src={iconGroupDrag} className="css_cursor_pointer" title="Drag" />
                                                    </div>                            
                                                    <div>
                                                        <img src={iconDuplicate} className="css_cursor_pointer" title="Duplicate" onClick={() => DuplicateData(index)} />
                                                    </div>
                                                    <div>
                                                        <img src={iconDelete} className="css_cursor_pointer" title="Delete" onClick={() => RemoveData(index)} />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {item.type_content == 'url' && (
                                            <div className="css_content_url">
                                                <div className="css_type_content">
                                                    URL
                                                </div>
                                                <div>
                                                    <div className="input_css">
                                                        <Field className="field" label="หัวข้อของการสร้าง link (ถ้ามี)">
                                                            <TextField
                                                                placeholder="ระบุ"
                                                                value={item.head_content}
                                                                onChange={(v) => onChangeDataContent(index, v, 'head_content')}
                                                            />
                                                        </Field>
                                                    </div>
                                                    <div className="css_magin_top_16"></div>
                                                    {item?.url_list?.map((item_sub, index_sub) => (
                                                        <div className="css_list_url">
                                                            <div>
                                                                <Field className="field" label="ชื่อของ link ที่แสดง">
                                                                    <TextField
                                                                        placeholder="ระบุ"
                                                                        value={item_sub.link_name}
                                                                        onChange={(v) => onChangeDataContentSub(index,index_sub, v, 'link_name')}
                                                                    />
                                                                </Field>
                                                            </div>
                                                            <div>
                                                                <Field className="field" label="Link">
                                                                    <TextField
                                                                        placeholder="ระบุ"
                                                                        value={item_sub.link_url}
                                                                        onChange={(v) => onChangeDataContentSub(index,index_sub, v, 'link_url')}
                                                                        endAdornment_is_border_left={true}
                                                                        endAdornment={
                                                                        <div >
                                                                            <span className="icon-header"><IoIosLink size={18} /></span>
                                                                            <span>URL</span>                                                       
                                                                        </div>
                                                                        }
                                                                    />
                                                                </Field>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    <div >
                                                        <ButtonSecondary append={<GoPlus  size={18} />} onClick={() => AddContentURL(index)}>
                                                            {'เพิ่ม link'}
                                                        </ButtonSecondary>
                                                    </div>

                                                    {
                                                        /*
                                                        <div className="input_css">
                                                            <Field className="field" label="URL">
                                                                <TextField
                                                                    placeholder="ระบุ"
                                                                    value={item.content}
                                                                    onChange={(v) => onChangeDataContent(index, v, 'content')}
                                                                    endAdornment_is_border_left={true}
                                                                    endAdornment={
                                                                    <div >
                                                                        <span className="icon-header"><IoIosLink size={18} /></span>
                                                                        <span>URL</span>                                                       
                                                                    </div>
                                                                    }
                                                                />
                                                            </Field>
                                                        </div>
                                                        */
                                                    }
                                                </div>
                                                <div className="main_box_button">
                                                    <div {...provided.dragHandleProps}>
                                                        <img src={iconGroupDrag} className="css_cursor_pointer" title="Drag" />
                                                    </div>                            
                                                    <div>
                                                        <img src={iconDuplicate} className="css_cursor_pointer" title="Duplicate" onClick={() => DuplicateData(index)} />
                                                    </div>
                                                    <div>
                                                        <img src={iconDelete} className="css_cursor_pointer" title="Delete" onClick={() => RemoveData(index)} />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className="css_magin_top_24"></div>
                                        <div className="border_header"></div>
                                    </div>
                                )}
                            </Draggable>
                        ))} 
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
        <div className="css_magin_top_24"></div>  
        <div>
            <ButtonOutline append={<GoPlus  size={18} />} className="button_add" onClick={onAddContent}>
                {'เพิ่มเนื้อหา'}
            </ButtonOutline>
            <div className={isDivCreate ? 'dropdown' : 'dropdown_none'}>
                <ul>
                    <li onClick={() => AddContent('text')}>
                        <span className="icon-header"><TfiText size={16} /></span>
                         <span>ข้อความ</span>  
                    </li>
                    <li onClick={() => AddContent('img')}>
                        <span className="icon-header"><CiImageOn size={16} /></span>
                         <span>รูปภาพ</span>  
                    </li>
                    <li onClick={() => AddContent('vdo')}>
                        <span className="icon-header"><CiYoutube size={16} /></span>
                         <span>วิดีโอ</span>  
                    </li>
                    <li onClick={() => AddContent('pdf')}>
                        <span className="icon-header"><FaRegFileLines size={16} /></span>
                         <span>PDF</span>  
                    </li>
                    <li onClick={() => AddContent('url')}>
                        <span className="icon-header"><RiLinkM size={16} /></span>
                         <span>URL</span>  
                    </li>
                </ul>
            </div>
            
        </div>
        <div ref={LastdivRef} />
        

    </Div>
  )
}

export default ContentElerning
