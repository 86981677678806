import OptionUnstyled from '@mui/base/Option'
import Popper from '@mui/base/Popper'
import SelectUnstyled from '@mui/base/Select'
import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { GrStatusGoodSmall } from 'react-icons/gr'
import iconDropdown from '../../assets/images/icon-dropdown.svg'

const Div = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;

  .MuiSelect-root {
    display: flex;
    align-items: center;
    padding: 10px 18px;
    width: 100%;
    min-width: 0;
    height: 40px;
    /*font-family: 'Inter';*/
    font-family: inherit;
    font-size: 14px;
    line-height: 1.5;
    color: var(--Gray-500);
    border: 1px solid ${({ error }) => (error ? 'red' : 'var(--Gray-300)')};
    background: var(--Base-White);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    cursor: pointer;

    &.Mui-disabled {
      pointer-events: none;
      background: #efefef4d;
    }

    span {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    img {
      width: 12px;
      margin-left: 12px;
    }
  }
`

// eslint-disable-next-line react/display-name
const CustomRoot = forwardRef(({ children, /*omit*/ ownerState, ...props }, ref) => {
  return (
    <div ref={ref} {...props}>
      <span>{children}</span>
      <img src={iconDropdown} />
    </div>
  )
})

const CustomListbox = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0;
  /*font-family: 'Inter';*/
  font-family: inherit;
  font-size: 14px;
  list-style: none;
  background: var(--Base-White);
  border: 1px solid var(--Gray-300);
  box-shadow: var(--Shadow-xs);
  border-radius: 8px;
  overflow: auto;
  max-height: 300px;
  cursor: pointer;

  .status {
    font-size: 0.6rem;
    margin-right: 0.2rem;
    color: #17b26a;

    &.inactive {
      color: red;
    }
    &.draft {
      color: #98a2b3;
    }
  }

  li {
    padding: 8px 18px;
    color: var(--Gray-500);

    &.Mui-selected {
      color: var(--Primary-700);
      font-weight: 700;
    }

    &:hover {
      background: var(--Gray-100);
    }
  }
`
const CustomPopper = ({ anchorEl, ...props }) => {
  return <Popper anchorEl={anchorEl} style={{ minWidth: anchorEl?.offsetWidth, zIndex: 2000 }} {...props} />
}

const Dropdown = ({
  optionList = [],
  value,
  error,
  disabled,
  onChange = () => {},
  placeHolder,
  ValueplaceHolderIsNull,
  isShowDisable = true,
  isStatus,
  ...props
}) => {
  const handleChange = (e, value) => {
    onChange(value)
  }

  return (
    <Div {...props} error={error} name={props.name}>
      <SelectUnstyled
        value={value}
        onChange={handleChange}
        disabled={disabled}
        slots={{
          root: CustomRoot,
          listbox: CustomListbox,
          popper: CustomPopper,
          ...props.slots,
        }}
        {...props}
      >
        {isShowDisable == true && (
          <OptionUnstyled value={ValueplaceHolderIsNull ? null : ''} disabled>
            {placeHolder ? placeHolder : 'Please select'}
          </OptionUnstyled>
        )}
        {isShowDisable == false && (
          <OptionUnstyled value={ValueplaceHolderIsNull ? null : ''}>
            {placeHolder ? placeHolder : 'Please select'}
          </OptionUnstyled>
        )}
        {optionList.map((v) => (
          <OptionUnstyled key={v.value} value={v.value} >
            {isStatus && (
              <GrStatusGoodSmall
                className={`status ${
                  v.value.toLowerCase() == 'inactive' ? 'inactive' : v.value.toLowerCase() == 'draft' ? 'draft' : ''
                }`}
              />
            )}{' '}
            {v.text}
          </OptionUnstyled>
        ))}
      </SelectUnstyled>
    </Div>
  )
}

export default Dropdown
